import React from 'react';
import { Select } from 'antd';
import useSelector from '../../../utils/hooks/useSelector';
import { OptionProps } from 'antd/lib/select';

interface Props {
    value?: string,
    onChange: (value: string) => void,
}

function ManagerSelectField({
    value,
    onChange 
}: Props) {

    const { salaryManagerList } = useSelector(state => state.salary);

    const handleChange = (data: string) => {
        onChange(data);
    };

    const filterOptions = (query: string, option: React.ReactElement<OptionProps>) => {
        let label = option?.props?.children;
        if (label) {
            if (typeof label !== 'string') label = String(label);
            return (label as string).toLowerCase().indexOf(query.toLowerCase()) > 0;
        }
        return false;
    }

    return (
        <Select
            value={value}
            onChange={handleChange}
            className="gx-w-100"
            filterOption={filterOptions}
        >
            {salaryManagerList.map((_) => (
                <Select.Option value={_.managerId}>{_.managerUsername}</Select.Option>
            ))}
        </Select>
    );
}

export default ManagerSelectField;