import React, { useState } from 'react';
import {
  Button,
  Card,
  Table,
} from 'antd';
import { useHistory } from 'react-router';
import moment from 'moment';
import Icon from 'antd/es/icon';
import { ColumnProps } from 'antd/es/table';
import { USER_DATE_FORMAT } from '../../../utils/constants';
import useSelector from '../../../utils/hooks/useSelector';
import { Account } from '../types';
import { getContractStatusColor, getUserStatusColor } from '../utils';
import useDispatch from '../../../utils/hooks/useDispatch';
import { isNumber } from 'lodash';
import { RootState } from '../../../reducers';
import { SelectManagerModal } from './SelectManagerModal';
import { loadSalaryManagerList } from "../../salary/action-creators";
import classNames from 'classnames';
import { removeAccountManager } from '../action-creators';

interface AccountTableProps {
  loading: boolean,
}

function AccountTable({ loading }: AccountTableProps) {
  const accountsList = useSelector((state) => state.accounts.accountsList);
  const { user } = useSelector((state: RootState) => state.users);
  const { salaryManagerList } = useSelector((state: RootState) => state.salary);
  const history = useHistory();
  const dispatch = useDispatch();

  const [editingAccountId, setEditingAccount] = useState<number | null>(null);

  const getUserStatus = (record: Account) => {
    const color = getUserStatusColor(record);
    return <Icon type="smile" theme="twoTone" style={{ color: color.primary }} twoToneColor={color.outline} />;
  };

  const getContractStatus = (record: Account) => {
    const color = getContractStatusColor(record);
    return <Icon type="file-text" theme="twoTone" style={{ color: color.primary }} twoToneColor={color.outline} />;
  };

  const handleClick = (record: Account) => {
    history.push(`/accounts/${record.accountId}`);
  };

  const getManagerColView = (managerId: number) => {
    const manager = salaryManagerList.find(i => i.managerId === managerId);
    if (manager) return manager.managerUsername;
    return null;
  }

  const columns: ColumnProps<Account>[] = [
    {
      title: 'Название юрлица',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (<span>{getUserStatus(record)} {getContractStatus(record)} {text}</span>),
    },
    {
      title: 'ИНН',
      dataIndex: 'inn',
      key: 'inn',
    },
    {
      title: 'Прирост компании',
      dataIndex: 'growthValue',
      key: 'growthValue',
      render: (text, record) => {
        const { growthValue } = record;
        return <span className={classNames({'gx-text-green': growthValue > 0, 'gx-text-danger': growthValue < 0})}>{growthValue.toFixed(2)} %</span>;
      },
    },
    {
      title: 'Прошлый мес.',
      dataIndex: 'billingPrevMonthAmount',
      key: 'billingPrevMonthAmount',
    },
    {
      title: 'Лимит суммы заказов',
      dataIndex: 'billingLimit',
      key: 'billingLimit',
      render: (text, record) => {
        const signed = record.contract?.status === 'confirmed';
        return signed ? text : record.contract?.lastBillingLimitRequest || 0;
      },
    },
    {
      title: 'Номер договора',
      dataIndex: 'contractNumber',
      key: 'contractNumber',
    },
    {
      title: 'Дата договора',
      key: 'contractDate',
      render: (_, record) => (record?.contract?.date ? moment(record.contract.date).format(USER_DATE_FORMAT) : ''),
    },
    {
      title: 'Менеджер',
      key: 'manager',
      render: (_, record) => record.managerId ? getManagerColView(record.managerId) : '-'
    },
    {
      title: 'Редактирование менеджера',
      key: 'addManager',
      render: (_, record) => (
        user?.isAdmin
        ?
        <div className='gx-d-flex gx-align-items-center'>
          <Button 
            type='primary'
            size='small'
            className='gx-mb-0'
            onClick={(e: any) => {
              e.stopPropagation();
              setEditingAccount(record.accountId)
            }}>
              <Icon type="edit"/>
          </Button>
          {
            record.managerId
            ?
            <Button 
              type='danger'
              size='small'
              className='gx-mb-0'
              onClick={(e: any) => {
                e.stopPropagation();
                dispatch(removeAccountManager(record.accountId))
              }}>
                <Icon type="delete"/>
            </Button>
            :
            null
          }
        </div>
        :
        null
      ),
    },
    {
      title: 'График',
      key: 'chart',
      render: (_, record) => (
        <div className='gx-d-flex gx-align-items-center'>
          <Button 
            type='primary'
            size='small'
            className='gx-mb-0'
            onClick={(e: any) => {
              e.stopPropagation();
              history.push(`/accounts/${record.accountId}/chart`)
            }}>
              Открыть
          </Button>
        </div>
      ),
    }
  ];

  React.useEffect(() => {
    dispatch(loadSalaryManagerList())
  }, [])

  return (
    <Card>
      <SelectManagerModal 
        editingAccountId={editingAccountId} 
        handleClose={() => setEditingAccount(null)}/>
      <Table
        style={{ margin: -24 }}
        className="gx-table-responsive"
        columns={columns}
        onRowClick={handleClick}
        rowClassName={() => 'gx-pointer'}
        loading={loading}
        dataSource={accountsList}
        pagination={false}
      />
    </Card>
  );
}

export default AccountTable;
