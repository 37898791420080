import React, { useCallback } from 'react';
import { Moment } from 'moment';
import { Col, Row } from 'antd';
import useSelector from '../../../utils/hooks/useSelector';
import useDispatch from '../../../utils/hooks/useDispatch';
import useCancelCallback from '../../../utils/hooks/useCancelCallback';
import { loadBaseStatistic, loadWaybillStat } from '../action-creators';
import { SERVER_DATE_FORMAT } from '../../../utils/constants';
import SiteVisit from '../views/components/WaybillStat';

type WaybillStatTabContentProps = {
  dateFrom: Moment,
  dateTo: Moment,
};

function WaybillStatTabContent({ dateFrom, dateTo }: WaybillStatTabContentProps) {
  const dispatch = useDispatch();
  const { waybillStat, waybillStatTotalCount, waybillStatTotalAmount, waybillBaseStat } = useSelector((state) => state.statistics);

  const handleLoad = useCancelCallback((from, to, cancelToken) => dispatch(loadWaybillStat(from?.format(SERVER_DATE_FORMAT), to?.format(SERVER_DATE_FORMAT), cancelToken)), []);

  const handleLoadBaseStatistics = useCallback((from, to) => {
    dispatch(loadBaseStatistic(from?.format(SERVER_DATE_FORMAT), to?.format(SERVER_DATE_FORMAT)))
  }, [])

  React.useEffect(() => {
    handleLoadBaseStatistics(dateFrom, dateTo);
    handleLoad(dateFrom, dateTo);
  }, [dateFrom, dateTo]);

  return (
    <SiteVisit
      body={(
        <Row>
          <Col lg={12} md={12} sm={24} xs={24}>
            <p style={{ color: '#038FDE' }}>Общее кол-во заказов: {waybillStatTotalCount}</p>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <p style={{ color: '#FE9E15' }}>Общая сумма заказов: {waybillStatTotalAmount} руб</p>
            {
              waybillBaseStat
              &&
              <>
                <p style={{ color: '#FE9E15' }}>Сумма заказов listing: {waybillBaseStat?.waybillAmount} руб</p>
                <p style={{ color: '#FE9E15' }}>Сумма биллингов: {waybillBaseStat?.billingAmount} руб</p>
                <p style={{ color: '#FE9E15' }}>Сумма счетов: {waybillBaseStat?.manualAmount} руб</p>
              </>
              ||
              null
            }
          </Col>
        </Row>
      )}
      data={waybillStat}
    />
  );
}

export default WaybillStatTabContent;
