import {
  GlobalNotification,
  NotificationsActions,
  RESET_CURRENT_GLOBAL_NOTIFICATION,
  SET_CURRENT_GLOBAL_NOTIFICATION,
  SET_GLOBAL_NOTIFICATION_COUNT,
  SET_GLOBAL_NOTIFICATION_LIST,
} from './types';

type NotificationsState = {
  globalNotifications: GlobalNotification[],
  globalNotificationsCount: number,
  currentGlobalNotification: GlobalNotification | null,
};

const initialState: NotificationsState = {
  globalNotifications: [],
  globalNotificationsCount: 0,
  currentGlobalNotification: null,
};

export default function (state = initialState, action: NotificationsActions) {
  switch (action.type) {
    case SET_GLOBAL_NOTIFICATION_LIST:
      return { ...state, globalNotifications: action.list };
    case SET_GLOBAL_NOTIFICATION_COUNT:
      return { ...state, globalNotificationsCount: action.count };
    case SET_CURRENT_GLOBAL_NOTIFICATION:
      return { ...state, currentGlobalNotification: action.notification };
    case RESET_CURRENT_GLOBAL_NOTIFICATION:
      return { ...state, currentGlobalNotification: initialState.currentGlobalNotification };
    default: return state;
  }
}
