import React from 'react';
import { Select } from 'antd';
import groupBy from 'lodash/groupBy';
import { OptionProps } from 'antd/es/select';
import Truncate from 'react-truncate';
import useSelector from '../../utils/hooks/useSelector';
import { Rate } from '../../app/reference/types';

type DeliveryRateFieldProps = {
  value: undefined | null | string[],
  onChange: (value: undefined | null | string[]) => void,
  deliveryService?: string | string[],
};

const filterRates = (rates: Rate[], deliveryService: string | string[]) => {
  if (Array.isArray(deliveryService)) {
    return rates.filter((_) => deliveryService.some((__) => __ === _.deliveryService));
  }
  return rates.filter((_) => _.deliveryService === deliveryService);
};

const filterOptions = (query: string, option: React.ReactElement<OptionProps>) => {
  let label = option?.props?.children;
  if (label) {
    if (typeof label !== 'string') label = String(label);
    return (label as string).toLowerCase().indexOf(query.toLowerCase()) > 0;
  }
  return false;
};

function DeliveryRateField({ value, onChange, deliveryService }: DeliveryRateFieldProps) {
  const { rateList, deliveryServices } = useSelector((state) => state.reference);

  const actualRates = React.useMemo(() => (groupBy(deliveryService ? filterRates(rateList, deliveryService) : rateList, 'deliveryService')), [rateList, deliveryService]);

  return (
    <div>

      <Select
        mode="multiple"
        value={value}
        onChange={(values: string[] | null | undefined) => onChange(values)}
        className="gx-w-100"
        filterOption={filterOptions}
        autoClearSearchValue={false}
      >
        {Object.keys(actualRates).map((service) => {
          const ds = deliveryServices[service];
          if (ds) {
            return (
              <Select.OptGroup label={ds.title} key={service}>
                {actualRates[service].map(({ title, code }) => (
                  <Select.Option value={code} key={code}>
                    {title}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            );
          }
          return (
            <>
              {actualRates[service].map(({ title, code }) => (
                <Select.Option value={code} key={code}>
                  {title}
                </Select.Option>
              ))}

            </>
          );
        })}
      </Select>
    </div>
  );
}

export default DeliveryRateField;

export const DeliveryRateLabel = ({ code }: { code: string }) => {
  const { rateList } = useSelector((state) => state.reference);

  const rate = React.useMemo(() => rateList.find((_) => _.code === code), [code, rateList]);

  return (
    <span>
      <Truncate
        lines={1}
        ellipsis="..."
        trimWhitespace
        width={200}
      >
        {rate?.title || code}
      </Truncate>
    </span>
  );
};
