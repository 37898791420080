import React from 'react';
import { Col, Row } from 'antd';
import SearchBox from './components/SearchBox';
import ClientBillTable from './components/ClientBillTable';

function ClientBillView() {
  return (
    <>
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <div className="title h1"><p>Клиентские счета</p></div>
        </Col>
      </Row>
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <SearchBox />
        </Col>
      </Row>
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <ClientBillTable />
        </Col>
      </Row>
    </>
  );
}

export default ClientBillView;
