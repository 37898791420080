import React from 'react';
import { Select } from 'antd';
import { OptionProps } from 'antd/es/select';
import useSelector from '../../utils/hooks/useSelector';

type SellerFieldProps = {
  value: number,
  onChange: (value: number) => void,
};

const filterOptions = (query: string, option: React.ReactElement<OptionProps>) => {
  let label = option?.props?.children;
  if (label) {
    if (typeof label !== 'string') label = String(label);
    return (label as string).toLowerCase().indexOf(query.toLowerCase()) > 0;
  }
  return false;
};

function SellerField({ value, onChange }: SellerFieldProps) {
  const { sellers } = useSelector((state) => state.reference);

  return (
    <div>

      <Select
        value={value}
        onChange={(value: number) => onChange(value)}
        className="gx-w-100"
        filterOption={filterOptions}
        autoClearSearchValue={false}
      >
        {sellers.map(({ title, sellerId }) => (
            <Select.Option value={sellerId} key={sellerId}>
                {title}
            </Select.Option>
        ))}
      </Select>
    </div>
  );
}

export default SellerField;