import { useState, useRef, useCallback, DependencyList } from 'react';
import axios from 'axios';

export default function (callback: (...args: Array<any>) => Promise<any>, deps: DependencyList): any {
  const [loading, setLoading] = useState(false);
  const cancelToken = useRef(axios.CancelToken.source());

  return useCallback((...args: Array<any>) => {
    let cancel = cancelToken.current;
    if (loading) {
      cancelToken.current.cancel('cancel');
      cancel = axios.CancelToken.source();
      cancelToken.current = cancel;
    }
    setLoading(true);
    return callback(...[...args, cancel.token]).finally(() => setLoading(false));
  }, [callback, loading, ...deps]);
}
