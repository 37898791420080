import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const ShippingDetail = React.lazy(() => import('../components/ShippingDetail'));
function ShippingDetailView(props: any) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <ShippingDetail {...props} />
    </Suspense>
  );
}

export default ShippingDetailView;
