import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Icon,
  Row,
  Table,
} from 'antd';
import { Field, Form, Formik } from 'formik';
import Modal from 'antd/es/modal';
import { useHistory } from 'react-router';
import { ColumnProps } from 'antd/es/table';
import useSelector from '../../../utils/hooks/useSelector';
import useDispatch from '../../../utils/hooks/useDispatch';
import { SalaryManager } from '../types';
import { managerTypeDescription } from '../constants';
import { loadSalaryManagerList, updateManagerData } from '../action-creators';
import { isNumber } from 'lodash';
import { AntInputNumber } from '../../../components/CreateAntField';
import { RootState } from '../../../reducers';

interface AccountTableProps {
  loading: boolean,
}

function SalaryManagerTable({ loading }: AccountTableProps) {
  const history = useHistory();
  const dispatch = useDispatch();

  const { salaryManagerList } = useSelector((state) => state.salary);
  const { user } = useSelector((state: RootState) => state.users);

  const [editManager, setEditManager] = useState<number | null>(null);

  const handleClick = (record: SalaryManager) => {
    history.push(`/salary/${record.managerId}`);
  };

  useEffect(() => {
    dispatch(loadSalaryManagerList())
  }, [])

  const columns: ColumnProps<SalaryManager>[] = [
    {
      title: 'Менеджер',
      dataIndex: 'managerUsername',
      key: 'managerUsername'
    },
    {
      title: 'Тип',
      dataIndex: 'managerType',
      key: 'managerType',
      //@ts-ignore
      render: (_, record) => managerTypeDescription[record.managerType],
    },
    {
      title: '% от плана продаж',
      dataIndex: 'awardPercent',
      key: 'awardPercent',
    },
    {
      title: '% от оплат',
      dataIndex: 'sellAwardPercent',
      key: 'sellAwardPercent',
    },
    {
      title: 'Оклад',
      dataIndex: 'salary',
      key: 'salary',
    },
    {
      title: 'Редактировать',
      key: 'edit',
      //@ts-ignore
      render: (_, record) => (
        user?.isAdmin
        ?
        <Button 
          type='primary'
          size='small'
          className='gx-mb-0'
          onClick={(e: any) => {
            e.stopPropagation();
            setEditManager(record.managerId);
          }}><Icon type="edit"/></Button>
        :
        null
      ),
    }
  ];

  const handleEditManager = React.useCallback(
    (values, actions) => {
      if (isNumber(editManager) && isNumber(values.awardPercent) && isNumber(values.sellAwardPercent) && isNumber(values.salary) && isNumber(values.profitabilityAwardPercent)) {
        dispatch(updateManagerData(editManager, values.awardPercent, values.sellAwardPercent, values.salary, values.profitabilityAwardPercent)).then(
          () => setEditManager(null),
          ({ item }) => actions.setErrors(item),
        ).finally(() => actions.setSubmitting(false));
      }
    },
    [editManager],
  );

  const getInitialValues = useCallback(
    () => {
      const manager = salaryManagerList.find(i => i.managerId === editManager)
      if (manager) {
        return ({
          awardPercent: manager.awardPercent,
          sellAwardPercent: manager.sellAwardPercent,
          profitabilityAwardPercent: manager.profitabilityAwardPercent,
          salary: manager.salary
        })
      }
      return {}
    },
    [editManager]
  )

  return (
    <Card>
      {isNumber(editManager) && (
        <Formik
          initialValues={getInitialValues()}
          enableReinitialize
          onSubmit={handleEditManager}
        >
          {({ submitForm, isSubmitting }) => (
            <Modal
              visible={isNumber(editManager)}
              onCancel={() => setEditManager(null)}
              title={`Редактирование ${salaryManagerList.find(i => i.managerId === editManager)?.managerUsername}`}
              onOk={submitForm}
              confirmLoading={isSubmitting}
              okText="Сохранить"
              cancelText="Отменить"
            >
              <Form>
                <Row>
                  <Col xs={24} md={24}>
                    <Field
                      component={AntInputNumber}
                      name="awardPercent"
                      placeholder="Процент премии"
                      title="Процент премии"
                      label="Процент премии"
                      componentClassName="gx-w-100"
                    />
                  </Col>
                  <Col xs={24} md={24}>
                    <Field
                      component={AntInputNumber}
                      name="sellAwardPercent"
                      placeholder="Процент с продаж"
                      title="Процент с продаж"
                      label="Процент с продаж"
                      componentClassName="gx-w-100"
                    />
                  </Col>
                  <Col xs={24} md={24}>
                    <Field
                      component={AntInputNumber}
                      name="profitabilityAwardPercent"
                      placeholder="Процент с рентабельности"
                      title="Процент с рентабельности"
                      label="Процент с рентабельности"
                      componentClassName="gx-w-100"
                    />
                  </Col>
                  <Col xs={24} md={24}>
                    <Field
                      component={AntInputNumber}
                      name="salary"
                      placeholder="Оклад"
                      title="Оклад"
                      label="Оклад"
                      componentClassName="gx-w-100"
                    />
                  </Col>
                </Row>
              </Form>
            </Modal>
          )}
        </Formik>
      )}
      <Table
        style={{ margin: -24 }}
        className="gx-table-responsive"
        columns={columns}
        onRowClick={handleClick}
        rowClassName={() => 'gx-pointer'}
        loading={loading}
        dataSource={salaryManagerList}
        pagination={false}
      />
    </Card>
  );
}

export default SalaryManagerTable;
