import { Account } from '../accounts/types';

export const SET_MARKETING_LIST = 'SET_MARKETING_LIST';
export const SET_MARKETING_COUNT = 'SET_MARKETING_COUNT';
export const ADD_MARKETING = 'ADD_MARKETING';
export const REMOVE_MARKETING = 'REMOVE_MARKETING';
export const RESET_MARKETING_LIST = 'RESET_MARKETING_LIST';
export const REPLACE_MARKETING = 'REPLACE_MARKETING';
export const SET_SERVICE_LIMIT_LIST = 'SET_SERVICE_LIMIT_LIST';
export const RESET_SERVICE_LIMIT_LIST = 'RESET_SERVICE_LIMIT_LIST';
export const REPLACE_SERVICE_LIMIT = 'REPLACE_SERVICE_LIMIT';

export const SET_MARKETING_CLIENT_LOG = 'SET_MARKETING_CLIENT_LOG';
export const SET_MARKETING_CLIENT_LOG_COUNT = 'SET_MARKETING_CLIENT_LOG_COUNT';
export const RESET_MARKETING_CLIENT_LOG = 'RESET_MARKETING_CLIENT_LOG';

export const SET_TENDER_RATE_LIST = 'SET_TENDER_RATE_LIST';
export const RESET_TENDER_RATE_LIST = 'RESET_TENDER_RATE_LIST';
export const REPLACE_TENDER_RATE = 'REPLACE_TENDER_RATE';
export const REMOVE_TENDER_RATE = 'REMOVE_TENDER_RATE';
export const ADD_TENDER_RATE = 'ADD_TENDER_RATE';
export const SET_TENDER_RATE_COUNT = 'SET_TENDER_RATE_COUNT';
export const SET_MARKETING_IMPORT_ID = 'SET_MARKETING_IMPORT_ID';
export const SET_MARKETING_IMPORT_STATUS = 'SET_MARKETING_IMPORT_STATUS';
export const RESET_MARKETING_IMPORT = 'RESET_MARKETING_IMPORT';

export const SET_STORE_ITEMS = 'SET_STORE_ITEMS';
export const SET_STORE_ORDER_LIST = 'SET_STORE_ORDER_LIST';
export const SET_STORE_ORDER_COUNT = 'SET_STORE_ORDER_COUNT';
export const SET_STORE_ORDER_DETAIL = 'SET_STORE_ORDER_DETAIL';

export const SET_EXTRA_ACCOUNT_MARKETING_LIST = 'SET_EXTRA_ACCOUNT_MARKETING_LIST';
export const RESET_EXTRA_ACCOUNT_MARKETING_LIST = 'RESET_EXTRA_ACCOUNT_MARKETING_LIST';
export const REPLACE_EXTRA_ACCOUNT_MARKETING = 'REPLACE_EXTRA_ACCOUNT_MARKETING';
export const REMOVE_EXTRA_ACCOUNT_MARKETING = 'REMOVE_EXTRA_ACCOUNT_MARKETING';
export const ADD_EXTRA_ACCOUNT_MARKETING = 'ADD_EXTRA_ACCOUNT_MARKETING';
export const SET_EXTRA_ACCOUNT_MARKETING_COUNT = 'SET_EXTRA_ACCOUNT_MARKETING_COUNT';

export const SET_EXTRA_RATE_CASHBACK = 'SET_EXTRA_RATE_CASHBACK';

export type ClientTenderTableParams = {
  page: number,
  search: string | null,
  enabled: string | null,
  weight: string | null,
  weightCondition: string | null,
  sendIso: string | null,
  sendCity: string | null,
  sendDistrict: string | null,
  sendRegion: string | null,
  recIso: string | null,
  recCity: string | null,
  recDistrict: string | null,
  recRegion: string | null,
};

export type ExtraAccountMarketingTableParams = {
  page: number,
  search: string | null,
  enabled: string | null,
  weight: string | null,
  weightCondition: string | null,
  sendIso: string | null,
  sendCity: string | null,
  sendDistrict: string | null,
  sendRegion: string | null,
  recIso: string | null,
  recCity: string | null,
  recDistrict: string | null,
  recRegion: string | null,
}


export type ClientMarketingTableParams = {
  page: number,
  search: string | null,
  enabled: string | null,
  weight: string | null,
  weightCondition: string | null,
  sendIso: string | null,
  sendCity: string | null,
  sendDistrict: string | null,
  sendRegion: string | null,
  recIso: string | null,
  recCity: string | null,
  recDistrict: string | null,
  recRegion: string | null,
  deliveryService: string | null,
};

export enum MarketingConfigurationFieldChoices {
  WEIGHT = 'weight',
  SEND_ISO = 'send_iso',
  SEND_CITY = 'send_city',
  SEND_DISTRICT = 'send_district',
  SEND_REGION = 'send_region',
  REC_ISO = 'rec_iso',
  REC_CITY = 'rec_city',
  REC_DISTRICT = 'rec_district',
  REC_REGION = 'rec_region',
  DELIVERY_SERVICE = 'delivery_service',
  RATE_CODE = 'rate_code'
}

export enum MarketingConfigurationConditionChoices {
  EXACT = 'exact',
  GT = 'gt',
  GTE = 'gte',
  LT = 'lt',
  LTE = 'lte'
}

export type MarketingConfiguration = {
  field: MarketingConfigurationFieldChoices,
  condition: MarketingConfigurationConditionChoices,
  value?: number,
  textValue?: string,
};

export type MarketingClient = {
  actionType: 1 | 2,
  value: number,
  enabled: boolean,
  configurations: Array<MarketingConfiguration>,
  marketingId?: number,
  dateFrom: string,
  dateTo: string,
  rateTitle?: string,
  cashbackPercent?: number,
};

export type MarketingClientLog = Omit<MarketingClient, 'enabled' | 'marketingId'> & {};

export type ServiceLimit = {
  deliveryService: string,
  limitTo: string,
  limitId: number,
};

export type AccountTenderRate = {
  value: number,
  enabled: boolean,
  configurations: MarketingConfiguration[],
  tenderRateId: number,
  days: string,
  creationDeliveryService: string,
  creationRate: number,
};

export enum ClientMarketingImportStatusAction {
  ERROR = 'error',
  SUCCESS = 'success'
}

export type ClientMarketingImportStatus = {
  action: ClientMarketingImportStatusAction,
  importId: string,
  detail?: string,
};

export type StoreItem = {
  itemId: number,
  title: string,
  image: string,
  enabled: boolean,
  denominationList: number[],
};

export enum OrderStatus {
  INITIAL = 'INITIAL',
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED'
}

export type OrderListItem = {
  orderId: number,
  accountId: number,
  status: OrderStatus,
  createdAt: string,
  totalValue: number,
  account?: Account,
  number: string,
};

export type StoreOrderItem = {
  itemId: number,
  quantity: number,
  denomination: number,
};

export type StoreOrderDetail = {
  orderId: number,
  accountId: number,
  status: OrderStatus,
  createdAt: string,
  totalValue: number,
  email: string,
  phone: string,
  items: StoreOrderItem[],
  number: string,
  account?: Account,
};

export type ExtraAccountMarketing = {
  creationRateId: number;
  extraAccountMarketingId: number,
  enabled: boolean,
  configurations: MarketingConfiguration[],
  extraDiscount: number,
  rateId: number,
};

export type ExtraRateCashback = {
  extraAccountId: number,
  cashbackPercent: number,
}

export interface SetExtraRateCashback {
  type: typeof SET_EXTRA_RATE_CASHBACK,
  payload: ExtraRateCashback | null
}

export interface SetMarketingList {
  type: typeof SET_MARKETING_LIST,
  marketingList: Array<MarketingClient>
}

export interface ResetMarketingList {
  type: typeof RESET_MARKETING_LIST,
}

export interface ReplaceMarketing {
  type: typeof REPLACE_MARKETING,
  marketing: MarketingClient,
}

export interface SetServiceLimitList {
  type: typeof SET_SERVICE_LIMIT_LIST,
  serviceLimitList: Array<ServiceLimit>,
}

export interface ResetServiceLimitList {
  type: typeof RESET_SERVICE_LIMIT_LIST,
}

export interface ReplaceServiceLimit {
  type: typeof REPLACE_SERVICE_LIMIT,
  serviceLimit: ServiceLimit,
}

export interface SetMarketingClientLog {
  type: typeof SET_MARKETING_CLIENT_LOG,
  logs: MarketingClientLog[],
}

export interface SetMarketingClientLogCount {
  type: typeof SET_MARKETING_CLIENT_LOG_COUNT,
  count: number,
}

export interface ResetMarketingClientLog {
  type: typeof RESET_MARKETING_CLIENT_LOG,
}

export interface SetTenderRateList {
  type: typeof SET_TENDER_RATE_LIST,
  tenderRateList: Array<AccountTenderRate>
}

export interface ResetTenderRateList {
  type: typeof RESET_TENDER_RATE_LIST,
}

export interface ReplaceTenderRate {
  type: typeof REPLACE_TENDER_RATE,
  tenderRate: AccountTenderRate,
}

export interface RemoveTenderRate {
  type: typeof REMOVE_TENDER_RATE,
  tenderRateId: number,
}

export interface AddTenderRate {
  type: typeof ADD_TENDER_RATE,
  tenderRate: AccountTenderRate,
}

export interface SetTenderRateCount {
  type: typeof SET_TENDER_RATE_COUNT,
  count: number,
}

export interface SetMarketingCount {
  type: typeof SET_MARKETING_COUNT,
  count: number,
}

export interface AddMarketing {
  type: typeof ADD_MARKETING,
  marketing: MarketingClient,
}

export interface RemoveMarketing {
  type: typeof REMOVE_MARKETING,
  marketingId: number,
}

export interface SetMarketingImportId {
  type: typeof SET_MARKETING_IMPORT_ID,
  importId: string,
}

export interface SetMarketingImportStatus {
  type: typeof SET_MARKETING_IMPORT_STATUS,
  status: ClientMarketingImportStatus,
}

export interface ResetMarketingImport {
  type: typeof RESET_MARKETING_IMPORT,
}

export interface SetStoreItems {
  type: typeof SET_STORE_ITEMS,
  items: StoreItem[],
}

export interface SetStoreOrderList {
  type: typeof SET_STORE_ORDER_LIST,
  items: OrderListItem[],
}

export interface SetStoreOrderCount {
  type: typeof SET_STORE_ORDER_COUNT,
  count: number,
}

export interface SetStoreOrderDetail {
  type: typeof SET_STORE_ORDER_DETAIL,
  detail: StoreOrderDetail,
}

export interface SetExtraAccountMarketingList {
  type: typeof SET_EXTRA_ACCOUNT_MARKETING_LIST,
  list: ExtraAccountMarketing[],
}

export interface ResetExtraAccountMarketingList {
  type: typeof RESET_EXTRA_ACCOUNT_MARKETING_LIST,
}

export interface ReplaceExtraAccountMarketing {
  type: typeof REPLACE_EXTRA_ACCOUNT_MARKETING,
  marketing: ExtraAccountMarketing,
}

export interface RemoveExtraAccountMarketing {
  type: typeof REMOVE_EXTRA_ACCOUNT_MARKETING,
  marketingId: number,
}

export interface AddExtraAccountMarketing {
  type: typeof ADD_EXTRA_ACCOUNT_MARKETING,
  marketing: ExtraAccountMarketing,
}

export interface SetExtraAccountMarketingCount {
  type: typeof SET_EXTRA_ACCOUNT_MARKETING_COUNT,
  count: number,
}

export type MarketingAction =
  SetMarketingList
  | SetMarketingCount
  | ResetMarketingList
  | ReplaceMarketing
  | SetServiceLimitList
  | ResetServiceLimitList
  | ReplaceServiceLimit
  | SetMarketingClientLog
  | SetMarketingClientLogCount
  | ResetMarketingClientLog
  | SetTenderRateList
  | ResetTenderRateList
  | ReplaceTenderRate
  | RemoveTenderRate
  | AddTenderRate
  | SetTenderRateCount
  | AddMarketing
  | RemoveMarketing
  | SetMarketingImportId
  | SetMarketingImportStatus
  | ResetMarketingImport
  | SetStoreItems
  | SetStoreOrderList
  | SetStoreOrderCount
  | SetStoreOrderDetail
  | SetExtraAccountMarketingList
  | ResetExtraAccountMarketingList
  | ReplaceExtraAccountMarketing
  | RemoveExtraAccountMarketing
  | AddExtraAccountMarketing
  | SetExtraAccountMarketingCount
  | SetExtraRateCashback;
