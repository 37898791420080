import { pluralize } from '../../helpers';

export const getWaybillRateNameListing = (waybill) => {
  let daysString = null;
  if (waybill) {
    if (waybill.minDeliveryDays === 0 && waybill.maxDeliveryDays === 0) {
      if (waybill.senderCity !== waybill.receiverCity) {
        daysString = 'Сроки необходимо уточнить';
      } else {
        daysString = 'В течение дня';
      }
    } else if (waybill.minDeliveryDays === waybill.maxDeliveryDays) {
      daysString = `${waybill.minDeliveryDays} ${pluralize(waybill.minDeliveryDays, 'день,дня,дней')}`;
    } else {
      daysString = `${waybill.minDeliveryDays}-${waybill.maxDeliveryDays} ${
        pluralize(waybill.maxDeliveryDays, 'день,дня,дней')
      }`;
    }
  }
  return `${waybill?.rate} ${daysString ? ` (${daysString})` : ''}`;
};

export const getWaybillRateName = (waybill) => {
  let daysString = null;

  if (waybill) {
    if (waybill.rateMinDays === 0 && waybill.rateMaxDays === 0) {
      if (waybill.senderContact.city !== waybill.receiverContact.city) {
        daysString = 'Сроки необходимо уточнить';
      } else {
        daysString = 'В течение дня';
      }
    } else if (waybill.rateMinDays === waybill.rateMaxDays) {
      daysString = `${waybill.rateMinDays} ${pluralize(waybill.rateMinDays, 'день,дня,дней')}`;
    } else {
      daysString = `${waybill.rateMinDays}-${waybill.rateMaxDays} ${pluralize(waybill.rateMaxDays, 'день,дня,дней')}`;
    }
  }
  return `${waybill?.rateName} ${daysString ? ` (${daysString})` : ''}`;
};
