import React, {useCallback, useMemo} from 'react';
import {Menu} from 'antd';
import {Link} from 'react-router-dom';
import {useHistory} from 'react-router';
import useSelector from '../../utils/hooks/useSelector';
import { COMMON_EXCLUDED_FOR_MANAGER, DEVELOPMENT_EXCLUDED_FOR_MANAGER, MANAGER_TYPES } from '../../constants/ManagerConstants';

const {SubMenu} = Menu;

function HorizontalNav() {
  const history = useHistory();
  const { user } = useSelector((state) => state.users);
  const menu = [
    {
      title: 'Клиенты',
      to: '/accounts',
    },
    {
      title: 'Заказы',
      to: '/shipping',
    },
    {
      title: 'Закрывающие документы',
      to: '/acts',
    },
    {
      title: 'Задолженность',
      to: '/debt',
    },
    {
      title: 'События',
      to: '/client_events',
    },
    {
      title: 'Коммерческие предложения',
      to: '/tender/calculation',
    },
    {
      title: 'Запросы на подарки',
      to: '/store/orders',
    },
    {
      title: 'Шаблоны уведомлений',
      to: '/notification_templates',
    },
    {
      title: 'Уведомление клиентов',
      to: '/notifications/global',
    },
    {
      title: 'Зарплаты',
      to: '/salary',
    },
    {
      title: 'Рентабельность',
      to: '/profitability',
    },
    {
      title: 'Не созданные счета',
      to: '/failed_billings'
    },
    {
      title: 'Заказы с понижением статуса',
      to: '/downgraded_orders',
    },
  ];

  const renderMenu = useCallback(
    (item) => {
      if (item.submenu) {
        return (
          <SubMenu
            title={item.title}
            className="ant-menu-submenu ant-menu-submenu-horizontal gx-menu-horizontal"
            onTitleClick={() => history.push(item.submenu[0].to)}
          >
            {item.submenu.map((subitem) => renderMenu(subitem))}

          </SubMenu>
        );
      }
      return (
        <Menu.Item className="ant-menu-submenu ant-menu-submenu-horizontal gx-menu-horizontal">
          <Link className="ant-menu-submenu-title" to={item.to}>
            <span>{item.title}</span>
          </Link>
        </Menu.Item>
      );
    }, [menu],
  );

  const filteredMenu = useMemo(() => {
    return menu.filter(item => {
      if (user && user.salaryManagerType) {
        return ![
          ...COMMON_EXCLUDED_FOR_MANAGER, 
          ...(user.salaryManagerType === MANAGER_TYPES.DEVELOPMENT_MANAGER ? DEVELOPMENT_EXCLUDED_FOR_MANAGER : [])
        ].includes(item.to);
      }
      return true
    })
  }, [user])

  return (
    <Menu
      className="ant-menu ant-menu-light ant-menu-root ant-menu-horizontal"
      mode="horizontal"
    >
      {filteredMenu.map((item) => renderMenu(item))}
    </Menu>

  );
}

export default HorizontalNav;
