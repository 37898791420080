import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const OrderList = React.lazy(() => import('../components/Store/OrderList'));

function OrderListView(props: anyObject) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <OrderList {...props} />
    </Suspense>
  );
}

export default OrderListView;
