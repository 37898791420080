import { RcFile } from 'antd/lib/upload/interface';

export default function (blob: Blob | File | RcFile) {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
}
