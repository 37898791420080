import React, { Suspense } from 'react';
import CircularProgress from '../../../../components/CircularProgress';

const ActStatistic = React.lazy(() => import('../components/ActStatistic'));

function ActStatisticView(props: anyObject) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <ActStatistic {...props} />
    </Suspense>
  );
}

export default ActStatisticView;
