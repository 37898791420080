import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const ComplexContractCreation = React.lazy(() => import('../components/ComplexContractCreation'));
function ComplexContractCreationView(props:any) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <ComplexContractCreation {...props} />
    </Suspense>
  );
}

export default ComplexContractCreationView;
