function addOrUpdate<T extends anyObject>(arr: Array<T>, key: keyof T | Array<keyof T>, value: T): Array<T> {
  const tmp = arr.slice();

  if (Array.isArray(key)) {
    const index = tmp.findIndex((val) => key.every((_) => val[_] === value[_]));
    if (index > -1) tmp[index] = value;
    else tmp.push(value);
  } else {
    const index = tmp.findIndex((val) => val[key] === value[key]);
    if (index > -1) tmp[index] = value;
    else tmp.push(value);
  }

  return tmp;
}

export default addOrUpdate;
