import React, { useCallback } from 'react';
import moment from 'moment';
import DeliveryServiceField from '../../../components/form/DeliveryServiceField';
import DeliveryServiceLabel from '../../../components/form/DeliveryServiceField/DeliveryServiceLabel';
import List from '../../../components/List';
import DateRangeField from '../../../components/form/DateRangeField';
import { generateProfitabilityReport, loadProfitabilityList } from '../action-creators';
import { SERVER_DATE_FORMAT, USER_DATE_FORMAT } from '../../../utils/constants';
import withQueryParams, { ConfigValueType, SetFieldValueFunc } from '../../../utils/withQueryParams';
import useDispatch from '../../../utils/hooks/useDispatch';
import useSelector from '../../../utils/hooks/useSelector';
import DeliveryRateField, { DeliveryRateLabel } from '../../../components/filters/DeliveryRateField';
import { SellerLabel } from '../components/SellerLabel';
import SellerField from '../../../components/filters/SellerField';
import { ProfitabilityTable } from '../components/ProfitabilityTable';
import NumberRangeField from '../../../components/form/NumberRangeField';
import CityField from '../../../components/filters/CityField';
import { Button } from 'antd';
import ProfitabilityImportModal from '../components/ProfitabilityImportModal';
import AccountField from '../../../components/filters/AccountField';
import { ProfitabilityUpdateModal } from '../components/ProfitabilityUpdateModal';
import { profitabilityUpdateTypes } from '../constants';

const QUERY_PARAMS_CONFIG = {
  query: { type: ConfigValueType.STRING },
  page: { type: ConfigValueType.PAGE_NUMBER },
  service: { type: ConfigValueType.STRING },
  createdAtFrom: { type: ConfigValueType.DATE },
  createdAtTo: { type: ConfigValueType.DATE },
  deliveryDateFrom: { type: ConfigValueType.DATE },
  deliveryDateTo: { type: ConfigValueType.DATE },
  tariff: { type: ConfigValueType.ARRAY },
  sellerId: { type: ConfigValueType.INTEGER },
  serviceCostFrom: { type: ConfigValueType.INTEGER },
  serviceCostTo: { type: ConfigValueType.INTEGER },
  serviceWeightFrom: { type: ConfigValueType.INTEGER },
  serviceWeightTo: { type: ConfigValueType.INTEGER },
  essentialCostFrom: { type: ConfigValueType.INTEGER },
  essentialCostTo: { type: ConfigValueType.INTEGER },
  cityFrom: { type: ConfigValueType.STRING },
  cityTo: { type: ConfigValueType.STRING },
  accountId: { type: ConfigValueType.INTEGER },
  isLowProfitability: { type: ConfigValueType.BOOLEAN }
  
};

interface ProfitabilityListParams {
  query: string,
  page: number,
  createdAtFrom: null | moment.Moment,
  createdAtTo: null | moment.Moment,
  service: string,
  deliveryDateFrom: null | moment.Moment,
  deliveryDateTo: null | moment.Moment,
  tariff: string[],
  sellerId: number,
  serviceCostFrom: number,
  serviceCostTo: number,
  serviceWeightFrom: number,
  serviceWeightTo: number,
  essentialCostFrom: number,
  essentialCostTo: number,
  cityFrom: string,
  cityTo: string,
  accountId: number,
  isLowProfitability: boolean
}

interface ProfitabilityListProps {
  params: ProfitabilityListParams,
  setFieldValue: SetFieldValueFunc<ProfitabilityListParams>,
}

function ProfitabilityTableView({ setFieldValue, params }: ProfitabilityListProps) {
  const dispatch = useDispatch();
  const { profitabilityCount, profitabilityTotals, reportURL } = useSelector((state) => state.profitability);
  const { user } = useSelector((state) => state.users);
  const { rates } = useSelector((state) => state.reference);
  const [loading, setLoading] = React.useState(false);
  const [isProfitabilityModal, setIsProfitabilityModal] = React.useState(false);
  const [profitabilityUpdateType, setProfitabilityUpdateType] = React.useState<profitabilityUpdateTypes | null>(null);
  const [selectedOrders, setSelectedOrders] = React.useState<string[]>([]);

  const [isReportGenerating, setIsReportGenerating] = React.useState<boolean>(false);

  const handleLoadProfitability = React.useCallback(
    (newParams: ProfitabilityListParams) => {
      const {
        query, page, createdAtFrom, createdAtTo, deliveryDateFrom, deliveryDateTo, ...restFilters
      } = newParams;
      setLoading(true);

      const filters: anyObject = { ...restFilters };

      if (deliveryDateFrom) filters.deliveryDateFrom = deliveryDateFrom.format(SERVER_DATE_FORMAT);
      if (deliveryDateTo) filters.deliveryDateTo = deliveryDateTo.format(SERVER_DATE_FORMAT);
      
      dispatch(loadProfitabilityList(
        // managerId ? Number(managerId) : 0,
        query,
        page,
        createdAtFrom?.format(SERVER_DATE_FORMAT),
        createdAtTo?.format(SERVER_DATE_FORMAT),
        filters,
      )).finally(() => setLoading(false));
    },
    [],
  );

  const handlePageChange = React.useCallback(
    (page) => {
      setFieldValue({ page }, handleLoadProfitability);
    },
    [handleLoadProfitability],
  );

  const handleFilterChange = React.useCallback(
    (value, filter: string) => {
      setFieldValue({ [filter]: value, page: 1 }, handleLoadProfitability);
    },
    [handleLoadProfitability],
  );

  const handleRangeFilterChange = React.useCallback(
    ([from, to], filter: string) => {
      setFieldValue({ [`${filter}From`]: from, [`${filter}To`]: to, page: 1 }, handleLoadProfitability);
    },
    [handleLoadProfitability],
  );

  const handleDateFilterClean = React.useCallback(
    (filter: string) => {
      setFieldValue({ [filter]: null }, handleLoadProfitability);
    },
    [handleLoadProfitability],
  );

  const getClearFilter = React.useCallback(() => {
    return Object.assign(
      //@ts-ignore
      ...Object.entries(params).map(([key, value]) => ({ [key]: null }))
    );
  }, [params])

  const handleShowWithoutBilling = React.useCallback(() => {
    setFieldValue({ ...getClearFilter(), essentialCostTo: -1 }, handleLoadProfitability);
  }, [getClearFilter])

  const handleShowMissing = React.useCallback(() => {
    setFieldValue({ ...getClearFilter(), serviceCostTo: -1, deliveryDateFrom: moment('2023-01-01') }, handleLoadProfitability);
  }, [getClearFilter])

  const handleShowLowProfitability = React.useCallback(() => {
    setFieldValue({ ...getClearFilter(), isLowProfitability: true }, handleLoadProfitability);
  }, [getClearFilter])

  const tags = React.useMemo(() => {
    const {
      service,
      createdAtFrom,
      createdAtTo,
      deliveryDateFrom,
      deliveryDateTo,
      tariff,
      sellerId,
      serviceCostFrom,
      serviceCostTo,
      serviceWeightFrom,
      serviceWeightTo,
      cityFrom,
      cityTo,
      essentialCostFrom,
      essentialCostTo,
      isLowProfitability
    } = params;
    const _: anyObject[] = [];

    if (createdAtFrom) {
      _.push({
        onClose: () => handleDateFilterClean('createdAtFrom'),
        content: <span>Дата создания: c {moment(createdAtFrom).format(USER_DATE_FORMAT)}</span>,
      });
    }
    if (createdAtTo) {
      _.push({
        onClose: () => handleDateFilterClean('createdAtTo'),
        content: <span>Дата создания: по {moment(createdAtTo).format(USER_DATE_FORMAT)}</span>,
      });
    }
    if (deliveryDateFrom) {
      _.push({
        onClose: () => handleDateFilterClean('deliveryDateFrom'),
        content: <span>Дата доставки: с {moment(deliveryDateFrom).format(USER_DATE_FORMAT)}</span>,
      });
    }
    if (deliveryDateTo) {
      _.push({
        onClose: () => handleDateFilterClean('deliveryDateTo'),
        content: <span>Дата доставки: по {moment(deliveryDateTo).format(USER_DATE_FORMAT)}</span>,
      });
    }
    if (service) {
      _.push({
        onClose: () => handleFilterChange(null, 'service'),
        content: <span><DeliveryServiceLabel code={service}/></span>,
      });
    }
    if (tariff && tariff.length > 0) {
      tariff.forEach((code) => {
        const rate = rates[code];
        _.push({
          onClose: () => handleFilterChange(tariff.filter((__) => __ !== code), 'tariff'),
          content: <DeliveryRateLabel code={code} />,
          tooltip: rate?.title,
        });
      });
    }
    if (sellerId) {
      _.push({
        onClose: () => handleFilterChange(null, 'sellerId'),
        content: <SellerLabel sellerId={sellerId}/>,
      });
    }
    if (serviceCostFrom) {
      _.push({
        onClose: () => handleFilterChange(null, 'serviceCostFrom'),
        content: <span>Стоимость в курьерке: от {serviceCostFrom}</span>,
      });
    }
    if (serviceCostTo) {
      _.push({
        onClose: () => handleFilterChange(null, 'serviceCostTo'),
        content: <span>Стоимость в курьерке: до {serviceCostTo}</span>,
      });
    }
    if (serviceWeightFrom) {
      _.push({
        onClose: () => handleFilterChange(null, 'serviceWeightFrom'),
        content: <span>Вес к оплате: от {serviceWeightFrom}</span>,
      });
    }
    if (serviceWeightTo) {
      _.push({
        onClose: () => handleFilterChange(null, 'serviceWeightTo'),
        content: <span>Вес к оплате: до {serviceWeightTo}</span>,
      });
    }
    if (essentialCostFrom) {
      _.push({
        onClose: () => handleFilterChange(null, 'essentialCostFrom'),
        content: <span>Стоимость у нас: от {essentialCostFrom}</span>,
      });
    }
    if (essentialCostTo) {
      _.push({
        onClose: () => handleFilterChange(null, 'essentialCostTo'),
        content: <span>Стоимость у нас: до {essentialCostTo}</span>,
      });
    }
    if (cityFrom) {
      _.push({
        onClose: () => handleFilterChange(null, 'cityFrom'),
        content: <span>Сбор: {cityFrom}</span>,
      });
    }
    if (cityTo) {
      _.push({
        onClose: () => handleFilterChange(null, 'cityTo'),
        content: <span>Доставка: {cityTo}</span>,
      });
    }
    if (isLowProfitability) {
      _.push({
        onClose: () => handleFilterChange(null, 'isLowProfitability'),
        content: <span>С низкой рентабельностью</span>,
      });
    }

    return _;
  }, [params, rates]);

  React.useEffect(() => {
    handleLoadProfitability(params);
  }, []);

  const handleReport = () => {
    const {
      query, page, createdAtFrom, createdAtTo, deliveryDateFrom, deliveryDateTo, ...restFilters
    } = params;

    setIsReportGenerating(true)

    const filters: anyObject = { ...restFilters };

    if (deliveryDateFrom) filters.deliveryDateFrom = deliveryDateFrom.format(SERVER_DATE_FORMAT);
    if (deliveryDateTo) filters.deliveryDateTo = deliveryDateTo.format(SERVER_DATE_FORMAT);
    
    dispatch(generateProfitabilityReport(
      query,
      page,
      createdAtFrom?.format(SERVER_DATE_FORMAT),
      createdAtTo?.format(SERVER_DATE_FORMAT),
      filters,
    )).finally(() => setIsReportGenerating(false));
  }

  return (
    <>
      <ProfitabilityImportModal isOpened={isProfitabilityModal} onClose={() => setIsProfitabilityModal(false)}/>
      <ProfitabilityUpdateModal updateType={profitabilityUpdateType} selectedOrders={selectedOrders} handleClose={() => setProfitabilityUpdateType(null)}/>
      <List
        title={
          <div className="gx-d-flex gx-flex-column">
            <div className="title h1"><p>Рентабельность</p></div>
            <p className='gx-mr-1 gx-font-weight-normal gx-mb-1' style={{fontSize: "16px"}}>Стоимость общая: {profitabilityTotals?.totalBilling ? profitabilityTotals?.totalBilling.toFixed(2) : null}</p>
            <p className='gx-mr-1 gx-font-weight-normal gx-mb-1' style={{fontSize: "16px"}}>Стоимость в курьерке общая: {profitabilityTotals?.totalServiceCost ? profitabilityTotals?.totalServiceCost.toFixed(2) : null}</p>
            <p className="gx-mr-1 gx-font-weight-normal gx-mb-1" style={{fontSize: "16px"}}>Вес общий: {profitabilityTotals?.totalServiceWeight ? profitabilityTotals?.totalServiceWeight.toFixed(2) : null}</p>
          </div>
        }
        totalCount={profitabilityCount}
        currentPage={params.page}
        searchPlaceholder="Укажите номер накладной"
        handlePageChange={handlePageChange}
        searchValue={params.query}
        onSearchChange={(query: string) => handleFilterChange(query, 'query')}
        addon={(
          <>
          <Button className="gx-w-100" type="primary" ghost style={{ margin: '0' }} onClick={() => setIsProfitabilityModal(true)}>
            Добавить рентабельность
          </Button>
          {
            user?.isAdmin
            ?
            <>
              <Button className="gx-w-100 gx-mt-2" type="primary" ghost style={{ margin: '0' }} onClick={() => setProfitabilityUpdateType(selectedOrders.length ? profitabilityUpdateTypes.BY_NUMBERS : profitabilityUpdateTypes.BY_DELIVERY_SERVICE)}>
                Изменить стоимость
              </Button>
              {
                reportURL
                &&
                <a href={reportURL} target="_blank" className='ant-btn gx-w-100 gx-mt-2 ant-btn-primary ant-btn-background-ghost' style={{ margin: '0' }}>Скачать</a>
                ||
                <Button className="gx-w-100 gx-mt-2" type="primary" ghost style={{ margin: '0' }} onClick={handleReport} loading={isReportGenerating}>
                  Создать отчет
                </Button>
              }
              <Button className="gx-w-100 gx-mt-2" type="primary" ghost style={{ margin: '0' }} onClick={handleShowWithoutBilling} loading={loading}>
                Показать без стоимости
              </Button>
              <Button className="gx-w-100 gx-mt-2" type="primary" ghost style={{ margin: '0' }} onClick={handleShowMissing} loading={loading}>
                Показать отсутствующие
              </Button>
              <Button className="gx-w-100 gx-mt-2" type="primary" ghost style={{ margin: '0' }} onClick={handleShowLowProfitability} loading={loading}>
                Показать с низкой рентабельностью
              </Button>
            </>
            :
            null
          }
          </>
        )}
        filters={[
          {
            title: 'Дата создания',
            Component: DateRangeField,
            value: [params.createdAtFrom, params.createdAtTo],
            onChange: (vals: [any, any]) => handleRangeFilterChange(vals, 'createdAt'),
            componentProps: {
              fromPlaceholder: 'Создан c',
              toPlaceholder: 'Создан по',
            },
          },
          {
            title: 'Дата доставки',
            Component: DateRangeField,
            value: [params.deliveryDateFrom, params.deliveryDateTo],
            onChange: (vals: [any, any]) => handleRangeFilterChange(vals, 'deliveryDate'),
            componentProps: {
              fromPlaceholder: 'Дата доставки c',
              toPlaceholder: 'Дата доставки по',
            },
          },
          {
            title: 'Перевозчик',
            Component: DeliveryServiceField,
            value: params.service,
            onChange: (val: string) => handleFilterChange(val, 'service'),
            componentProps: {
              single: true,
            },
          },
          {
            title: 'Тариф',
            Component: DeliveryRateField,
            value: params.tariff,
            onChange: (val: string[]) => handleFilterChange(val, 'tariff'),
            componentProps: {
              deliveryService: params.service,
            },
          },
          {
            title: 'Селлер',
            Component: SellerField,
            value: params.sellerId,
            onChange: (val: string[]) => handleFilterChange(val, 'sellerId'),
          },
          {
            title: 'Аккаунт',
            Component: AccountField,
            value: params.accountId,
            onChange: (val: any) => handleFilterChange(val, 'accountId'),
          },
          {
            title: 'Стоимость в курьерке',
            Component: NumberRangeField,
            value: [params.serviceCostFrom, params.serviceCostTo],
            onChange: (vals: [any, any]) => handleRangeFilterChange(vals, 'serviceCost'),
            componentProps: {
              fromPlaceholder: 'От',
              toPlaceholder: 'До',
            },
          },
          {
            title: 'Вес к оплате',
            Component: NumberRangeField,
            value: [params.serviceWeightFrom, params.serviceWeightTo],
            onChange: (vals: [any, any]) => handleRangeFilterChange(vals, 'serviceWeight'),
            componentProps: {
              fromPlaceholder: 'От',
              toPlaceholder: 'До',
            },
          },
          {
            title: 'Города',
            Component: CityField,
            value: [params.cityFrom, params.cityTo],
            onChange: (vals: [any, any]) => handleRangeFilterChange(vals, 'city'),
            componentProps: {
              fromPlaceholder: 'Сбор',
              toPlaceholder: 'Доставка',
            },
          },
        ]}
        tags={tags}
      >
        <ProfitabilityTable
          loading={loading}
          selectedOrders={selectedOrders}
          setSelectedOrders={setSelectedOrders}
          params={params}
        />
      </List>
    </>
  );
}

export default withQueryParams(QUERY_PARAMS_CONFIG)(ProfitabilityTableView);
