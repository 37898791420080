import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withQueryParams } from '../../../helpers';
import List from '../../../components/List';
import SalaryManagerTable from '../components/SalaryManagerTable';
import {
  loadSalaryManagerList,
} from '../action-creators';

const QUERY_PARAMS_CONFIG = {
  query: { type: 'string' },
  page: { type: 'pageNumber' }
};

class SalaryManagerTableView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.loadManagerSalary();
  }

  setLoading = (loading) => this.setState({ loading });

  loadManagerSalary = () => {
    const {
      params: {
        query, page
      },
    } = this.props;
    this.setLoading(true);
    this.props.loadSalaryManagerList(
      query,
      page,
    ).finally(
      () => { this.setLoading(false); },
    );
  };

  handlePageChange = (page) => this.props.setFieldValue({ page }, this.loadManagerSalary);

  handleSearchChange = (query) => this.props.setFieldValue({ query, page: 1 }, this.loadManagerSalary);

  render() {
    const { params: { query, page }, salaryManagerList } = this.props;
    const { loading } = this.state;
    return (
      <List
        title="Зарплаты"
        totalCount={salaryManagerList.length}
        currentPage={page}
        handlePageChange={this.handlePageChange}
        searchValue={query}
        searchPlaceholder="Введите менеджера"
        onSearchChange={this.handleSearchChange}
        loading={loading}
      >
        <SalaryManagerTable loading={loading} />
      </List>
    );
  }
}

export default connect(
  ({ salary: { salaryManagerList } }) => ({ salaryManagerList }),
  { loadSalaryManagerList },
)(withQueryParams(QUERY_PARAMS_CONFIG)(SalaryManagerTableView));
