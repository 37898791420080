import { Account } from '../accounts/types';
import { ShortUserInfo } from '../users/types';

export const SET_CURRENT_WAYBILL = 'SET_CURRENT_WAYBILL';
export const RESET_CURRENT_WAYBILL = 'RESET_CURRENT_WAYBILL';
export const SET_CURRENT_WAYBILL_TRACKING_LIST = 'SET_CURRENT_WAYBILL_TRACKING_LIST';
export const ADD_CURRENT_WAYBILL_TRACKING_LIST = 'ADD_CURRENT_WAYBILL_TRACKING_LIST';
export const RESET_CURRENT_WAYBILL_TRACKING_LIST = 'RESET_CURRENT_WAYBILL_TRACKING_LIST';
export const SET_CURRENT_WAYBILL_TRACKING_COUNT = 'SET_CURRENT_WAYBILL_TRACKING_COUNT';
export const SET_CURRENT_ORDER_SERVICE_LIST = 'SET_CURRENT_ORDER_SERVICE_LIST';
export const SET_CURRENT_WAYBILL_PRICING_LIST = 'SET_CURRENT_WAYBILL_PRICING_LIST';

export interface WaybillContactPhone {
  number: string
}

export interface WaybillContactType {
  iso: string,
  postcode: string,
  region: string,
  district: string,
  city: string,
  street: string,
  house: string,
  companyName: string,
  contactName: string,
  longitude: number,
  latitude: number,
  phones: Array<WaybillContactPhone>,
  office: string,
  email: string,
  comment: string,
}

export interface WaybillCargoPackage {
  width: number,
  height: number,
  length: number,
  weight: number,
  description: string
}

export enum WaybillCargoAdditionalServiceCode {
  INSURANCE = 'INSURANCE',
  CARGO_LOADING = 'CARGO_LOADING',
  CARGO_UNLOADING = 'CARGO_UNLOADING'
}

export type WaybillCargoAdditionalService = {
  serviceCode: WaybillCargoAdditionalServiceCode;
};

export type WaybillCargoInsurance = {
  insuranceValueRate: number,
  insuranceAmount: number,
  insuranceRate: string,
};


export interface WaybillCargo {
  cargoId: string,
  packages: Array<WaybillCargoPackage>,
  services: WaybillCargoAdditionalService[],
  insurance: WaybillCargoInsurance,
}

export type WaybillStatus = 'registered' | 'processed' | 'performed' | 'canceled';

export interface Order {
  cost: number,
  orderNumber: string,
  status: WaybillStatus
}

export interface Waybill {
  status: string,
  plsWaybillNumber: string,
  serviceWaybillNumber: string,
  accountId: number,
  accountName: string,
  senderCity: string,
  receiverCity: string,
  createdDate: string,
  pickupDate: string,
  deliveryDate: string,
  deliveryService: string,
  rateName: string,
  rateCost: number,
  ratePrice: number,
  account: Account | null,
  invoiceUrl: string,
  order: Order | null
}

export interface WaybillDetail {
  id: number,
  plsWaybillNumber: string,
  serviceWaybillNumber: string,
  deliveryService: string,
  plsOrderNumber: string,
  status: WaybillStatus,
  createdAt: string,
  cargo: WaybillCargo | null,
  senderContact: WaybillContactType | null,
  receiverContact: WaybillContactType | null,
  rateName: string,
  pickupDate: string,
  deliveryDate: string,
  rateCost: number | null,
  servicePickupNumber?: string,
  accountId: number,
  invoiceUrl: string,
  order: Order | null,
  createdBy?: ShortUserInfo,
}

export interface WaybillTracking {
  description: string,
  date?: string,
  createdAt: string
}

export interface WaybillPricing {
  code: string,
  amount: number,
  comment: string
}

export interface SetCurrentWaybill {
  type: typeof SET_CURRENT_WAYBILL,
  waybill: WaybillDetail
}

export interface ResetCurrentWaybill {
  type: typeof RESET_CURRENT_WAYBILL,
}

export interface SetCurrentWaybillTrackingList {
  type: typeof SET_CURRENT_WAYBILL_TRACKING_LIST,
  tracking: Array<WaybillTracking>
}

export interface SetCurrentWaybillPricingList {
  type: typeof SET_CURRENT_WAYBILL_PRICING_LIST,
  pricing: Array<WaybillPricing>
}

export interface AddCurrentWaybillTrackingList {
  type: typeof ADD_CURRENT_WAYBILL_TRACKING_LIST,
  tracking: Array<WaybillTracking>
}

export interface ResetCurrentWaybillTrackingList {
  type: typeof RESET_CURRENT_WAYBILL_TRACKING_LIST
}

export interface SetCurrentWaybillTrackingCount {
  type: typeof SET_CURRENT_WAYBILL_TRACKING_COUNT,
  count: number,
}

export interface SetCurrentOrderServiceList {
  type: typeof SET_CURRENT_ORDER_SERVICE_LIST,
  serviceList: Array<any>,
}

export type waybillActions = SetCurrentWaybill | ResetCurrentWaybill | SetCurrentWaybillTrackingList | AddCurrentWaybillTrackingList |
ResetCurrentWaybillTrackingList | SetCurrentWaybillTrackingCount | SetCurrentOrderServiceList | SetCurrentWaybillPricingList;
