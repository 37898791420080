import React from 'react';
import {
  Col, Row, Button, Icon,
} from 'antd';

function SimplePagination({
  current,
  pageSize = 25,
  total,
  onChange,
  loading,
}) {
  const canDecrement = current > 1;
  const totalPages = Math.ceil(total / pageSize);
  const canIncrement = current < totalPages;
  const incrementPage = () => (canIncrement ? onChange(current + 1) : null);
  const decrementPage = () => (canDecrement ? onChange(current - 1) : null);

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flexGrow: '1', textAlign: 'right', paddingRight: '10px' }}>
        {!loading && (
          <>
            <div>Стр. {current} из {totalPages}</div>
            <div style={{ fontSize: '12px' }}>
              Записи {((current - 1) * pageSize) + 1} - {Math.min(current * pageSize, total)} из {total}
            </div>
          </>
        )}
      </div>
      <div>
        <div className="ant-input-wrapper ant-input-group ant-input-group-compact" style={{ width: '91px' }}>
          <Button className="gx-simple-pagination-button" onClick={decrementPage} disabled={!canDecrement}>
            <Icon type="left" />
          </Button>
          <Button className="gx-simple-pagination-button" onClick={incrementPage} disabled={!canIncrement}>
            <Icon type="right" />
          </Button>
        </div>
      </div>
    </div>

  );
}

export default SimplePagination;
