import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const AccountDialogList = React.lazy(() => import('../components/AccountDialogList'));
function AccountDialogListView(props: any) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <AccountDialogList {...props} />
    </Suspense>
  );
}

export default AccountDialogListView;
