import { Moment } from 'moment';

export const SET_ACTS_LIST = 'SET_ACTS_LIST';
export const SET_CURRENT_ACT = 'SET_CURRENT_ACT';
export const SET_ACTS_COUNT = 'SET_ACTS_COUNT';
export const SET_ACTS_PAGE = 'SET_ACTS_PAGE';
export const SET_ACTS_SEARCH = 'SET_ACTS_SEARCH';
export const SET_ACTS_DATE_FROM = 'SET_ACTS_DATE_FROM';
export const SET_ACTS_DATE_TO = 'SET_ACTS_DATE_TO';
export const SET_ACTS_ACCOUNT = 'SET_ACTS_ACCOUNT';
export const SET_CURRENT_ACT_ORDERS_LIST = 'SET_CURRENT_ACT_ORDERS_LIST';
export const SET_CURRENT_ACT_ORDERS_COUNT = 'SET_CURRENT_ACT_ORDERS_COUNT';
export const SET_ACT_STATISTIC = 'SET_ACT_STATISTIC';

export interface Act {
  actId: number,

  accountId: number,
  contractId: number,

  number: string,
  date: string,
  dateFrom: string,
  dateTo: string,
  amount: number,

  actFileUrl: string,
  invoiceFileUrl: string,
  // billInvoiceFileUrl: string,
  // detailFileUrl: string,
}

export interface ActStatisticSeries {
  date: string,
  count: number,
  sum: number,
}

export interface SetActsList {
  type: typeof SET_ACTS_LIST,
  acts: Array<Act>
}

export interface SetActsCount {
  type: typeof SET_ACTS_COUNT,
  count: number
}

export interface SetActsPage {
  type: typeof SET_ACTS_PAGE,
  page: number
}

export interface SetActsSearch {
  type: typeof SET_ACTS_SEARCH,
  search: string,
}

export interface SetActsDateFrom {
  type: typeof SET_ACTS_DATE_FROM,
  date: Moment | null
}

export interface SetActsDateTo {
  type: typeof SET_ACTS_DATE_TO,
  date: Moment | null
}

export interface SetActsAccount {
  type: typeof SET_ACTS_ACCOUNT,
  accountId: number | null
}

export interface SetCurrentAct {
  type: typeof SET_CURRENT_ACT,
  act: Act
}

export interface SetCurrentActOrdersList {
  type: typeof SET_CURRENT_ACT_ORDERS_LIST,
  orders: Array<any>,
}

export interface SetCurrentActOrdersCount {
  type: typeof SET_CURRENT_ACT_ORDERS_COUNT,
  count: number,
}

export interface SetActStatistic {
  type: typeof SET_ACT_STATISTIC,
  statistic: ActStatisticSeries[],
}

export type ActActions = SetActsList | SetActsCount | SetActsPage | SetActsSearch | SetActsDateFrom | SetActsDateTo |
SetActsAccount | SetCurrentAct | SetCurrentActOrdersList | SetCurrentActOrdersCount | SetActStatistic;
