import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import { Col, Row } from 'antd';
import Card from 'antd/lib/card';
import DateRangeField from 'components/form/DateRangeField';
import { withQueryParams } from 'helpers';
import SiteVisit from './components/WaybillStat';
import useSelector from '../../../utils/hooks/useSelector';
import { loadWaybillStat } from '../action-creators';
import useDispatch from '../../../utils/hooks/useDispatch';
import { SERVER_DATE_FORMAT } from '../../../utils/constants';
import ListModeSwitch from '../../../components/List/ListModeSwitch';
import WaybillStatTabContent from '../components/WaybillStatTabContent';
import WaybillTotalStatTabContent from '../components/WaybillTotalStatTabContent';

const QUERY_PARAMS_CONFIG = {
  dateFrom: { type: 'date' },
  dateTo: { type: 'date' },
};

function StatisticView({ params: { dateFrom, dateTo }, setFieldValue }) {
  const history = useHistory();
  const [currentTab, setCurrentTab] = React.useState('pickup');

  const FROM_DEFAULT_VALUE = moment().subtract(90, 'days');
  const TO_DEFAULT_VALUE = moment();

  const value = [dateFrom || FROM_DEFAULT_VALUE, dateTo || TO_DEFAULT_VALUE];

  const handleDateRangeChange = ([dateFrom, dateTo]) => {
    setFieldValue({
      dateFrom: dateFrom || FROM_DEFAULT_VALUE,
      dateTo: dateTo || TO_DEFAULT_VALUE,
    });
  };

  const goToList = () => {
    history.push('/shipping');
  };

  return (
    <>
      <Row>
        <Col lg={12} md={12} sm={12} xs={24}>
          <div className="title h1"><p>Заказы</p></div>
        </Col>
        <Col lg={12} md={12} sm={12} xs={24}>
          <div className="gx-float-right">
            <ListModeSwitch onListClick={goToList} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <Row>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Card
                onTabChange={setCurrentTab}
                activeTabKey={currentTab}
                tabList={[
                  {
                    key: 'pickup',
                    tab: <div>По забору</div>,
                  },
                  {
                    key: 'create',
                    tab: <div>По созданию</div>,
                  },
                ]}
                hoverable
                extra={(
                  <DateRangeField
                    value={value}
                    onChange={handleDateRangeChange}
                    singleLine
                    style={{
                      display: 'flex',
                      float: 'right',
                    }}
                    fromLabel="Дата с:&nbsp;"
                    toLabel="&nbsp;&nbsp;&nbsp;по:&nbsp;"
                  />
                )}
              >
                {currentTab === 'create'
                && (
                <WaybillTotalStatTabContent
                  dateFrom={value[0]}
                  dateTo={value[1]}
                />
                )}
                {currentTab === 'pickup'
                && (
                <WaybillStatTabContent
                  dateFrom={value[0]}
                  dateTo={value[1]}
                />
                )}
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default withQueryParams(QUERY_PARAMS_CONFIG)(StatisticView);
