import React, { useCallback } from 'react';

const DELIVERY_SERVICE_NAMES = {
  cdek: 'CDEK',
  cse: 'CSE',
  dpd: 'DPD',
  pony: 'Pony Express',
};

function DeliveryServiceLabel({ code }) {
  return (
    <>{DELIVERY_SERVICE_NAMES[code]}</>
  );
}

export default DeliveryServiceLabel;
