import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const ContractDetail = React.lazy(() => import('../components/ContractDetail'));
function ContractView(props) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <ContractDetail {...props} />
    </Suspense>
  );
}

export default ContractView;
