import React from 'react';
import { Col, Row } from 'antd';
import SearchBox from './components/SearchBox';
import SupportTable from './components/SupportTable';

function SupportView() {
  return (
    <>
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <div className="title h1"><p>Обращения</p></div>
        </Col>
      </Row>
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <SearchBox />
        </Col>
      </Row>
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <SupportTable />
        </Col>
      </Row>
    </>
  );
}

export default SupportView;
