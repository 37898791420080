import React from 'react';
import {
  DatePicker, Form, Input, TimePicker, Select, InputNumber,
} from 'antd';
import GeoAutoComplete from '../customComponents/GeoAutoComplete';
import MaskInput from './MaskInput';
import CustomSelect from './CustomSelect';

const FormItem = Form.Item;
const { Option } = Select;

export const CreateAntField = (AntComponent) => ({
  field,
  form,
  hasFeedback,
  label,
  selectOptions,
  type,
  getError,
  errorName,
  children,
  className,
  componentClassName,
  ...props
}) => {
  const touched = form.touched[field.name];
  const submitted = form.submitCount > 0;
  let hasError = null;
  if (typeof getError === 'function') {
    hasError = getError(form.errors, field.name);
  } else {
    hasError = form.errors[field.name] || form.errors[errorName];
  }
  const submittedError = hasError && submitted;
  const touchedError = hasError && touched;
  const onInputChange = ({ target: { value } }) => {
    const err = { ...form.errors };
    if (err.hasOwnProperty(field.name)) {
      err[field.name] = undefined;
      form.setErrors(err);
    }
    if (err.hasOwnProperty(errorName)) {
      err[errorName] = undefined;
      form.setErrors(err);
    }
    form.setFieldValue(field.name, value);
    if (typeof props.onChange === 'function') props.onChange(value);
  };
  const onChange = (value) => {
    const err = { ...form.errors };
    if (err.hasOwnProperty(field.name)) {
      err[field.name] = undefined;
      form.setErrors(err);
    }
    if (err.hasOwnProperty(errorName)) {
      err[errorName] = undefined;
      form.setErrors(err);
    }
    form.setFieldValue(field.name, value);
    if (typeof props.onChange === 'function') props.onChange(value);
  };
  const onBlur = () => form.setFieldTouched(field.name, true);
  return (
    <FormItem
      label={label}
      hasFeedback={
        !!((hasFeedback && submitted) || (hasFeedback && touched))
      }
      help={submittedError || touchedError ? hasError : false}
      validateStatus={submittedError || touchedError ? 'error' : 'success'}
      wrapperCol={{ span: 24 }}
      labelCol={{ xs: 24 }}
      labelAlign="left"
      className={className}
    >
      <AntComponent
        {...field}
        {...props}
        className={componentClassName}
        onBlur={onBlur}
        onChange={type ? onInputChange : onChange}
        type={type}
      >
        {selectOptions
        && selectOptions.map(({ key, title, component }) => <Option key={key}>{component || title}</Option>)}
      </AntComponent>
      {children}
    </FormItem>
  );
};

export const AntSelect = CreateAntField(Select);
export const AntDatePicker = CreateAntField(DatePicker);
export const AntDateRangePicker = CreateAntField(DatePicker.RangePicker);
export const AntInput = CreateAntField(Input);
export const AntTextArea = CreateAntField(Input.TextArea);
export const AntInputNumber = CreateAntField(InputNumber);
export const AntTimePicker = CreateAntField(TimePicker);
export const AntTextarea = CreateAntField(Input.TextArea);
export const AntPassword = CreateAntField(Input.Password);
export const AntGeoAutoComplete = CreateAntField(GeoAutoComplete);
export const AntMaskInput = CreateAntField(MaskInput);
export const AntCustomSelect = CreateAntField(CustomSelect);
export const AntMonthPicker = CreateAntField(DatePicker.MonthPicker);
