import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

export interface Crumb {
  to?: string,
  title: string
}

export interface BreadcrumbsProps {
  items: Crumb[]
}

function Breadcrumbs({ items }: BreadcrumbsProps) {
  return (
    <Breadcrumb className="gx-pt-3 gx-pb-3">
      {items.map(({ title, to }, index) => (
        <Breadcrumb.Item>
          {(index === items.length - 1 || !to) ? title : <Link to={to} className="gx-text-blue">{title}</Link>}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}

export default Breadcrumbs;
