import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import MainView from '../views/MainView';
import PrivateRoute from './PrivateRoute';
import ContractView from '../app/contracts/views/ContractView';

import ShippingView from '../app/shipping/views/ShippingView';

import ShippingDetailOldView from '../app/shipping_old/views/ShippingDetailView';

import ActListView from '../app/accounting/acts/views/ActListView';
import ActDetailView from '../app/accounting/acts/views/ActDetailView';
import ClientBillView from '../app/accounting/client_bills/views/ClientBillView';
import CompanyBillView from '../app/accounting/company_bills/views/CompanyBillView';
import SupportView from '../app/support/views/SupportView';
import StatisticView from '../app/statistics/views/StatisticView';
import ComplexContractCreationView from '../app/contracts/views/ComplexContractCreationView';
import AccountDebtView from '../app/accounts/views/AccountDebtView';
import AccountRouter from './AccountRouter';
import ClientEventListView from '../app/notifications/client_event/views/ClientEventListView';
import ActStatisticView from '../app/accounting/acts/views/ActStatisticView';
import TenderCalculationView from '../app/tender/views/TenderCalculationView';
import TenderCalculationListView from '../app/tender/views/TenderCalculationListView';
import OrderListView from '../app/marketing/views/OrderListView';
import OrderDetailView from '../app/marketing/views/OrderDetailView';
import NotificationTemplatesView from '../app/notifications/client_event/views/NotificationTemplatesView';
import NotificationTemplatePreviewView from '../app/notifications/client_event/views/NotificationTemplatePreviewView';
import GlobalNotificationsListView from '../app/notifications/views/GlobalNotificationsListView';
import GlobalNotificationDetailView from '../app/notifications/views/GlobalNotificationDetailView';
import SalaryManagerTableView from '../app/salary/views/SalaryManagerTableView';
import SalaryManagerDetailView from '../app/salary/views/SalaryManagerDetailView';
import ProfitabilityTableView from '../app/profitability/views/ProfitabilityTableView';
import FailedBillingTableView from '../app/failedBilling/views/FailedBillingTableView';
import DowngradedOrdersListView from '../app/downgradedOrders/views/DowngradedOrdersListView';

function AuthorizedRouter() {
  return (
    <MainView>
      <Switch>
        <PrivateRoute exact path="/accounts/:accountId/contracts/:id" component={ContractView} />
        <Route path="/accounts" component={AccountRouter} />

        {/* <PrivateRoute exact path="/contracts" component={ContractListView} /> */}
        <PrivateRoute exact path="/contracts/new" component={ComplexContractCreationView} />
        <PrivateRoute exact path="/contracts/:id" component={ContractView} />

        <PrivateRoute exact path="/shipping" component={ShippingView} />
        <PrivateRoute exact path="/shipping/chart" component={StatisticView} />
        <PrivateRoute exact path="/shipping/:waybillId" component={ShippingDetailOldView} />
        <PrivateRoute exact path="/acts" component={ActListView} />
        <PrivateRoute exact path="/acts/statistic" component={ActStatisticView} />
        <PrivateRoute exact path="/debt" component={AccountDebtView} />
        <PrivateRoute exact path="/client_events" component={ClientEventListView} />
        <PrivateRoute exact path="/acts/:actId" component={ActDetailView} />
        <PrivateRoute exact path="/bills" component={ClientBillView} />

        <PrivateRoute exact path="/services" component={CompanyBillView} />

        <PrivateRoute exact path="/support" component={SupportView} />
        <PrivateRoute exact path="/tender/calculation" component={TenderCalculationListView} />
        <PrivateRoute exact path="/tender/calculation/:tenderCalculationId" component={TenderCalculationView} />

        <PrivateRoute exac path="/store/orders/:orderId" component={OrderDetailView} />
        <PrivateRoute exac path="/store/orders" component={OrderListView} />

        <PrivateRoute exac path="/notification_templates/:type/:template" component={NotificationTemplatePreviewView} />
        <PrivateRoute exac path="/notification_templates" component={NotificationTemplatesView} />

        <PrivateRoute exact path="/notifications/global" component={GlobalNotificationsListView} />
        <PrivateRoute exact path="/notifications/global/create" component={GlobalNotificationDetailView} create />
        <PrivateRoute exact path="/notifications/global/:notificationId" component={GlobalNotificationDetailView} />

        <PrivateRoute exact path="/salary" component={SalaryManagerTableView} />
        <PrivateRoute exact path="/salary/:id" component={SalaryManagerDetailView} />

        <PrivateRoute exact path="/profitability" component={ProfitabilityTableView} />

        <PrivateRoute exact path="/failed_billings" component={FailedBillingTableView} />

        <PrivateRoute exact path="/downgraded_orders" component={DowngradedOrdersListView} />

        <Redirect to="/404" />
      </Switch>
    </MainView>
  );
}

export default AuthorizedRouter;
