import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const AccountDetail = React.lazy(() => import('../components/AccountDetail'));
function AccountDetailView(props) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <AccountDetail {...props} />
    </Suspense>
  );
}

export default AccountDetailView;
