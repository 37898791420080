import React from 'react';
import CircularProgress from '../../../components/CircularProgress';

const GlobalNotificationsList = React.lazy(() => import('../components/GlobalNotificationsList'));

function GlobalNotificationsListView(props: any) {
  return (
    <React.Suspense fallback={<CircularProgress />}>
      <GlobalNotificationsList {...props} />
    </React.Suspense>
  );
}

export default GlobalNotificationsListView;
