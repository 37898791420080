import { Profitability } from '../profitability/types';
import {
  RESET_SALARY_MANAGER,
  RESET_SALARY_STAT,
  SET_SALARY_MANAGER,
  SET_SALARY_MANAGER_LIST,
  SalaryActions,
  SalaryManager,
  UPDATE_SALARY_MANAGER,
  SET_COMPLETION_RATE_STATISTICS_LIST,
  WorkPeriod,
  SET_MANAGER_WORK_PERIOD_LIST,
  CompletionRateStatistics,
  InvoicesAndPaymentsStatistics,
  SET_INVOICES_AND_PAYMENTS_STATISTICS_LIST,
  UPDATE_WORK_PERIOD,
  SET_COMPLETION_RATE_CLIENT_LIST,
  CompletionRateClient,
  SET_INVOICES_AND_PAYMENTS_TOTALS,
  InvoicesAndPaymentsTotals,
  InvoicesAndPaymentsDetails,
  SET_INVOICES_AND_PAYMENTS_DETAILS,
  RESET_SALARY_DETAILS,
  SET_MANAGER_PROFITABILITY,
  ManagerProfitability,
  SET_MANAGER_PROFITABILITY_DETAILS,
  SET_TOTAL_MANAGER_PROFITABILITY,
  TotalManagerProfitability,
  SET_MANAGER_REFRESH_STATE,
  ManagerRefreshState
} from './types';

export interface SalaryState {
  salaryManagerList: SalaryManager[],
  currentSalaryManager: SalaryManager | null,
  workPeriodList: WorkPeriod[],
  completionRateClients: CompletionRateClient[],
  completionRateStatistics: CompletionRateStatistics[],
  invoicesAndPaymentsStatistics: InvoicesAndPaymentsStatistics[],
  invoicesAndPaymentsTotals: InvoicesAndPaymentsTotals | null,
  invoicesAndPaymentsDetails: InvoicesAndPaymentsDetails[],
  managerProfitabilityList: ManagerProfitability[],
  managerProfitabilityDetails: Profitability[],
  managerProfitabilityDetailsCount: number,
  totalManagerProfitability: TotalManagerProfitability | null,
  managerRefreshState: ManagerRefreshState | null
}

const initialState: SalaryState = {
  salaryManagerList: [],
  currentSalaryManager: null,
  workPeriodList: [],
  completionRateClients: [],
  completionRateStatistics: [],
  invoicesAndPaymentsStatistics: [],
  invoicesAndPaymentsTotals: null,
  invoicesAndPaymentsDetails: [],
  managerProfitabilityList: [],
  managerProfitabilityDetails: [],
  managerProfitabilityDetailsCount: 0,
  totalManagerProfitability: null,
  managerRefreshState: null
};

export default function (state = initialState, action: SalaryActions): SalaryState {
  switch (action.type) {
    case SET_SALARY_MANAGER_LIST:
      return { ...state, salaryManagerList: action.payload };
    case SET_SALARY_MANAGER:
      return { ...state, currentSalaryManager: action.payload };
    case SET_MANAGER_WORK_PERIOD_LIST:
      return { ...state, workPeriodList: action.payload };

    case SET_COMPLETION_RATE_CLIENT_LIST:
      return { ...state, completionRateClients: action.payload };

    case SET_INVOICES_AND_PAYMENTS_STATISTICS_LIST:
      return { ...state, invoicesAndPaymentsStatistics: action.payload };
    case SET_INVOICES_AND_PAYMENTS_TOTALS:
      return { ...state, invoicesAndPaymentsTotals: action.payload }
    case SET_INVOICES_AND_PAYMENTS_DETAILS:
      const toSet = action.payload.length ? [...state.invoicesAndPaymentsDetails.filter(i => i.accountId !== action.payload[0].accountId), ...action.payload] : [...state.invoicesAndPaymentsDetails];
      return { ...state, invoicesAndPaymentsDetails: toSet };
    case SET_COMPLETION_RATE_STATISTICS_LIST: {
      const toSet = action.payload.length ? [...state.completionRateStatistics.filter(i => i.accountId !== action.payload[0].accountId), ...action.payload] : [...state.completionRateStatistics];
      return { ...state, completionRateStatistics: toSet };
    }

    case UPDATE_SALARY_MANAGER:
      return { ...state, salaryManagerList: state.salaryManagerList.map(i => {
        if (i.managerId === action.payload.managerId) {
          return action.payload;
        }
        return i
      }) }

    case UPDATE_WORK_PERIOD:
      return { ...state, workPeriodList: state.workPeriodList.map(i => {
        if (i.periodStart === action.payload.periodStart && i.periodEnd === action.payload.periodEnd) {
          return action.payload;
        }
        return i
      }) }

    case SET_MANAGER_PROFITABILITY:
      return { ...state, managerProfitabilityList: action.payload }
    case SET_MANAGER_PROFITABILITY_DETAILS:
      return { ...state, managerProfitabilityDetails: action.payload.items, managerProfitabilityDetailsCount: action.payload.count }
    case SET_TOTAL_MANAGER_PROFITABILITY:
      return { ...state, totalManagerProfitability: action.payload }

    case SET_MANAGER_REFRESH_STATE:
      return { ...state, managerRefreshState: action.payload }

    case RESET_SALARY_STAT:
      return { ...state, workPeriodList: [], invoicesAndPaymentsStatistics: [], completionRateStatistics: [], managerProfitabilityList: [], totalManagerProfitability: null }
    case RESET_SALARY_MANAGER:
      return { ...state, currentSalaryManager: null }
    case RESET_SALARY_DETAILS:
      return { ...state, completionRateStatistics: [], invoicesAndPaymentsDetails: [], managerProfitabilityDetails: [] }
      
    default:
      return state;
  }
}
