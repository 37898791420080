import { CompanyBill } from './types';

export interface CompanyBillState {
  billsList: CompanyBill[],
  billsCount: number
}

const initialState: CompanyBillState = {
  billsList: [],
  billsCount: 0,
};

export default function (state = initialState, action: any): CompanyBillState {
  switch (action.type) {
    case 'SET_COMPANY_BILL_LIST':
      return { ...state, billsList: action.bills };
    case 'SET_COMPANY_BILL_COUNT':
      return { ...state, billsCount: action.count };
    default:
      return state;
  }
}
