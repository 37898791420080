import React, { useEffect } from 'react';
import { Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { loadDeliveryServices } from '../../../app/reference/action-creators';

function DeliveryServiceField({ value = undefined, onChange, single = false }) {
  const handleChange = (data) => {
    if (!single) onChange(data.selectedKeys);
  };

  const dispatch = useDispatch();

  const { deliveryServices } = useSelector((state) => state.reference);

  const handleClick = React.useCallback(
    ({ key }) => {
      if (single) {
        if (value === key) onChange();
        else onChange(key);
      }
    },
    [single, value],
  );

  useEffect(() => {
    if (!deliveryServices) {
      dispatch(loadDeliveryServices());
    }
  }, [dispatch]);

  return (
    <Menu
      selectedKeys={single ? [value] : value}
      onSelect={handleChange}
      onDeselect={handleChange}
      style={{ margin: '-16px', border: 'none' }}
      multiple={!single}
      onClick={handleClick}
      selectable
    >
      {deliveryServices ? Object.values(deliveryServices).map(({ key, title }) => (
        <Menu.Item key={key}>
          <span>{title}</span>
        </Menu.Item>
      )) : null}
    </Menu>
  );
}

export default DeliveryServiceField;
