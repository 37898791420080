import {
  ClientEvent,
  ClientEventsActions,
  SET_CLIENT_EVENT_COUNT,
  SET_CLIENT_EVENT_LIST,
} from './types';

export interface ClientEventState {
  clientEventList: ClientEvent[],
  clientEventCount: number,
}

const initialState: ClientEventState = {
  clientEventList: [],
  clientEventCount: 0,
};

export default function (state = initialState, action: ClientEventsActions): ClientEventState {
  switch (action.type) {
    case SET_CLIENT_EVENT_LIST:
      return { ...state, clientEventList: action.items };

    case SET_CLIENT_EVENT_COUNT:
      return { ...state, clientEventCount: action.count };
    default:
      return state;
  }
}
