import React from 'react';
import { Redirect, Route } from 'react-router';
import { useSelector } from 'react-redux';
import { MANAGER_TYPES, COMMON_EXCLUDED_FOR_MANAGER, DEVELOPMENT_EXCLUDED_FOR_MANAGER } from '../constants/ManagerConstants';

function PrivateRoute({ component: Component, ...rest }) {
  const authorized = useSelector((state) => !!state.users.user);
  const { user } = useSelector((state) => state.users);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (authorized) {
          if (
            user.salaryManagerType &&
            [
              ...COMMON_EXCLUDED_FOR_MANAGER, 
              ...(user.salaryManagerType === MANAGER_TYPES.DEVELOPMENT_MANAGER ? DEVELOPMENT_EXCLUDED_FOR_MANAGER : [])
            ].includes(rest.path)) {
            return <Redirect to="/404" />
          }
          return <Component {...props} />
        }
        return <Redirect to="/login" from={props.location} />
      }}
    />
  );
}

export default PrivateRoute;
