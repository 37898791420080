import React from 'react';
import { Menu } from 'antd';
import { SelectParam } from 'antd/es/menu';
import useSelector from '../../utils/hooks/useSelector';

interface AccountSellerFieldProps {
  value?: string[],
  onChange: (selectedKeys: string[]) => void,
}

function AccountSellerField({ value, onChange }: AccountSellerFieldProps) {
  const { sellers } = useSelector((state) => state.reference);

  const handleChange = React.useCallback(
    ({ selectedKeys }: SelectParam) => {
      onChange(selectedKeys);
    },
    [onChange],
  );

  return (
    <Menu
      selectedKeys={value}
      onSelect={handleChange}
      onDeselect={handleChange}
      multiple
      style={{ margin: '-16px', border: 'none' }}
    >
      {sellers.map((_) => (
        <Menu.Item key={_.sellerId}>
          <span>{_.title}</span>
        </Menu.Item>
      ))}
    </Menu>
  );
}

export default AccountSellerField;

export const AccountSeller = ({ sellerId }: { sellerId: string | number }) => {
  const { sellers } = useSelector((state) => state.reference);

  let seller;
  if (typeof sellerId === 'number') seller = sellers.find((_) => _.sellerId === sellerId);
  else seller = sellers.find((_) => _.sellerId === parseInt(sellerId, 10));

  return <span>{seller ? seller.title : sellerId}</span>;
};
