import {RcFile} from "antd/lib/upload/interface";

import { adaptFromApi, adaptToApi } from '../../utils/adapter';
import { ThunkAction } from '../../utils/types';
import { SalaryManager } from '../salary/types';
import {
  SetProfitabilityManagerList,
  SET_PROFITABILITY_MANAGER_LIST,
  SetProfitabilityManager,
  SET_PROFITABILITY_MANAGER,
  SetProfitabilityList,
  Profitability,
  SET_PROFITABILITY_LIST,
  RESET_PROFITABILITY,
  ResetProfitability,
  ResetProfitabilityManager,
  RESET_PROFITABILITY_MANAGER,
  SetProfitabilityCount,
  SET_PROFITABILITY_COUNT,
  SET_CURRENT_IMPORT_STATE,
  CurrentImportState,
  SetCurrentImportState,
  ResetCurrentImport,
  RESET_CURRENT_IMPORT,
  ProfitabilityImportParsedData,
  SET_PARSED_IMPORT_ITEMS,
  SetParsedImportItems,
  SetParsedImportCount,
  SET_PARSED_IMPORT_COUNT,
  SetCurrentImportID,
  SET_CURRENT_IMPORT_ID,
  SetUpdatedParsedWaybillData,
  SET_UPDATED_PARSED_WAYBILL_DATA,
  ProfitabilityTotals,
  SET_PROFITABILITY_TOTALS,
  SetProfitabilityTotals,
  SetProfitabilityReport,
  SET_PROFITABILITY_REPORT_URL,
} from './types';
import blobToBase64 from '../../util/blobToBase64';

export const setProfitabilityManagerList = (payload: Array<SalaryManager>): SetProfitabilityManagerList => ({
  type: SET_PROFITABILITY_MANAGER_LIST,
  payload,
});

export const setProfitabilityManager = (payload: SalaryManager): SetProfitabilityManager => ({
  type: SET_PROFITABILITY_MANAGER,
  payload,
});

export const setProfitabilityList = (payload: Array<Profitability>): SetProfitabilityList => ({
  type: SET_PROFITABILITY_LIST,
  payload,
});

export const setProfitabilityCount = (payload: number): SetProfitabilityCount => ({
  type: SET_PROFITABILITY_COUNT,
  payload,
});

export const setCurrentImportState = (payload: CurrentImportState): SetCurrentImportState => ({
  type: SET_CURRENT_IMPORT_STATE,
  payload,
});

export const setCurrentImportID = (payload: number): SetCurrentImportID => ({
  type: SET_CURRENT_IMPORT_ID,
  payload
});

export const setParsedImportItems = (payload: ProfitabilityImportParsedData[]): SetParsedImportItems => ({
  type: SET_PARSED_IMPORT_ITEMS,
  payload
});

export const setParsedImportCount = (payload: number): SetParsedImportCount => ({
  type: SET_PARSED_IMPORT_COUNT,
  payload
});

export const setUpdatedParsedWaybillData = (payload: ProfitabilityImportParsedData): SetUpdatedParsedWaybillData => ({
  type: SET_UPDATED_PARSED_WAYBILL_DATA,
  payload
});

export const setProfitabilityTotals = (payload: ProfitabilityTotals): SetProfitabilityTotals => ({
  type: SET_PROFITABILITY_TOTALS,
  payload
})

export const setProfitabilityReport = (payload: string): SetProfitabilityReport => ({
  type: SET_PROFITABILITY_REPORT_URL,
  payload
})

export const resetCurrentImport = (): ResetCurrentImport => ({
  type: RESET_CURRENT_IMPORT,
});;

export const resetProfitability = (): ResetProfitability => ({
  type: RESET_PROFITABILITY,
});

export const resetProfitabilityManager = (): ResetProfitabilityManager => ({
  type: RESET_PROFITABILITY_MANAGER
});

export const loadProfitabilityManagerList = (search = '', page = 1): ThunkAction => (
  dispatch, getState, http,
) => {
  const params: any = { query: search, page, manager_type: 'DEVELOPMENT_MANAGER' };
  return http.get('/api/v1/salary/list/', params).then(
    (response: any) => {
      const { items } = adaptFromApi(response);
      dispatch(setProfitabilityManagerList(items || []));
      return Promise.resolve(response);
    },
    (reject: any) => Promise.reject(reject),
  );
};

export const loadProfitabilityList = (
  // managerId: number,
  search = '',
  page = 1,
  createdAtFrom: string | null = null,
  createdAtTo: string | null = null,
  filters?: anyObject,
): ThunkAction => (
  dispatch, getState, http,
) => {
  const params: any = { limit: 25, skip: page * 25 - 25 };
  if (search) params.query = search;

  if (createdAtFrom) params.created_at_from = createdAtFrom;
  if (createdAtTo) params.created_at_to = createdAtTo;
  if (filters?.deliveryDateFrom) params.delivery_date_from = filters.deliveryDateFrom;
  if (filters?.deliveryDateTo) params.delivery_date_to = filters.deliveryDateTo;
  if (filters?.service) params.delivery_service = filters.service;
  if (filters?.sellerId) params.seller_id = filters.sellerId;
  if (filters?.tariff) params.rate_code = filters.tariff;

  if (filters?.serviceCostFrom) params.service_cost_from = filters.serviceCostFrom;
  else if (filters?.serviceCostTo !== -1) params.service_cost_from = 1;
  if (filters?.serviceCostTo) params.service_cost_to = filters.serviceCostTo;
  if (filters?.serviceWeightFrom) params.service_weight_from = filters.serviceWeightFrom;
  if (filters?.serviceWeightTo) params.service_weight_to = filters.serviceWeightTo;
  if (filters?.cityFrom) params.city_sender = filters.cityFrom;
  if (filters?.cityTo) params.city_receiver = filters.cityTo;
  if (filters?.accountId) params.account_id = filters.accountId;

  if (filters?.essentialCostFrom) params.essential_cost_from = filters.essentialCostFrom;
  if (filters?.essentialCostTo) params.essential_cost_to = filters.essentialCostTo;

  if (filters?.isLowProfitability) params.is_low_profitability = filters.isLowProfitability;

  return http.get(`/api/v1/salary/profitability/list/`, params).then(
    (response: any) => {
      const { items, count, totals } = adaptFromApi(response);
      dispatch(setProfitabilityList(items || []));
      dispatch(setProfitabilityCount(count));
      dispatch(setProfitabilityTotals(totals))
      return Promise.resolve(response);
    },
    (reject: any) => Promise.reject(reject),
  );
};

export const startImport = (file: RcFile | null, serviceWaybillNumberCol: number, serviceCostCol: number, serviceWeightCol: number, serviceRateCol: number): ThunkAction => async (dispatch, getState, http) => {
  const data: anyObject = {
    serviceWaybillNumberCol,
    serviceCostCol,
    serviceWeightCol,
    serviceRateCol
  };

  if (file) {
    data.file = await blobToBase64(file);
    console.log(`data.file ${data.file}`);
  }

  return http.post(
    '/api/v1/salary/profitability/import/start/', adaptToApi(data),
  ).then(
    (response: any) => {
      const parsed = adaptFromApi(response);
      dispatch(setCurrentImportState(CurrentImportState.RUNNING));
      dispatch(setCurrentImportID(parsed.profId))
      return Promise.resolve(response);
    },
  );
};

export const fetchParsedImportData = (profId: number, page: number): ThunkAction => async (dispatch, getState, http) => {

  return http.get(
    '/api/v1/salary/profitability/import/list/', adaptToApi({profId, page})
  ).then(
    (response: any) => {
      const parsed = adaptFromApi(response);
      dispatch(setParsedImportItems(parsed.items));
      dispatch(setParsedImportCount(parsed.count));
      return Promise.resolve(response);
    },
  );
};

export const updateParsedWaybillData = (profId: number, plsWaybillNumber: string, serviceCost: number, serviceWeight: number): ThunkAction => async (dispatch, getState, http) => {

  return http.patch(
    `/api/v1/salary/profitability/import/${plsWaybillNumber}/update/`, adaptToApi({profId, serviceCost, serviceWeight})
  ).then(
    (response: any) => {
      const parsed = adaptFromApi(response);
      dispatch(setUpdatedParsedWaybillData(parsed));
      return Promise.resolve(response);
    },
  );
};

export const approveParsedImportData = (profId: number, serviceWaybillNumbers: string[]): ThunkAction => async (dispatch, getState, http) => {

  return http.post(
    '/api/v1/salary/profitability/import/approve/', adaptToApi({profId, serviceWaybillNumbers})
  ).then(
    (response: any) => {
      if (response.done) {
        dispatch(setCurrentImportState(CurrentImportState.DONE));
      }
      return Promise.resolve(response);
    },
  );
};

export const generateProfitabilityReport = (
  search = '',
  page = 1,
  createdAtFrom: string | null = null,
  createdAtTo: string | null = null,
  filters?: anyObject,
): ThunkAction => async (dispatch, getState, http) => {

  const params: any = { limit: 25, skip: page * 25 - 25 };
  if (search) params.query = search;

  if (createdAtFrom) params.created_at_from = createdAtFrom;
  if (createdAtTo) params.created_at_to = createdAtTo;
  if (filters?.deliveryDateFrom) params.delivery_date_from = filters.deliveryDateFrom;
  if (filters?.deliveryDateTo) params.delivery_date_to = filters.deliveryDateTo;
  if (filters?.service) params.delivery_service = filters.service;
  if (filters?.sellerId) params.seller_id = filters.sellerId;
  if (filters?.tariff) params.rate_code = filters.tariff;

  if (filters?.serviceCostFrom) params.service_cost_from = filters.serviceCostFrom;
  else params.service_cost_from = 1;
  if (filters?.serviceCostTo) params.service_cost_to = filters.serviceCostTo;
  if (filters?.serviceWeightFrom) params.service_weight_from = filters.serviceWeightFrom;
  if (filters?.serviceWeightTo) params.service_weight_to = filters.serviceWeightTo;
  if (filters?.cityFrom) params.city_sender = filters.cityFrom;
  if (filters?.cityTo) params.city_receiver = filters.cityTo;
  if (filters?.accountId) params.account_id = filters.accountId;

  return http.post(`/api/v1/salary/profitability/report/`, params).then(
    (response: any) => {
      const { file, status } = adaptFromApi(response);
      if (status === 2 && file) {
        dispatch(setProfitabilityReport(file))
      }
      return Promise.resolve(response);
    },
    (reject: any) => Promise.reject(reject),
  );
};

export const updateOrdersByNumberOrService = (increasePercent: number, plsWaybillNumbers: string[], deliveryService: string): ThunkAction => async (dispatch, getState, http) => {
  const params: any = {
    increasePercent
  }
  if (plsWaybillNumbers.length) {
    params.plsWaybillNumbers = plsWaybillNumbers
  } else if (deliveryService) {
    params.deliveryService = deliveryService
  }
  return http.post(
    '/api/v1/salary/profitability/update/', adaptToApi(params)
  ).then(
    (response: any) => {
      if (response.done) {
        dispatch(loadProfitabilityList())
      }
      return Promise.resolve(response);
    },
  );
};

export const updateProfitabilitySingle = (data: any): ThunkAction => (dispatch, getState, http) => {
  const { plsNumber, nextServiceCost } = data;
  return http.patch(
    `/api/v1/salary/profitability/single/${plsNumber}/update/`, adaptToApi({ nextServiceCost }),
  ).then(
    (response: any) => {
      return Promise.resolve(response);
    },
    (reject: any) => Promise.reject(reject),
  );
}

export const addSingleProfitability = (data: any): ThunkAction => async (dispatch, getState, http) => {
  return http.post(
    '/api/v1/salary/profitability/single/add/', adaptToApi(data)
  ).then(
    (response: any) => {
      return Promise.resolve(response);
    },
    (reject: any) => Promise.reject(reject),
  );
};