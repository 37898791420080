import React from 'react';
import { Menu } from 'antd';
import AccountStatus from './AccountStatus';

function AccountStatusField({ value = undefined, onChange }) {
  const handleStatusChange = (data) => {
    onChange(data.selectedKeys);
  };

  return (
    <Menu
      selectedKeys={value}
      onSelect={handleStatusChange}
      onDeselect={handleStatusChange}
      multiple
      style={{ margin: '-16px', border: 'none' }}
    >
      <Menu.Item key={1}>
        <AccountStatus code="1" />
      </Menu.Item>
      <Menu.Item key={2}>
        <AccountStatus code="2" />
      </Menu.Item>
      <Menu.Item key={3}>
        <AccountStatus code="3" />
      </Menu.Item>
      <Menu.Item key={4}>
        <AccountStatus code="4" />
      </Menu.Item>
      <Menu.Item key={5}>
        <AccountStatus code={5} />
      </Menu.Item>
    </Menu>
  );
}

export default AccountStatusField;
