import {
  SET_FAILED_BILLING_LIST,
  RESET_FAILED_BILLING,
  FailedBilling,
  FailedBillingActions
} from './types';

export interface FailedBillingState {
  failedBillingList: FailedBilling[],
  failedBillingCount: number
}

const initialState: FailedBillingState = {
  failedBillingList: [],
  failedBillingCount: 0
};

export default function (state = initialState, action: FailedBillingActions): FailedBillingState {
  switch (action.type) {
    case SET_FAILED_BILLING_LIST:
      return { ...state, failedBillingList: action.payload.items, failedBillingCount: action.payload.count };
    case RESET_FAILED_BILLING:
      return { ...initialState }
      
    default:
      return state;
  }
}
