import { Moment } from 'moment';
import {
  ADD_CURRENT_WAYBILL_TRACKING_LIST,
  RESET_CURRENT_WAYBILL, RESET_CURRENT_WAYBILL_TRACKING_LIST, SET_CURRENT_ORDER_SERVICE_LIST,
  SET_CURRENT_WAYBILL, SET_CURRENT_WAYBILL_TRACKING_COUNT, SET_CURRENT_WAYBILL_TRACKING_LIST,
  SET_CURRENT_WAYBILL_PRICING_LIST,
  Waybill,
  waybillActions,
  WaybillDetail,
  WaybillTracking,
  WaybillPricing,
} from './types';

export interface ShippingState {
  waybillsList: Waybill[],
  waybillsCount: number,
  waybillsSearch: string,
  waybillsStatus: number | null,
  waybillsPage: number,
  waybillsCreatedAtFrom: Moment | null,
  waybillsCreatedAtTo: Moment | null,
  waybillsPickupDateFrom: Moment | null,
  waybillsPickupDateTo: Moment | null,
  waybillsBillingDateFrom: Moment | null,
  waybillsBillingDateTo: Moment | null,
  waybillsAccount: number | null,
  waybillsDeliveryService: string | null,
  currentWaybill: WaybillDetail | null,
  currentWaybillTrackingList: Array<WaybillTracking>,
  currentWaybillTrackingCount: number,
  currentWaybillPricingList: Array<WaybillPricing>,
  currentOrderServiceList: Array<any>,
}

const initialState: ShippingState = {
  waybillsList: [],
  waybillsCount: 0,
  waybillsSearch: '',
  waybillsStatus: null,
  waybillsPage: 1,
  waybillsCreatedAtFrom: null,
  waybillsCreatedAtTo: null,
  waybillsPickupDateFrom: null,
  waybillsPickupDateTo: null,
  waybillsBillingDateFrom: null,
  waybillsBillingDateTo: null,
  waybillsAccount: null,
  waybillsDeliveryService: null,
  currentWaybill: null,
  currentWaybillTrackingList: [],
  currentWaybillTrackingCount: 0,
  currentWaybillPricingList: [],
  currentOrderServiceList: [],
};

export default function (state = initialState, action: waybillActions | any): ShippingState {
  switch (action.type) {
    case 'SET_WAYBILLS_LIST':
      return { ...state, waybillsList: action.waybills };
    case 'SET_WAYBILLS_COUNT':
      return { ...state, waybillsCount: action.count };
    case 'SET_WAYBILLS_SEARCH':
      return { ...state, waybillsSearch: action.search };
    case 'SET_WAYBILLS_STATUS':
      return { ...state, waybillsStatus: action.status };
    case 'SET_WAYBILLS_PAGE':
      return { ...state, waybillsPage: action.page };
    case 'SET_WAYBILLS_CREATED_AT_FROM':
      return { ...state, waybillsCreatedAtFrom: action.date };
    case 'SET_WAYBILLS_CREATED_AT_TO':
      return { ...state, waybillsCreatedAtTo: action.date };
    case 'SET_WAYBILLS_PICKUP_DATE_FROM':
      return { ...state, waybillsPickupDateFrom: action.date };
    case 'SET_WAYBILLS_PICKUP_DATE_TO':
      return { ...state, waybillsPickupDateTo: action.date };
    case 'SET_WAYBILLS_BILLING_DATE_FROM':
      return { ...state, waybillsBillingDateFrom: action.date };
    case 'SET_WAYBILLS_BILLING_DATE_TO':
      return { ...state, waybillsBillingDateTo: action.date };
    case 'SET_WAYBILLS_ACCOUNT':
      return { ...state, waybillsAccount: action.account };
    case 'SET_WAYBILLS_DELIVERY_SERVICE':
      return { ...state, waybillsDeliveryService: action.deliveryService };
    case 'RESET_WAYBILL_LIST':
      return { ...initialState, currentWaybill: state.currentWaybill };
    case SET_CURRENT_WAYBILL:
      return { ...state, currentWaybill: action.waybill };
    case RESET_CURRENT_WAYBILL:
      return { ...state, currentWaybill: initialState.currentWaybill };
    case SET_CURRENT_WAYBILL_TRACKING_LIST:
      return { ...state, currentWaybillTrackingList: action.tracking };
    case SET_CURRENT_WAYBILL_PRICING_LIST:
      return { ...state, currentWaybillPricingList: action.pricing };
    case ADD_CURRENT_WAYBILL_TRACKING_LIST:
      return { ...state, currentWaybillTrackingList: [...state.currentWaybillTrackingList, ...action.tracking] };
    case RESET_CURRENT_WAYBILL_TRACKING_LIST:
      return { ...state, currentWaybillTrackingList: initialState.currentWaybillTrackingList };
    case SET_CURRENT_WAYBILL_TRACKING_COUNT:
      return { ...state, currentWaybillTrackingCount: action.count };
    case SET_CURRENT_ORDER_SERVICE_LIST:
      return { ...state, currentOrderServiceList: action.serviceList };
    default:
      return state;
  }
}
