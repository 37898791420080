import React from 'react';
import { Button, Icon } from 'antd';

function ListModeSwitch({ onListClick, onChartClick }) {
  return (
    <div className="ant-input-wrapper ant-input-group ant-input-group-compact" style={{ width: '91px' }}>
      <Button className="gx-simple-pagination-button" disabled={!onListClick} onClick={onListClick}>
        <Icon type="unordered-list" />
      </Button>
      <Button className="gx-simple-pagination-button" disabled={!onChartClick} onClick={onChartClick}>
        <Icon type="line-chart" />
      </Button>
    </div>

  );
}

export default ListModeSwitch;
