import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const DowngradedOrdersList = React.lazy(() => import('../components/DowngradedOrdersList'));

function DowngradedOrdersListView(props: Object) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <DowngradedOrdersList {...props} />
    </Suspense>
  );
}

export default DowngradedOrdersListView;
