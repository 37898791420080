import React from 'react';
import { Select } from 'antd';
import useSelector from '../../../utils/hooks/useSelector';

interface Props {
    title: string,
    availableColumns: {key: string, value: number}[],
    selected: number | undefined,
    onChange: (values: number) => void
}

function ProfitabilityImportSelect({ title, availableColumns, selected, onChange }: Props) {
    const { sellers } = useSelector((state) => state.reference);

    return (
        <div className="gx-mb-2">
            <span>{title}: </span>
            <Select
                value={selected}
                onChange={onChange}
                placeholder='Название колонки'
                style={{ minWidth: 230 }}
                showSearch={false}
            >
                {availableColumns.map((_) => (<Select.Option value={_.value}>{_.key}</Select.Option>))}
            </Select>
        </div>
    );
}

ProfitabilityImportSelect.defaultProps = {
    selected: undefined
}

export default ProfitabilityImportSelect;
