import { RcFile } from 'antd/lib/upload';
import {
  AccountTenderImportStatus,
  ADD_OR_UPDATE_TENDER_CALCULATION_WEIGHT_RESULT,
  ADD_TENDER_CALCULATION_DESTINATION,
  ADD_TENDER_CALCULATION_WEIGHT,
  AddOrUpdateTenderCalculationWeightResult,
  AddTenderCalculationDestination,
  AddTenderCalculationWeight,
  REMOVE_TENDER_CALCULATION_DESTINATION,
  REMOVE_TENDER_CALCULATION_WEIGHT,
  RemoveTenderCalculationDestination,
  RemoveTenderCalculationWeight,
  RESET_ACCOUNT_TENDER_IMPORT,
  RESET_TENDER_CALCULATION_DETAIL,
  RESET_TENDER_CALCULATION_IMPORT_STATUS,
  ResetAccountTenderImport,
  ResetTenderCalculationDetail,
  ResetTenderCalculationImportStatus,
  SET_ACCOUNT_TENDER_IMPORT_ID,
  SET_ACCOUNT_TENDER_IMPORT_STATUS,
  SET_CALCULATION_RUNNING,
  SET_TENDER_CALCULATION_COUNT,
  SET_TENDER_CALCULATION_DETAIL,
  SET_TENDER_CALCULATION_IMPORT_STATUS,
  SET_TENDER_CALCULATION_LIST,
  SetAccountTenderImportId,
  SetAccountTenderImportStatus,
  SetCalculationRunning,
  SetTenderCalculationCount,
  SetTenderCalculationDetail,
  SetTenderCalculationImportStatus,
  SetTenderCalculationList,
  TenderCalculationDestination,
  TenderCalculationDetail,
  TenderCalculationImportStatus,
  TenderCalculationListItem,
  TenderCalculationWeight,
  TenderDestinationWeightResult,
} from './types';
import { ThunkAction } from '../../utils/types';

export const setTenderCalculationList = (list: TenderCalculationListItem[]): SetTenderCalculationList => ({
  type: SET_TENDER_CALCULATION_LIST,
  list,
});

export const setTenderCalculationCount = (count: number): SetTenderCalculationCount => ({
  type: SET_TENDER_CALCULATION_COUNT,
  count,
});

export const setTenderCalculationDetail = (detail: TenderCalculationDetail): SetTenderCalculationDetail => ({
  type: SET_TENDER_CALCULATION_DETAIL,
  detail,
});

export const resetTenderCalculationDetail = (): ResetTenderCalculationDetail => ({
  type: RESET_TENDER_CALCULATION_DETAIL,
});

export const _addTenderCalculationWeight = (weight: TenderCalculationWeight): AddTenderCalculationWeight => ({
  type: ADD_TENDER_CALCULATION_WEIGHT,
  weight,
});

export const _removeTenderCalculationWeight = (weightId: number): RemoveTenderCalculationWeight => ({
  type: REMOVE_TENDER_CALCULATION_WEIGHT,
  weightId,
});

export const _addTenderCalculationDestination = (destination: TenderCalculationDestination): AddTenderCalculationDestination => ({
  type: ADD_TENDER_CALCULATION_DESTINATION,
  destination,
});

export const _removeTenderCalculationDestination = (destinationId: number): RemoveTenderCalculationDestination => ({
  type: REMOVE_TENDER_CALCULATION_DESTINATION,
  destinationId,
});

export const addOrUpdateTenderCalculationWeightResult = (result: TenderDestinationWeightResult): AddOrUpdateTenderCalculationWeightResult => ({
  type: ADD_OR_UPDATE_TENDER_CALCULATION_WEIGHT_RESULT,
  result,
});

export const setCalculationRunning = (running: boolean): SetCalculationRunning => ({
  type: SET_CALCULATION_RUNNING,
  running,
});

export const setTenderCalculationImportStatus = (status: TenderCalculationImportStatus): SetTenderCalculationImportStatus => ({
  type: SET_TENDER_CALCULATION_IMPORT_STATUS,
  status,
});

export const resetTenderCalculationImportStatus = (): ResetTenderCalculationImportStatus => ({
  type: RESET_TENDER_CALCULATION_IMPORT_STATUS,
});

export const setAccountTenderImportId = (importId: string): SetAccountTenderImportId => ({
  type: SET_ACCOUNT_TENDER_IMPORT_ID,
  importId,
});

export const setAccountTenderImportStatus = (status: AccountTenderImportStatus): SetAccountTenderImportStatus => ({
  type: SET_ACCOUNT_TENDER_IMPORT_STATUS,
  status,
});

export const resetAccountTenderImport = (): ResetAccountTenderImport => ({
  type: RESET_ACCOUNT_TENDER_IMPORT,
});

export const createTenderCalculation = (): ThunkAction<Promise<{ tenderCalculationId: number }>> => (dispatch, getState, http) => http.post(
  '/api/v1/rates/tender/calculation/create/',
);

export const getTenderCalculationList = (page = 1): ThunkAction => (dispatch, getState, http) => http.get(
  '/api/v1/rates/tender/calculation/list/',
  { page },
).then(
  ({ items, count }: { items: TenderCalculationListItem[], count: number }) => {
    dispatch(setTenderCalculationList(items));
    dispatch(setTenderCalculationCount(count));
    return Promise.resolve();
  },
);

export const getTenderCalculationDetail = (tenderCalculationId: number | string): ThunkAction<Promise<TenderCalculationDetail>> => (dispatch, getState, http) => http.get(
  `/api/v1/rates/tender/calculation/${tenderCalculationId}/detail/`,
).then(
  (response: TenderCalculationDetail) => {
    dispatch(setTenderCalculationDetail(response));
    return Promise.resolve(response);
  },
);

export const addTenderCalculationWeight = (data: anyObject): ThunkAction => (dispatch, getState, http) => http.post(
  '/api/v1/rates/tender/calculation/weight/add/',
  data,
).then(
  (response: TenderCalculationWeight) => {
    dispatch(_addTenderCalculationWeight(response));
    return Promise.resolve();
  },
);

export const removeTenderCalculationWeight = (weightId: number): ThunkAction => (dispatch, getState, http) => http.delete(
  `/api/v1/rates/tender/calculation/weight/${weightId}/remove/`,
).then(
  () => {
    dispatch(_removeTenderCalculationWeight(weightId));
    return Promise.resolve();
  },
);

export const addTenderCalculationDestination = (data: anyObject): ThunkAction => (dispatch, getState, http) => http.post(
  '/api/v1/rates/tender/calculation/destination/add/',
  data,
).then(
  (response: TenderCalculationDestination) => {
    dispatch(_addTenderCalculationDestination(response));
    return Promise.resolve(response);
  },
);

export const removeTenderCalculationDestination = (destinationId: number): ThunkAction => (dispatch, getState, http) => http.delete(
  `/api/v1/rates/tender/calculation/destination/${destinationId}/remove/`,
).then(
  () => {
    dispatch(_removeTenderCalculationDestination(destinationId));
    return Promise.resolve();
  },
);

export const recalcTenderCalculation = (tenderCalculationId: number | string): ThunkAction => (dispatch, getState, http) => http.post(
  '/api/v1/rates/tender/calculation/recalc/',
  { tender_calculation_id: tenderCalculationId },
).then(
  () => {
    dispatch(setCalculationRunning(true));
  },
);

export const handleTenderCalculationWS = ({
  tenderCalculationId,
  status,
  payload,
}: anyObject): ThunkAction<void> => (dispatch, getState, http) => {
  const { tenderCalculationDetail } = getState().tender;

  if (tenderCalculationDetail?.tenderCalculationId === tenderCalculationId) {
    if (status === 'done') {
      dispatch(setCalculationRunning(false));
    } else if (status === 'progress') {
      dispatch(addOrUpdateTenderCalculationWeightResult(payload));
    }
  }
};

export const downloadTenderCalculation = (tenderCalculationId: string | number): ThunkAction<Promise<{ downloadUrl: string }>> => (dispatch, getState, http) => http.get(
  `/api/v1/rates/tender/calculation/${tenderCalculationId}/download/`,
);

export const importTenderCalculationData = (tenderCalculationId: string | number, file: RcFile | null): ThunkAction => async (dispatch, getState, http) => {
  const data = new FormData();

  if (file) {
    data.append('file', file);
  }

  return http.put(`/api/v1/rates/tender/calculation/${tenderCalculationId}/import/`, data, { json: false }).then(
    () => {
      dispatch(setTenderCalculationImportStatus(TenderCalculationImportStatus.RUNNING));
    },
  );
};

export const importAccountTender = (accountId: string | number, file: RcFile | null): ThunkAction => (dispatch, getState, http) => {
  const data = new FormData();
  if (file) data.append('file', file);
  return http.put(`/api/v1/rates/tender/${accountId}/import/`, data, { json: false }).then(
    ({ importId }: {importId: string}) => {
      dispatch(setAccountTenderImportId(importId));
      return Promise.resolve();
    },
  );
};

enum TenderWSType {
  IMPORT = 'import',
  ACCOUNT_IMPORT = 'account_import'
}

export const acceptTenderWS = (type: TenderWSType, payload: anyObject): ThunkAction<void> => (dispatch, getState) => {
  const { tenderCalculationDetail, accountTenderImportId } = getState().tender;

  console.debug('acceptTenderWS', type, payload, accountTenderImportId);

  switch (type) {
    case TenderWSType.IMPORT:
      if (tenderCalculationDetail?.tenderCalculationId === payload.tenderCalculationId && payload.command === 'done') {
        dispatch(setTenderCalculationImportStatus(TenderCalculationImportStatus.DONE));
      }
      break;
    case TenderWSType.ACCOUNT_IMPORT:
      if (accountTenderImportId === payload.importId) {
        dispatch(setAccountTenderImportStatus(payload as AccountTenderImportStatus));
      }
      break;
    default: break;
  }
};
