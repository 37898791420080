import React from 'react';
import CircularProgress from '../../../../components/CircularProgress';

const NotificationTemplates = React.lazy(() => import('../components/NotificationTemplates'));

function NotificationTemplatesView() {
  return (
    <React.Suspense fallback={<CircularProgress />}>
      <NotificationTemplates />
    </React.Suspense>
  );
}

export default NotificationTemplatesView;
