import axios from 'axios';
import createHttpService from './createHttpService';

const authToken = localStorage.getItem('accessToken');
const defaultHeaders = authToken ? {
  Authorization: `Bearer ${authToken}`,
} : {};
const axiosInstance = axios.create({
  headers: {
    common: {
      ...defaultHeaders,
    },
  },
});
axiosInstance.interceptors.response.use((response) => response, (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx

    try {
      console.error(JSON.stringify(error.response.data));
      console.error(JSON.stringify(error.response.status));
      console.error(JSON.stringify(error.response.headers));
    } catch (e) {
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.error(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error('Error during response', error.message);
  }
  console.error(JSON.stringify(error.config));
  return Promise.reject(error);
});
const httpService = createHttpService(axiosInstance);
export default httpService;
export { createHttpService };
