import React from 'react';
import { Moment } from 'moment';
import useDispatch from '../../../utils/hooks/useDispatch';
import useSelector from '../../../utils/hooks/useSelector';
import useCancelCallback from '../../../utils/hooks/useCancelCallback';
import { loadTotalWaybillStat } from '../action-creators';
import { SERVER_DATE_FORMAT } from '../../../utils/constants';
import { Col, Row } from 'antd';
import { Area, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

type WaybillTotalStatTabContentProps = {
  dateFrom: Moment,
  dateTo: Moment,
}

function WaybillTotalStatTabContent({ dateFrom, dateTo }: WaybillTotalStatTabContentProps) {
  const dispatch = useDispatch();
  const { totalWaybillStat } = useSelector((state) => state.statistics);

  const handleLoad = useCancelCallback((from, to, cancelToken) => dispatch(loadTotalWaybillStat(from?.format(SERVER_DATE_FORMAT), to?.format(SERVER_DATE_FORMAT), cancelToken)), []);

  const totalAmount = React.useMemo(() => {
    return totalWaybillStat.reduce((cur, item) => cur + item.count, 0);
  }, [totalWaybillStat]);

  React.useEffect(() => {
    handleLoad(dateFrom, dateTo);
  }, [dateFrom, dateTo]);


  return (
    <div className="gx-site-dash gx-pr-xl-5 gx-pt-3 gx-pt-xl-0 gx-pt-xl-2">
      <Row>
        <Col lg={12} md={12} sm={24} xs={24}>
          <p style={{ color: '#038FDE' }}>Общее кол-во заказов: {totalAmount}</p>
        </Col>
      </Row>
      <ResponsiveContainer width="100%" height={240}>
      <ComposedChart
        data={totalWaybillStat}
        margin={{
          top: 10, right: 0, left: -20, bottom: 0,
        }}
      >
        <XAxis dataKey="date" />
        <YAxis yAxisId="left" orientation="left" dataKey="count" domain={[0, 'dataMax']} />
        <Tooltip
          contentStyle={{ color: 'black' }}
          content={({ payload, formatter, label }: {payload: Array<Object>, formatter: Function, label: string}) => {
            if (!payload || !payload.length) {
              return null;
            }
            return (
              <div
                style={{
                  backgroundColor: '#fff', border: '1px solid #c1c1c1', borderRadius: '5px', padding: '10px',
                }}
                className="custom-tooltip"
              >
                <p>{label}</p>
                {payload.map((item: any) => {
                  const itemData = formatter(item.value, item.name);
                  return <p style={{ color: itemData[2] }} className="label">{`${itemData[1]}: ${itemData[0]}`}</p>;
                })}
              </div>
            );
          }}
          formatter={(value, name, props) => {
            if (name === 'count') {
              return [value, 'Кол-во', '#038FDE'];
            }
            return null;
          }}
        />
        <CartesianGrid horizontal={false} strokeDasharray="3 3" />
        <Area type="monotone" dataKey="count" fillOpacity={1} stroke="#038FDE" fill="#038FDE" yAxisId="left" />
      </ComposedChart>
    </ResponsiveContainer>
    </div>
  );
}

export default WaybillTotalStatTabContent;
