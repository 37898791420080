import React, { useCallback, useMemo } from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import useSelector from '../../utils/hooks/useSelector';
import { COMMON_EXCLUDED_FOR_MANAGER, DEVELOPMENT_EXCLUDED_FOR_MANAGER, MANAGER_TYPES } from '../../constants/ManagerConstants';

const { SubMenu } = Menu;

function VerticalNav({ onClick }) {
  const history = useHistory();
  const { user } = useSelector((state) => state.users);
  const menu = [
    {
      title: 'Клиенты',
      to: '/accounts',
    },
    {
      title: 'Заказы',
      to: '/shipping',
    },
    {
      title: 'Закрывающие документы',
      to: '/acts',
    },
    {
      title: 'Задолженность',
      to: '/debt',
    },
    {
      title: 'Зарплаты',
      to: '/salary',
    },
    {
      title: 'Рентабельность',
      to: '/profitability',
    },
  ];

  const renderMenu = useCallback(
    (item) => {
      if (item.submenu) {
        return (
          <SubMenu
            title={item.title}
            className="ant-menu-submenu ant-menu-submenu-vertical gx-menu-vertical"
            onTitleClick={() => history.push(item.submenu[0].to)}
          >
            {item.submenu.map((subitem) => renderMenu(subitem))}

          </SubMenu>
        );
      }
      return (
        <Menu.Item className="ant-menu-submenu ant-menu-submenu-vertical gx-menu-vertical">
          <Link className="ant-menu-submenu-title" to={item.to} onClick={onClick}>
            <span>{item.title}</span>
          </Link>
        </Menu.Item>
      );
    }, [menu],
  );

  const filteredMenu = useMemo(() => {
    return menu.filter(item => {
      if (user && user.salaryManagerType) {
        return ![
          ...COMMON_EXCLUDED_FOR_MANAGER, 
          ...(user.salaryManagerType === MANAGER_TYPES.DEVELOPMENT_MANAGER ? DEVELOPMENT_EXCLUDED_FOR_MANAGER : [])
        ].includes(item.title);
      }
      return true
    })
  }, [user])

  return (
    <Menu
      className="ant-menu-dark ant-menu-light ant-menu-root ant-menu-vertical"
      mode="vertical"
    >
      {filteredMenu.map((item) => renderMenu(item))}
    </Menu>

  );
}

export default VerticalNav;
