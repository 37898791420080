import React, { Suspense } from 'react';
import CircularProgress from '../../../../components/CircularProgress';

const DetailingList = React.lazy(() => import('../components/DetailingList'));
function DetailingListView(props: any) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <DetailingList {...props} />
    </Suspense>
  );
}

export default DetailingListView;
