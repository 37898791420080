import { Support } from './types';

export interface SupportState {
  supportsList: Support[],
  supportsCount: number
}

const initialState: SupportState = {
  supportsList: [],
  supportsCount: 0,
};

export default function (state = initialState, action: any): SupportState {
  switch (action.type) {
    case 'SET_SUPPORT_LIST':
      return { ...state, supportsList: action.supports };
    case 'SET_SUPPORT_COUNT':
      return { ...state, supportsCount: action.count };
    default:
      return state;
  }
}
