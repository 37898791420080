import React, { CSSProperties, ChangeEvent } from 'react';
import { Input } from 'antd';

interface CityFieldProps {
  value: [string, string],
  onChange: (val: [string, string]) => void,
  fromPlaceholder?: string,
  fromLabel?: string,
  toPlaceholder?: string,
  toLabel?: string,
  style?: CSSProperties,
}

function CityField({
  value = ['', ''],
  onChange,
  fromPlaceholder = '',
  fromLabel = '',
  toPlaceholder = '',
  toLabel = '',
  style = {},
}: CityFieldProps) {

  const handleFromChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange([e.target.value, value.length ? value[1] : '']);
  };

  const handleToChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange([value.length ? value[0] : '', e.target.value]);
  };

  return (
    <div style={style}>
      <div>
        {!!fromLabel && (
          <span>{fromLabel}</span>
        )}
        <Input value={value[0]} placeholder={fromPlaceholder} onChange={handleFromChange} />
      </div>
      <div>
        {!!toLabel && (
          <span>{toLabel}</span>
        )}
        <Input value={value[1]} placeholder={toPlaceholder} onChange={handleToChange} />
      </div>
    </div>
  );
}

export default CityField;