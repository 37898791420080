import moment, { Moment } from 'moment';
import {
  Act,
  ActActions,
  ActStatisticSeries,
  SET_ACT_STATISTIC,
  SET_ACTS_ACCOUNT,
  SET_ACTS_COUNT,
  SET_ACTS_DATE_FROM,
  SET_ACTS_DATE_TO,
  SET_ACTS_LIST,
  SET_ACTS_PAGE,
  SET_ACTS_SEARCH,
  SET_CURRENT_ACT,
  SET_CURRENT_ACT_ORDERS_COUNT,
  SET_CURRENT_ACT_ORDERS_LIST,
} from './types';
import { USER_DATE_FORMAT } from '../../../utils/constants';

export interface ActState {
  actsList: Act[],
  actsCount: number,
  currentAct: Act | null,
  currentActOrderList: Array<any>,
  currentActOrderCount: number | null,
  actsPage: number,
  actsSearch: string,
  actsDateFrom: Moment | null,
  actsDateTo: Moment | null,
  actsAccount: number | null,
  actStatistic: ActStatisticSeries[],
}

const initialState: ActState = {
  actsList: [],
  actsCount: 0,
  currentAct: null,
  currentActOrderList: [],
  currentActOrderCount: 0,
  actsPage: 1,
  actsSearch: '',
  actsDateFrom: null,
  actsDateTo: null,
  actsAccount: null,
  actStatistic: [],
};

export default function (state = initialState, action: ActActions): ActState {
  switch (action.type) {
    case SET_ACTS_LIST:
      return { ...state, actsList: action.acts };
    case SET_ACTS_COUNT:
      return { ...state, actsCount: action.count };
    case SET_CURRENT_ACT_ORDERS_LIST:
      return { ...state, currentActOrderList: action.orders };
    case SET_CURRENT_ACT_ORDERS_COUNT:
      return { ...state, currentActOrderCount: action.count };
    case SET_CURRENT_ACT:
      return { ...state, currentAct: action.act };
    case SET_ACTS_PAGE:
      return { ...state, actsPage: action.page };
    case SET_ACTS_SEARCH:
      return { ...state, actsSearch: action.search };
    case SET_ACTS_DATE_FROM:
      return { ...state, actsDateFrom: action.date };
    case SET_ACTS_DATE_TO:
      return { ...state, actsDateTo: action.date };
    case SET_ACTS_ACCOUNT:
      return { ...state, actsAccount: action.accountId };
    case SET_ACT_STATISTIC:
      return { ...state, actStatistic: action.statistic.map((_) => ({ ..._, date: moment(_.date).format('MMM YYYY') })) };
    default:
      return state;
  }
}
