import { SalaryManager } from "../salary/types";

export const SET_PROFITABILITY_MANAGER_LIST = 'SET_PROFITABILITY_MANAGER_LIST';
export const SET_PROFITABILITY_LIST = 'SET_PROFITABILITY_LIST';
export const SET_PROFITABILITY_COUNT = 'SET_PROFITABILITY_COUNT';
export const SET_PROFITABILITY_MANAGER = 'SET_PROFITABILITY_MANAGER';
export const RESET_PROFITABILITY = 'RESET_PROFITABILITY';
export const RESET_PROFITABILITY_MANAGER = 'RESET_PROFITABILITY_MANAGER'

export const SET_CURRENT_IMPORT_STATE = 'SET_CURRENT_IMPORT_STATE';
export const SET_PARSED_IMPORT_ITEMS = 'SET_PARSED_IMPORT_ITEMS';
export const SET_PARSED_IMPORT_COUNT = 'SET_PARSED_IMPORT_COUNT';
export const SET_CURRENT_IMPORT_ID = 'SET_CURRENT_IMPORT_ID';
export const SET_UPDATED_PARSED_WAYBILL_DATA = 'SET_UPDATED_PARSED_WAYBILL_DATA';
export const SET_PROFITABILITY_TOTALS = 'SET_PROFITABILITY_TOTALS';
export const SET_PROFITABILITY_REPORT_URL = 'SET_PROFITABILITY_REPORT_URL';

export const RESET_CURRENT_IMPORT = 'RESET_CURRENT_IMPORT';

export interface OrderServiceItem {
  amount: number,
  consolidationDate: string
}

export interface Profitability {
  id: number,
  plsNumber: string,
  serviceNumber: string,
  serviceOrderNumber: string,
  orderNumber: string,
  deliveryService: string,
  rate: string,
  minDeliveryDays: number,
  maxDeliveryDays: number,
  createdAt: string,
  state: string,
  stateData: string,
  senderCity: string,
  receiverCity: string,
  accountId: number,
  accountTitle: string,
  accountInn: string,
  tagInsurance: boolean,
  tagOnlineShop: boolean,
  tagToTerminal: boolean,
  totalCost: number,
  rateCost: number,
  invoicesCost: number,
  invoiceDescription: OrderServiceItem[],
  serviceCost: number,
  serviceWeight: number
}

export interface ProfitabilityTotals {
  totalServiceCost: number,
  totalServiceWeight: number,
  totalBilling: number
}

export type ProfitabilityImportParsedData = {
  plsWaybillNumber: string;
  serviceWaybillNumber: string;
  serviceCost: number;
  serviceWeight: number;
  citySender: string;
  cityReceiver: string;
  serviceRate: string;
  prevServiceCost?: number;
  prevServiceWeight?: number;
  prevServiceRate?: string;
}

export enum CurrentImportState {
  NEW,
  RUNNING,
  DONE
}

export interface SetProfitabilityReport {
  type: typeof SET_PROFITABILITY_REPORT_URL,
  payload: string
}

export interface SetProfitabilityTotals {
  type: typeof SET_PROFITABILITY_TOTALS,
  payload: ProfitabilityTotals
}

export interface SetProfitabilityManagerList {
  type: typeof SET_PROFITABILITY_MANAGER_LIST,
  payload: SalaryManager[],
}

export interface SetProfitabilityList {
  type: typeof SET_PROFITABILITY_LIST,
  payload: Profitability[],
}

export interface SetProfitabilityCount {
  type: typeof SET_PROFITABILITY_COUNT,
  payload: number,
}

export interface SetProfitabilityManager {
  type: typeof SET_PROFITABILITY_MANAGER,
  payload: SalaryManager,
}

export interface SetCurrentImportState {
  type: typeof SET_CURRENT_IMPORT_STATE,
  payload: CurrentImportState,
}

export interface SetCurrentImportID {
  type: typeof SET_CURRENT_IMPORT_ID,
  payload: number
}

export interface SetParsedImportItems {
  type: typeof SET_PARSED_IMPORT_ITEMS,
  payload: ProfitabilityImportParsedData[]
}

export interface SetParsedImportCount {
  type: typeof SET_PARSED_IMPORT_COUNT,
  payload: number
}

export interface SetUpdatedParsedWaybillData {
  type: typeof SET_UPDATED_PARSED_WAYBILL_DATA,
  payload: ProfitabilityImportParsedData
}

export interface ResetCurrentImport {
  type: typeof RESET_CURRENT_IMPORT,
}

export interface ResetProfitability {
  type: typeof RESET_PROFITABILITY,
}

export interface ResetProfitabilityManager {
  type: typeof RESET_PROFITABILITY_MANAGER,
}


export type ProfitabilityActions = SetProfitabilityManagerList
| SetProfitabilityList
| SetProfitabilityTotals
| SetProfitabilityCount
| SetProfitabilityManager
| SetCurrentImportState
| SetCurrentImportID
| SetParsedImportItems
| SetParsedImportCount
| SetUpdatedParsedWaybillData
| ResetProfitability
| ResetProfitabilityManager
| ResetCurrentImport
| SetProfitabilityReport;
