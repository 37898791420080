import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const CompanySpendsChart = React.lazy(() => import('../components/CompanySpendsChart'));

function CompanySpendsView(props: any) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <CompanySpendsChart {...props} />
    </Suspense>
  );
}

export default CompanySpendsView;
