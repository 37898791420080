export const SET_FAILED_BILLING_LIST = 'SET_FAILED_BILLING_LIST';
export const RESET_FAILED_BILLING = 'RESET_FAILED_BILLING';

export interface FailedBilling {
  id: number,
  accountId: number,
  title: string,
  date: string
}

export interface SetFailedBillingList {
  type: typeof SET_FAILED_BILLING_LIST,
  payload: {
    items: FailedBilling[],
    count: number
  }
}

export interface ResetFailedBilling {
  type: typeof RESET_FAILED_BILLING
}

export type FailedBillingActions = SetFailedBillingList
| ResetFailedBilling;
