import React, { useCallback, useState } from 'react';
import { SelectValue } from 'antd/lib/select';
import CustomSelect from '../CustomSelect';
import useDispatch from '../../utils/hooks/useDispatch';
import { loadAccounts } from '../../app/accounts/action-creators';
import { adaptFromApi } from '../../utils/adapter';
import { Account } from '../../app/accounts/types';
import useSelector from '../../utils/hooks/useSelector';
import { RootState } from '../../reducers';

interface AccountFieldProps {
  value?: number,
  onChange: (value?: SelectValue) => void,
}

function AccountField({ value, onChange }: AccountFieldProps) {

  const dispatch = useDispatch();

  const [suggestions, setSuggestions] = useState<Array<any>>([]);

  const { accountsList } = useSelector((state: RootState) => state.accounts);

  const handleAccountSearch = useCallback(
    (query: string) => {
      setSuggestions([])
      return dispatch(loadAccounts(query)).then(
        (response: any) => {
          setSuggestions(response.accounts);
          return Promise.resolve(adaptFromApi(response.accounts).map((item: Account) => ({
            value: item.accountId,
            text: item.title,
          })));
        },
      );
    },
    [dispatch],
  )

  const handleAccountSelect = useCallback(
    (value: SelectValue) => {
      if (suggestions.length) {
        suggestions.forEach(item => {
          if (item.accountId === Number(value)) {
            onChange(item.accountId)
          }
        })
      }
    },
    [suggestions],
  );

  const handleOnChange = () => {
    onChange(undefined)
  }

  return (
    <CustomSelect
      value={accountsList.find(i => i.accountId === value)?.title}
      onChange={handleOnChange}
      onSearch={handleAccountSearch}
      onSelect={handleAccountSelect}
      className="gx-w-100"
    />
  );
}

export default AccountField;