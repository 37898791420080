import React, { Suspense } from 'react';
import CircularProgress from '../../../../components/CircularProgress';

const ReconcillationList = React.lazy(() => import('../components/ReconcillationList'));
function ReconcillationListView(props: any) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <ReconcillationList {...props} />
    </Suspense>
  );
}

export default ReconcillationListView;
