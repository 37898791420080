import React from 'react';
import {
  Area,
  Line,
  ComposedChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const SiteVisit = ({ body, data } : any) => (
  <div className="gx-site-dash gx-pr-xl-5 gx-pt-3 gx-pt-xl-0 gx-pt-xl-2">
    {!!body && body}
    <ResponsiveContainer width="100%" height={240}>
      <ComposedChart
        data={data}
        margin={{
          top: 10, right: 0, left: -20, bottom: 0,
        }}
      >
        <XAxis dataKey="date" />
        <YAxis yAxisId="left" orientation="left" dataKey="count" domain={[0, 'dataMax']} />
        <YAxis yAxisId="right" orientation="right" dataKey="sum" domain={[0, 'dataMax']} />
        <Tooltip
          contentStyle={{ color: 'black' }}
          content={({ payload, formatter, label }: {payload: Array<Object>, formatter: Function, label: string}) => {
            if (!payload || !payload.length) {
              return null;
            }
            return (
              <div
                style={{
                  backgroundColor: '#fff', border: '1px solid #c1c1c1', borderRadius: '5px', padding: '10px',
                }}
                className="custom-tooltip"
              >
                <p>{label}</p>
                {payload.map((item: any) => {
                  const itemData = formatter(item.value, item.name);
                  return <p style={{ color: itemData[2] }} className="label">{`${itemData[1]}: ${itemData[0]}`}</p>;
                })}
              </div>
            );
          }}
          formatter={(value, name, props) => {
            if (name === 'count') {
              return [value, 'Кол-во', '#038FDE'];
            }
            if (name === 'sum') {
              return [`${value} руб`, 'Сумма', '#FE9E15'];
            }
            return null;
          }}
        />
        <CartesianGrid horizontal={false} strokeDasharray="3 3" />
        <Area type="monotone" dataKey="count" fillOpacity={1} stroke="#038FDE" fill="#038FDE" yAxisId="left" />
        <Line type="monotone" dataKey="sum" fillOpacity={1} stroke="#FE9E15" fill="#FE9E15" yAxisId="right" dot={false} />
      </ComposedChart>
    </ResponsiveContainer>
  </div>
);

export default SiteVisit;
