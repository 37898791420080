import moment from 'moment';
import { UploadFile } from 'antd/es/upload/interface';
import {
  AccountComment,
  ADD_ACCOUNT_COMMENT,
  ADD_DIALOG_MESSAGE,
  ADD_DIALOG_MESSAGES, ADD_UPLOAD_FILE,
  ChatReducerAction,
  Dialog,
  Message, REMOVE_UPLOAD_FILE, RESET_UPLOAD_FILES,
  SET_ACCOUNT_COMMENTS_COUNT,
  SET_ACCOUNT_COMMENTS_LIST,
  SET_CURRENT_DIALOG,
  SET_DIALOG_COUNT,
  SET_DIALOG_LIST,
  SET_DIALOG_MESSAGES,
  SET_DIALOG_MESSAGES_COUNT,
} from './types';

export interface ChatState {
  currentDialog: Dialog | null,
  dialogMessages: Message[],
  dialogMessagesCount: number,
  dialogList: Array<Dialog>,
  dialogCount: number,
  accountComments: Array<AccountComment>,
  accountCommentsCount: number,
  uploadFiles: Array<UploadFile>
}

const initialState: ChatState = {
  currentDialog: null,
  dialogMessages: [],
  dialogMessagesCount: 0,
  dialogList: [],
  dialogCount: 0,
  accountComments: [],
  accountCommentsCount: 0,
  uploadFiles: [],
};

type sortByCreatedAtParam<T> = (
  list: Array<{
    createdAt: string,
    [x: string]: any
  }>
) => T;

const sortByCreatedAt: sortByCreatedAtParam<any> = (list) => list.sort((a, b) => {
  const newA = moment(a.createdAt);
  const newB = moment(b.createdAt);
  if (newA.isBefore(newB)) return -1;
  if (newA.isAfter(newB)) return 1;
  return 0;
}).reverse();

const filterUniqueMessage = (arr: Array<Message>) => {
  const uniqueArr: Array<Message> = [];
  arr.forEach((item) => {
    if (!uniqueArr.find((_) => _.messageId === item.messageId)) uniqueArr.push(item);
  });
  return uniqueArr;
};

const removeFile = (files: Array<UploadFile>, file: UploadFile) => {
  const index = files.indexOf(file);
  const newFileList = files.slice();
  newFileList.splice(index, 1);
  return newFileList;
};

export default function (state = initialState, action: ChatReducerAction): ChatState {
  switch (action.type) {
    case SET_CURRENT_DIALOG:
      return { ...state, currentDialog: action.dialog };
    case SET_DIALOG_MESSAGES:
      return { ...state, dialogMessages: filterUniqueMessage(action.messages) };
    case ADD_DIALOG_MESSAGES:
      return { ...state, dialogMessages: filterUniqueMessage(sortByCreatedAt([...state.dialogMessages, ...action.messages])) };
    case ADD_DIALOG_MESSAGE:
      return {
        ...state,
        dialogMessages: filterUniqueMessage(sortByCreatedAt([...state.dialogMessages, action.message])),
        dialogMessagesCount: state.dialogMessagesCount + 1,
      };
    case SET_DIALOG_MESSAGES_COUNT:
      return { ...state, dialogMessagesCount: action.count };
    case SET_DIALOG_LIST:
      return { ...state, dialogList: action.dialogs };
    case SET_DIALOG_COUNT:
      return { ...state, dialogCount: action.count };
    case SET_ACCOUNT_COMMENTS_LIST:
      return { ...state, accountComments: action.comments };
    case SET_ACCOUNT_COMMENTS_COUNT:
      return { ...state, accountCommentsCount: action.count };
    case ADD_ACCOUNT_COMMENT:
      return { ...state, accountComments: sortByCreatedAt([action.comment, ...state.accountComments]) };
    case ADD_UPLOAD_FILE:
      return { ...state, uploadFiles: [...state.uploadFiles, action.file] };
    case RESET_UPLOAD_FILES:
      return { ...state, uploadFiles: initialState.uploadFiles };
    case REMOVE_UPLOAD_FILE:
      return { ...state, uploadFiles: removeFile(state.uploadFiles, action.file) };
    default:
      return state;
  }
}
