import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const ClientActiveStat = React.lazy(() => import('../components/ClientActiveStat'));

function ClientActiveStatView(props: anyObject) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <ClientActiveStat {...props} />
    </Suspense>
  );
}

export default ClientActiveStatView;
