import React from "react";
import useSelector from "../../../utils/hooks/useSelector";
import { RootState } from "../../../reducers";

type Props = {
    sellerId: number
}

const SellerLabel = ({
    sellerId
}: Props) => {
    const { sellers } = useSelector((state: RootState) => state.reference);
    const seller = sellers.find(i => i.sellerId === sellerId)
    return (
        <span>{seller?.title}</span>
    )
}

export { SellerLabel };