import React from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
  Col,
  Row,
  Table,
} from 'antd';
import { useHistory } from 'react-router';
import moment from 'moment';
import Icon from 'antd/es/icon';
import { USER_DATE_FORMAT } from '../../../utils/constants';

function AccountDebtTable({ loading }) {
  const { arrearsList } = useSelector((state) => state.accounts);
  const { sellers } = useSelector((state) => state.reference);
  const sellerDict = sellers.reduce((acc, seller) => ({ ...acc, [seller.sellerId]: seller }), {});
  const history = useHistory();

  const getUserStatus = (record) => {
    let color = {
      primary: '#bfbfbf',
      outline: '#8c8c8c',
    };

    if (record.owner.userId) {
      color = {
        primary: '#fadb14',
        outline: '#fa8c16',
      };
      if (record.status === 2) {
        color = {
          primary: '#20c997',
          outline: '#52c41a',
        };
      }
    }

    return <Icon type="smile" theme="twoTone" style={{ color: color.primary }} twoToneColor={color.outline} />;
  };

  const getContractStatus = (record) => {
    let color = {
      primary: '#bfbfbf',
      outline: '#8c8c8c',
    };

    if (record.contract) {
      if (record.contract.status === 'confirmed') {
        color = {
          primary: '#20c997',
          outline: '#52c41a',
        };
      } else {
        color = {
          primary: '#fadb14',
          outline: '#fa8c16',
        };
      }
    }

    return <Icon type="file-text" theme="twoTone" style={{ color: color.primary }} twoToneColor={color.outline} />;
  };

  const handleClick = (record) => {
    history.push(`/accounts/${record.accountId}`);
  };

  const columns = [
    {
      title: 'Название юрлица',
      dataIndex: 'account.title',
      key: 'account.title',
      render: (text, record) => (<span>{getUserStatus(record.account)} {getContractStatus(record.account)} {text}</span>),
    },
    {
      title: 'ИНН',
      dataIndex: 'account.inn',
      key: 'account.inn',
    },
    {
      title: 'Продавец',
      dataIndex: 'account.contract.sellerId',
      key: 'sellerId',
      render: (text) => <span>{sellerDict[text]?.title}</span>,
    },
    {
      title: 'Задолженность',
      dataIndex: 'billingValue',
      key: 'billingValue',
    },
  ];

  return (
    <Card>
      <Table
        style={{ margin: '-24px' }}
        className="gx-table-responsive"
        columns={columns}
        onRowClick={handleClick}
        rowClassName="gx-pointer"
        loading={loading}
        dataSource={arrearsList}
        pagination={false}
      />
    </Card>
  );
}

export default AccountDebtTable;
