import React, { useRef } from 'react';
import {
  Row, Col, Popover, Collapse, Tag, Tooltip,
} from 'antd';
import Icon from 'antd/es/icon';

import SearchInput from './SearchInput';

const SIMULATED_FIELD_ID = 'simulatedField';
const REAL_FIELD_ID = 'searchField';

const TooltipWrapper = ({
  tooltip,
  children,
}) => {
  if (tooltip) {
    return <Tooltip title={tooltip}>{children}</Tooltip>;
  }

  return children;
};

export default function SearchBox({
  searchValue,
  searchPlaceholder,
  onSearchChange,

  filters,
  tags,
  withFavorites,
}) {
  const handleSimulatedFieldClick = (e) => {
    if (e.target.id === SIMULATED_FIELD_ID) {
      document.getElementById(REAL_FIELD_ID).focus();
    }
  };

  const elementStyles = {
    mainInput: {},
    filtersButton: {},
    favoriesButton: {},
  };
  if (filters.length && withFavorites) {
    elementStyles.mainInput = { width: 'calc(100% - 94px)' };
    elementStyles.filtersButton = { right: '48px' };
    elementStyles.favoriesButton = { right: '0' };
  } else if (filters.length) {
    elementStyles.mainInput = { width: 'calc(100% - 47px)' };
    elementStyles.filtersButton = { right: '0' };
  } else {
    elementStyles.mainInput = { width: '100%' };
    elementStyles.filtersButton = { right: '0' };
  }

  return (
    <div className="gx-search-bar">
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <span
            style={{ marginBottom: '20px' }}
            className="ant-input-search-enter-button ant-input-search-large ant-input-group-wrapper ant-input-group-wrapper-lg"
          >
            <span
              className="ant-input-wrapper ant-input-group ant-input-group-compact"
              style={{
                position: 'relative',
                width: '100%',
              }}
            >
              <div
                style={elementStyles.mainInput}
                className="ant-input ant-input-lg search-input-double-button simulated-search-field"
                onClick={handleSimulatedFieldClick}
                id={SIMULATED_FIELD_ID}
              >
                {!!tags && !!tags.length && tags.map((tag, tagKey) => (
                  <Tag
                    key={tagKey}
                    onClose={tag.onClose}
                    closable
                    className="ant-select-selection__choice simulated-search-field__tag"
                  >
                    <TooltipWrapper tooltip={tag.tooltip}>
                      <span>{tag.content}</span>
                    </TooltipWrapper>
                  </Tag>
                ))}
                <div className="simulated-search-field__container">
                  <SearchInput
                    inputId={REAL_FIELD_ID}
                    value={searchValue}
                    placeholder={searchPlaceholder}
                    onChange={onSearchChange}
                  />
                </div>
              </div>
              {!!filters && !!filters.length && (
                <Popover
                  placement="bottomRight"
                  content={(
                    <Collapse style={{ margin: '-12px -16px', width: 320 }} accordion>
                      {filters.map(({
                        title, Component, value, onChange, componentProps,
                      }, key) => (
                        <Collapse.Panel header={title} key={key}>
                          <Component {...componentProps} value={value} onChange={onChange} />
                        </Collapse.Panel>
                      ))}
                    </Collapse>
                  )}
                  trigger="click"
                >
                  <button
                    type="button"
                    style={elementStyles.filtersButton}
                    className="ant-btn ant-input-search-button ant-btn-default ant-btn-lg search-box__button"
                  >
                    <Icon type="filter" theme="twoTone" style={{ color: '#fadb14' }} twoToneColor="#fa8c16" />
                  </button>
                </Popover>
              )}
              {withFavorites && (
                <button
                  type="button"
                  style={elementStyles.favoriesButton}
                  className="ant-btn ant-input-search-button ant-btn-default ant-btn-lg search-box__button"
                >
                  <Icon type="star" theme="twoTone" style={{ color: '#fadb14' }} twoToneColor="#fa8c16" />
                </button>
              )}
            </span>
          </span>
        </Col>
      </Row>
    </div>
  );
}
