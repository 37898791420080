import React from 'react';
import {
  Button, Row, Col, Select, DatePicker, Card,
} from 'antd';
import { USER_DATE_FORMAT } from '../../../../../utils/constants';

const SearchBox = ({
  styleName, placeholder, onChange, value,
}) => {
  // eslint-disable-next-line no-unused-vars
  function handleButtonClick(e) {
    console.log('click left button', e);
  }

  const { Option } = Select;

  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  function handleBlur() {
    console.log('blur');
  }

  function handleFocus() {
    console.log('focus');
  }

  return (
    <Row>
      <Col lg={24} md={24} sm={24} xs={24}>
        <Card>
          <div className={`gx-search-bar ${styleName}`}>
            <Row>

              <Col lg={9} md={9} sm={24} xs={24}>
                <div className="ant-input-search ant-input-affix-wrapper">
                  <input
                    className="ant-input"
                    type="search"
                    placeholder="Номер счета "
                    onChange={handleButtonClick}
                    value={value}
                  />
                </div>

              </Col>

              <Col lg={4} md={4} sm={24} xs={24}>
                <div className="ant-input-search ant-input-affix-wrapper">
                  <input
                    className="ant-input"
                    type="search"
                    placeholder="Клиент"
                    onChange={handleButtonClick}
                    value={value}
                  />
                </div>

              </Col>
              <Col lg={4} md={4} sm={12} xs={24}>
                <div className="gx-form-group">
                  <DatePicker
                    className="gx-mb-3 gx-w-100"
                    placeholder="Дата создания"
                    onChange={onChange}
                    format={USER_DATE_FORMAT}
                  />

                </div>

              </Col>
              <Col lg={3} md={3} sm={24} xs={24}>
                <div className="ant-input-search ant-input-affix-wrapper">
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Статус"
                    optionFilterProp="children"
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    <Option value="jack">Jack</Option>
                    <Option value="lucy">Lucy</Option>
                    <Option value="tom">Tom</Option>
                  </Select>
                </div>
              </Col>
              <Col lg={4} md={4} sm={12} xs={24}>
                <div className="gx-form-group">
                  <Button className="gx-w-100 btn btn-primary">
                                        Найти
                  </Button>
                </div>

              </Col>
            </Row>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

SearchBox.defaultProps = {
  styleName: '',
  value: '',
};

export default SearchBox;
