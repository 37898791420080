export const accountStatus = [
  {
    id: 1,
    title: 'Регистрация',
    color: 'grey',
  },
  {
    id: 2,
    title: 'Активный',
    color: 'green',
  },
  {
    id: 3,
    title: 'Заблокирован',
    color: 'red',
  },
];

export const accountLoyalty = {
  1: { title: 'Ранговая система' },
  2: { title: 'Временная система' },
  3: { title: 'Постоянная система' },
  4: { title: 'Тендерная система' },
};

export const accountRank: {[key: string]: { title: string, groups: any }} = {
  bronze: {
    title: 'Бронза',
    groups: {
      public: { cashback: 1, insurance: 0.75 },
      carrier: { cashback: 1, insurance: 0.75 },
      shop: { cashback: 1, insurance: 0.75 },
    },
  },
  silver: {
    title: 'Серебро',
    groups: {
      public: { cashback: 2, insurance: 0.7 },
      carrier: { cashback: 2, insurance: 0.6 },
      shop: { cashback: 2, insurance: 0.7 },
    },
  },
  gold: {
    title: 'Золото',
    groups: {
      public: { cashback: 3, insurance: 0.65 },
      carrier: { cashback: 3, insurance: 0.65 },
      shop: { cashback: 3, insurance: 0.65 },
    },
  },
  platinum: {
    title: 'Платина',
    groups: {
      public: { cashback: 5, insurance: 0.6 },
      carrier: { cashback: 4, insurance: 0.5 },
      shop: { cashback: 5, insurance: 0.55 },
    },
  },
  vip: {
    title: 'VIP',
    groups: {
      public: { cashback: 10, insurance: 0.55 },
      carrier: { cashback: 10, insurance: 0.45 },
      shop: { cashback: 10, insurance: 0.45 },
    },
  },
};

export const growthDescription = {
  "increased": "Рост увеличился",
  "reduced": "Рост уменьшился",
  "gone": "Ушли"
}