import {
  ProfitabilityActions,
  SET_PROFITABILITY_MANAGER_LIST,
  SET_PROFITABILITY_LIST,
  SET_PROFITABILITY_COUNT,
  SET_PROFITABILITY_MANAGER,
  RESET_PROFITABILITY,
  RESET_PROFITABILITY_MANAGER,
  Profitability,
  CurrentImportState,
  SET_CURRENT_IMPORT_STATE,
  RESET_CURRENT_IMPORT,
  ProfitabilityImportParsedData,
  SET_PARSED_IMPORT_ITEMS,
  SET_PARSED_IMPORT_COUNT,
  SET_CURRENT_IMPORT_ID,
  SET_UPDATED_PARSED_WAYBILL_DATA,
  SET_PROFITABILITY_TOTALS,
  ProfitabilityTotals,
  SET_PROFITABILITY_REPORT_URL,
} from './types';
import { SalaryManager } from '../salary/types';

export interface ProfitabilityState {
  profitabilityManagerList: SalaryManager[],
  currentProfitabilityManager: SalaryManager | null,
  profitabilityList: Profitability[],
  profitabilityCount: number,
  currentImportState: CurrentImportState,
  currentImportId: number,
  parsedImportItems: ProfitabilityImportParsedData[],
  parsedImportCount: number,
  profitabilityTotals: ProfitabilityTotals | null,
  reportURL: string | null
}

const initialState: ProfitabilityState = {
  profitabilityManagerList: [],
  currentProfitabilityManager: null,
  profitabilityList: [],
  profitabilityCount: 0,
  currentImportState: CurrentImportState.NEW,
  currentImportId: 0,
  parsedImportItems: [],
  parsedImportCount: 0,
  profitabilityTotals: null,
  reportURL: null
};

export default function (state = initialState, action: ProfitabilityActions): ProfitabilityState {
  switch (action.type) {
    case SET_PROFITABILITY_MANAGER_LIST:
      return { ...state, profitabilityManagerList: action.payload };
    case SET_PROFITABILITY_LIST:
      return { ...state, profitabilityList: action.payload };
    case SET_PROFITABILITY_COUNT:
      return { ...state, profitabilityCount: action.payload };
    case SET_PROFITABILITY_MANAGER:
      return { ...state, currentProfitabilityManager: action.payload };

    case SET_CURRENT_IMPORT_STATE:
      return { ...state, currentImportState: action.payload };
    case SET_PARSED_IMPORT_ITEMS:
      return { ...state, parsedImportItems: action.payload };
    case SET_PARSED_IMPORT_COUNT:
      return { ...state, parsedImportCount: action.payload };
    case SET_CURRENT_IMPORT_ID:
      return { ...state, currentImportId: action.payload };
    case SET_UPDATED_PARSED_WAYBILL_DATA:
      return { ...state, parsedImportItems: state.parsedImportItems.map(i => {
        if (i.plsWaybillNumber === action.payload.plsWaybillNumber) {
          return ({
            ...i,
            ...action.payload
          });
        }
        return i;
      }) }
    case SET_PROFITABILITY_TOTALS:
      return { ...state, profitabilityTotals: action.payload }
    
    case SET_PROFITABILITY_REPORT_URL:
      return { ...state, reportURL: action.payload }

    case RESET_CURRENT_IMPORT:
      return { ...initialState };

    case RESET_PROFITABILITY:
      return { ...state, profitabilityList: [] }
    case RESET_PROFITABILITY_MANAGER:
      return { ...state, currentProfitabilityManager: null }
      
    default:
      return state;
  }
}
