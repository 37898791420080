export const SET_TENDER_CALCULATION_LIST = 'SET_TENDER_CALCULATION_LIST';
export const SET_TENDER_CALCULATION_COUNT = 'SET_TENDER_CALCULATION_COUNT';
export const SET_TENDER_CALCULATION_DETAIL = 'SET_TENDER_CALCULATION_DETAIL';
export const RESET_TENDER_CALCULATION_DETAIL = 'RESET_TENDER_CALCULATION_DETAIL';
export const ADD_TENDER_CALCULATION_WEIGHT = 'ADD_TENDER_CALCULATION_WEIGHT';
export const REMOVE_TENDER_CALCULATION_WEIGHT = 'REMOVE_TENDER_CALCULATION_WEIGHT';
export const ADD_TENDER_CALCULATION_DESTINATION = 'ADD_TENDER_CALCULATION_DESTINATION';
export const REMOVE_TENDER_CALCULATION_DESTINATION = 'REMOVE_TENDER_CALCULATION_DESTINATION';
export const ADD_OR_UPDATE_TENDER_CALCULATION_WEIGHT_RESULT = 'ADD_OR_UPDATE_TENDER_CALCULATION_WEIGHT_RESULT';
export const SET_CALCULATION_RUNNING = 'SET_CALCULATION_RUNNING';
export const SET_TENDER_CALCULATION_IMPORT_STATUS = 'SET_TENDER_CALCULATION_IMPORT_STATUS';
export const RESET_TENDER_CALCULATION_IMPORT_STATUS = 'RESET_TENDER_CALCULATION_IMPORT_STATUS';

export const SET_ACCOUNT_TENDER_IMPORT_ID = 'SET_ACCOUNT_TENDER_IMPORT_ID';
export const SET_ACCOUNT_TENDER_IMPORT_STATUS = 'SET_ACCOUNT_TENDER_IMPORT_STATUS';
export const RESET_ACCOUNT_TENDER_IMPORT = 'RESET_ACCOUNT_TENDER_IMPORT';

export type TenderCalculationListItem = {
  tenderCalculationId: number,
  createdAt: string,
};

export type TenderCalculationWeight = {
  weightId: number,
  value: number,
};

export type TenderCalculationDestination = {
  destinationId: number,
  senderIso: string,
  senderRegion: string,
  senderDistrict: string,
  senderCity: string,
  receiverIso: string,
  receiverRegion: string,
  receiverDistrict: string,
  receiverCity: string,
};

export type TenderDestinationWeightResult = {
  weightId: number,
  destinationId: number,
  price: number,
  minDays: number,
  maxDays: number,
  primeCost?: number,
};

export type TenderCalculationDetail = {
  tenderCalculationId: number,
  weightList: TenderCalculationWeight[],
  destinations: TenderCalculationDestination[],
  resultList: TenderDestinationWeightResult[],
  createdAt: string,
};

export enum TenderCalculationImportStatus {
  INITIAL,
  RUNNING,
  DONE
}

export enum AccountTenderImportStatusAction {
  ERROR = 'error',
  SUCCESS = 'success'
}

export type AccountTenderImportStatus = {
  action: AccountTenderImportStatusAction,
  importId: string,
  detail?: string,
};

export interface SetTenderCalculationList {
  type: typeof SET_TENDER_CALCULATION_LIST,
  list: TenderCalculationListItem[],
}

export interface SetTenderCalculationCount {
  type: typeof SET_TENDER_CALCULATION_COUNT,
  count: number,
}

export interface SetTenderCalculationDetail {
  type: typeof SET_TENDER_CALCULATION_DETAIL,
  detail: TenderCalculationDetail,
}

export interface ResetTenderCalculationDetail {
  type: typeof RESET_TENDER_CALCULATION_DETAIL,
}

export interface AddTenderCalculationWeight {
  type: typeof ADD_TENDER_CALCULATION_WEIGHT,
  weight: TenderCalculationWeight,
}

export interface RemoveTenderCalculationWeight {
  type: typeof REMOVE_TENDER_CALCULATION_WEIGHT,
  weightId: number,
}

export interface AddTenderCalculationDestination {
  type: typeof ADD_TENDER_CALCULATION_DESTINATION,
  destination: TenderCalculationDestination,
}

export interface RemoveTenderCalculationDestination {
  type: typeof REMOVE_TENDER_CALCULATION_DESTINATION,
  destinationId: number,
}

export interface AddOrUpdateTenderCalculationWeightResult {
  type: typeof ADD_OR_UPDATE_TENDER_CALCULATION_WEIGHT_RESULT,
  result: TenderDestinationWeightResult,
}

export interface SetCalculationRunning {
  type: typeof SET_CALCULATION_RUNNING,
  running: boolean,
}

export interface SetTenderCalculationImportStatus {
  type: typeof SET_TENDER_CALCULATION_IMPORT_STATUS,
  status: TenderCalculationImportStatus,
}

export interface ResetTenderCalculationImportStatus {
  type: typeof RESET_TENDER_CALCULATION_IMPORT_STATUS,
}

export interface SetAccountTenderImportId {
  type: typeof SET_ACCOUNT_TENDER_IMPORT_ID,
  importId: string,
}

export interface SetAccountTenderImportStatus {
  type: typeof SET_ACCOUNT_TENDER_IMPORT_STATUS,
  status: AccountTenderImportStatus
}

export interface ResetAccountTenderImport {
  type: typeof RESET_ACCOUNT_TENDER_IMPORT,
}

export type TenderActions =
  SetTenderCalculationList |
  SetTenderCalculationCount |
  SetTenderCalculationDetail |
  ResetTenderCalculationDetail |
  AddTenderCalculationWeight |
  RemoveTenderCalculationWeight |
  AddTenderCalculationDestination |
  RemoveTenderCalculationDestination |
  AddOrUpdateTenderCalculationWeightResult|
  SetCalculationRunning |
  SetTenderCalculationImportStatus |
  ResetTenderCalculationImportStatus |
  SetAccountTenderImportId |
  SetAccountTenderImportStatus |
  ResetAccountTenderImport;
