import React from 'react';
import CircularProgress from '../../../../components/CircularProgress';

const NotificationTemplatePreview = React.lazy(() => import('../components/NotificationTemplatePreview'));

function NotificationTemplatePreviewView(props: any) {
  return (
    <React.Suspense fallback={<CircularProgress />}>
      <NotificationTemplatePreview {...props}/>
    </React.Suspense>
  );
}

export default NotificationTemplatePreviewView;
