import { ThunkAction } from '../../utils/types';
import { SERVER_DATE_FORMAT } from '../../utils/constants';
import { getFileUrl } from '../../utils/file';

export const getWaybillCostReport = (filters: anyObject): ThunkAction<Promise<string>> => (dispatch, getState, http) => {
  const params: anyObject = {};

  if (filters.query) params.query = filters.query;
  if (filters.page) params.page = filters.page;
  if (filters.createdAtFrom) params.created_at_from = filters.createdAtFrom.format(SERVER_DATE_FORMAT);
  if (filters.createdAtTo) params.created_at_to = filters.createdAtTo.format(SERVER_DATE_FORMAT);
  if (filters.pickupDateFrom) params.pickup_date_from = filters.pickupDateFrom.format(SERVER_DATE_FORMAT);
  if (filters.pickupDateTo) params.pickup_date_to = filters.pickupDateTo.format(SERVER_DATE_FORMAT);
  if (filters.billingDateFrom) params.billing_date_from = filters.billingDateFrom.format(SERVER_DATE_FORMAT);
  if (filters.billingDateTo) params.billing_date_to = filters.billingDateTo.format(SERVER_DATE_FORMAT);
  if (filters.service) params.service = filters.service;
  if (filters.status && filters.status.length) params.status = filters.status;
  if (filters.additionalService && filters.additionalService.length) params.additional_service = filters.additionalService;
  if (filters.deliveryDateFrom) params.delivery_date_from = filters.deliveryDateFrom.format(SERVER_DATE_FORMAT);
  if (filters.deliveryDateTo) params.delivery_date_to = filters.deliveryDateTo.format(SERVER_DATE_FORMAT);
  if (filters.accountId) params.account_id = filters.accountId;

  return http.post(
    '/api/v1/tms/waybill/report/cost/',
    params,
  ).then(
    ({ url }: { url: string }) => Promise.resolve(getFileUrl(url)),
  );
};
