import { ClientBill } from './types';

export interface ClientBillState {
  billsList: ClientBill[],
  billsCount: number
}

const initialState: ClientBillState = {
  billsList: [],
  billsCount: 0,
};

export default function (state = initialState, action: any): ClientBillState {
  switch (action.type) {
    case 'SET_CLIENT_BILL_LIST':
      return { ...state, billsList: action.bills };
    case 'SET_CLIENT_BILL_COUNT':
      return { ...state, billsCount: action.count };
    default:
      return state;
  }
}
