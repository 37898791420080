import Centrifuge from 'centrifuge';
import { ThunkAction } from './types';
import { setToken, signOut, setCent } from '../app/users/action-creators';
import { adaptFromApi } from './adapter';
import { acceptTenderWS, handleTenderCalculationWS } from '../app/tender/action-creators';
import { acceptMarketingImportWs } from '../app/marketing/action-creators';
import getAppConfig from '../util/getAppConfig';
import { fetchParsedImportData, setCurrentImportState } from '../app/profitability/action-creators';
import { CurrentImportState } from '../app/profitability/types';
import { setManagerRefreshState } from '../app/salary/action-creators';
import { ManagerRefreshStateStatuses } from '../app/salary/types';

const IMPORT_HANDLERS = [
  acceptMarketingImportWs,
];

export default function (): ThunkAction<void> {
  return (dispatch, getState, http) => {
    const { users: { user, cent, token } } = getState();
    if (!user || !token) return;
    if (cent) cent.disconnect();

    const { centrifugeHost } = getAppConfig();
    const c = new Centrifuge(`wss://${centrifugeHost}/connection/websocket`, {
      // debug: true,
      onRefresh: (ctx, cb) => {
        http.post('/api/v1/user/token_refresh/', { refresh_token: token.refreshToken }, { NO_AUTH: true }).then(
          (response: any) => {
            const { accessToken, refreshToken } = response;
            const expiredAt = new Date().getTime() + 300000;
            dispatch(setToken(accessToken, refreshToken, expiredAt));
            cb({ status: 200, data: { token: accessToken } });
          },
          () => {
            dispatch(signOut());
            c.disconnect();
          },
        );
      },
      onPrivateSubscribe: (ctx, cb) => {
        http.post('/api/v1/user/cent/subscribe/', ctx.data).then(
          (response: any) => {
            cb({ status: 200, data: response });
          },
        );
      },
    });

    if (token.accessToken) c.setToken(token.accessToken);

    c.subscribe('$tender_calculation', (message) => {
      const { data } = adaptFromApi(message);
      console.debug('message', message);
      dispatch(handleTenderCalculationWS(data));
    });

    c.subscribe('$crm_tender', (message) => {
      const { data: { type, payload } } = adaptFromApi(message);
      dispatch(acceptTenderWS(type, payload));
    });

    c.subscribe('$crm_import', (message) => {
      const { data: { type, payload } } = adaptFromApi(message);
      const handlers = IMPORT_HANDLERS.filter((_) => _.wsType === type);
      handlers.forEach((handler) => {
        try {
          dispatch(handler(payload));
        } catch (e) {}
      });
    });

    c.subscribe(`profitability_import#${user.id}`, (message) => {
      const { data: { profId, command } } = adaptFromApi(message);
      if (command === 'done') {
        dispatch(setCurrentImportState(CurrentImportState.DONE));
        dispatch(fetchParsedImportData(profId, 1))
      }
    });

    c.subscribe('$crm_refresh_manager', (message) => {
      const { data: { managerId, command } } = adaptFromApi(message);
      if (command === 'done') {
        dispatch(setManagerRefreshState({managerId, status: ManagerRefreshStateStatuses.DONE}));
      }
    });

    c.connect();
    dispatch(setCent(c));
  };
}
