import { Detailing, detailingType } from './types';

export interface DetailingState {
  detailingList: Detailing[],
  detailingCount: number,
}

const initialState: DetailingState = {
  detailingList: [],
  detailingCount: 0,
};

export default function (state = initialState, action: detailingType): DetailingState {
  switch (action.type) {
    case 'SET_DETAILING_LIST':
      return { ...state, detailingList: action.items };
    case 'SET_DETAILING_COUNT':
      return { ...state, detailingCount: action.count };
    default:
      return state;
  }
}
