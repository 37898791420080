import {
  SET_DOWNGRADED_ORDERS_COUNT,
  SET_DOWNGRADED_ORDERS_LIST,
  DowngradedItem,
  DowngradedOrdersActions
} from './types';

export interface DowngradedOrdersState {
  downgradedOrdersList: Array<DowngradedItem>,
  downgradedOrdersCount: number,
}

const initialState: DowngradedOrdersState = {
  downgradedOrdersList: [],
  downgradedOrdersCount: 0,
};

export default function (state = initialState, action: DowngradedOrdersActions): DowngradedOrdersState {
  switch (action.type) {
    case SET_DOWNGRADED_ORDERS_LIST:
      return { ...state, downgradedOrdersList: action.payload };
    case SET_DOWNGRADED_ORDERS_COUNT:
      return { ...state, downgradedOrdersCount: action.payload };
    default:
      return state;
  }
}
