import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import NotFound from '../views/NotFound';
import WrappedNormalLoginForm from '../app/users/views/UserLoginView';
import NonAuthorizedRoute from './NonAuthorizeRoute';
import AccountRouter from './AccountRouter';
import AuthorizedRouter from './AuthorizedRouter';

function BaseRouter() {
  return (
    <Switch>
      <Redirect exact path="/" to="/accounts" />

      <NonAuthorizedRoute exact path="/login" component={WrappedNormalLoginForm} />
      <Route exact path="/404" component={NotFound} />
      <Route path="/" component={AuthorizedRouter} />
      <Redirect to="/404" />
    </Switch>
  );
}

export default BaseRouter;
