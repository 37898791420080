import React, { useEffect } from 'react';
import { Layout } from 'antd';
import TopBar from '../customComponents/Topbar/index.js';
import useDispatch from '../utils/hooks/useDispatch';
import { loadDeliveryServices } from '../app/reference/action-creators';

function MainView({ children }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadDeliveryServices());
  }, []);

  return (
    <Layout className="ant-layout">
      <TopBar />
      <div className="gx-layout-content gx-container-wrap  ant-layout-content">
        <div className="gx-main-content-wrapper">
          { children }
        </div>
      </div>
    </Layout>
  );
}

export default MainView;
