import React from 'react';
import { DatePicker } from 'antd';
import { USER_DATE_FORMAT } from '../../../utils/constants';

function DateRangeField({
  value = [null, null],
  onChange,
  fromPlaceholder = '',
  fromLabel = '',
  toPlaceholder = '',
  toLabel = '',
  style = {},
  singleLine = false,
}) {
  const handleFromDateChange = (fromDate) => {
    onChange([fromDate, value.length ? value[1] : null]);
  };

  const handleToDateChange = (toDate) => {
    onChange([value.length ? value[0] : null, toDate]);
  };

  const disabledFromDate = (current) => (value[1] ? current > value[1] : false);
  const disabledToDate = (current) => (value[0] ? current < value[0] : false);

  return (
    <div style={style}>
      <div>
        {!!fromLabel && (
          <span>{fromLabel}</span>
        )}
        <DatePicker
          className={singleLine ? '' : 'gx-mb-3 gx-w-100'}
          placeholder={fromPlaceholder}
          disabledDate={disabledFromDate}
          onChange={handleFromDateChange}
          value={value.length ? value[0] : null}
          locale="ru"
          format={USER_DATE_FORMAT}
        />
      </div>
      <div>
        {!!toLabel && (
          <span>{toLabel}</span>
        )}
        <DatePicker
          className={singleLine ? '' : 'gx-w-100'}
          placeholder={toPlaceholder}
          disabledDate={disabledToDate}
          onChange={handleToDateChange}
          value={value.length ? value[1] : null}
          locale="ru"
          format={USER_DATE_FORMAT}
        />
      </div>
    </div>
  );
}

export default DateRangeField;
