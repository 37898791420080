import React, { useCallback } from "react";
import { Field, Form, Formik } from 'formik';
import Modal from 'antd/es/modal';
import useSelector from "../../../utils/hooks/useSelector";
import { RootState } from "../../../reducers";
import useDispatch from "../../../utils/hooks/useDispatch";
import { SERVER_DATE_FORMAT } from "../../../utils/constants";
import { Col, Row } from "antd";
import { AntInputNumber } from "../../../components/CreateAntField";
import { profitabilityUpdateTypes } from "../constants";
import DeliveryServiceField from "../../../components/form/DeliveryServiceField";
import { updateOrdersByNumberOrService } from "../action-creators";

type Props = {
    updateType: profitabilityUpdateTypes | null;
    selectedOrders: string[];
    handleClose: () => void;
}

const ProfitabilityUpdateModal = ({
    updateType,
    selectedOrders,
    handleClose
}: Props) => {
    const dispatch = useDispatch();

    const { workPeriodList, currentSalaryManager } = useSelector((state: RootState) => state.salary);

    const handleSubmit = React.useCallback(
        (values, actions) => {
            if (!!updateType && (values.deliveryService || selectedOrders.length) && values.increasePercent) {
                dispatch(updateOrdersByNumberOrService((values.increasePercent / 100) + 1, selectedOrders, values.deliveryService)).then(
                    () => handleClose(),
                    ({ item }) => actions.setErrors(item),
                ).finally(() => actions.setSubmitting(false));
            }
        },
        [workPeriodList, currentSalaryManager, updateType],
    );

    return (
        <Formik
          initialValues={{}}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ submitForm, isSubmitting }) => (
            <Modal
              visible={!!updateType}
              onCancel={handleClose}
              title="Редактирование"
              onOk={submitForm}
              confirmLoading={isSubmitting}
              okText="Сохранить"
              cancelText="Отменить"
            >
              <Form>
                <Row>
                  {
                    updateType === profitabilityUpdateTypes.BY_DELIVERY_SERVICE
                    ?
                    <Col xs={24} md={24}>
                        <Field
                            component={DeliveryServiceField}
                            name="deliveryService"
                            placeholder="Перевозчик"
                            title="Перевозчик"
                            label="Перевозчик"
                            componentClassName="gx-w-100"
                            />
                    </Col>
                    :
                    null
                  }
                  <Col xs={24} md={24}>
                    <Field
                      component={AntInputNumber}
                      name="increasePercent"
                      placeholder="Увеличить на (процент)"
                      title="Увеличить на (процент)"
                      label="Увеличить на (процент)"
                      componentClassName="gx-w-100"
                    />
                  </Col>
                </Row>
              </Form>
            </Modal>
          )}
        </Formik>
    )
}

export { ProfitabilityUpdateModal }