import { Profitability } from "../profitability/types";

export const SET_SALARY_MANAGER_LIST = 'SET_SALARY_MANAGER_LIST';
export const SET_SALARY_MANAGER = 'SET_SALARY_MANAGER';
export const SET_MANAGER_WORK_PERIOD_LIST = 'SET_MANAGER_WORK_PERIOD_LIST';
export const RESET_SALARY_STAT = 'RESET_SALARY_STAT';
export const RESET_SALARY_DETAILS = 'RESET_SALARY_DETAILS';
export const RESET_SALARY_MANAGER = 'RESET_SALARY_MANAGER';
export const UPDATE_SALARY_MANAGER = 'UPDATE_SALARY_MANAGER';
export const UPDATE_WORK_PERIOD = 'UPDATE_WORK_PERIOD';
export const SET_COMPLETION_RATE_CLIENT_LIST = 'SET_COMPLETION_RATE_CLIENT_LIST';
export const SET_COMPLETION_RATE_STATISTICS_LIST = 'SET_COMPLETION_RATE_STATISTICS_LIST';
export const SET_INVOICES_AND_PAYMENTS_STATISTICS_LIST = 'SET_INVOICES_AND_PAYMENTS_STATISTICS_LIST';
export const SET_INVOICES_AND_PAYMENTS_TOTALS = 'SET_INVOICES_AND_PAYMENTS_TOTALS';
export const SET_INVOICES_AND_PAYMENTS_DETAILS = 'SET_INVOICES_AND_PAYMENTS_DETAILS';
export const SET_MANAGER_PROFITABILITY = 'SET_MANAGER_PROFITABILITY';
export const SET_MANAGER_PROFITABILITY_DETAILS = 'SET_MANAGER_PROFITABILITY_DETAILS';
export const SET_TOTAL_MANAGER_PROFITABILITY = 'SET_TOTAL_MANAGER_PROFITABILITY';
export const SET_MANAGER_REFRESH_STATE = 'SET_MANAGER_REFRESH_STATE';

export interface SalaryManager {
  managerId: number,
  managerUsername: string,
  managerType: string,
  awardPercent: number,
  sellAwardPercent: number,
  salary: number,
  profitabilityAwardPercent: number,
}

export interface ProfitabilitySettingsItem {
  id: number,
  fromPercent: number,
  toPercent: number,
  targetPercent: number
}

export interface WorkPeriod {
  periodStart: string,
  periodEnd: string,
  totalWorked: number,
  totalDays: number,
  predictedCompletionRate: number,
  totalCompletionRate: number,
  totalPeriodPayments: number,
  predictedProfitability: number,
  totalRatePaidPart?: number,
  sellAward?: number,
  profitabilitySettings: ProfitabilitySettingsItem[]
}

export interface InvoicesAndPaymentsRateDetails {
  ratePaidPart?: number,
  sellAward?: number,
  profitabilityPercent?: number,
  invoiceNumber: string,
  invoiceDate: string
}

export interface InvoicesAndPaymentsStatistics {
  accountId: number,
  title: string,
  totalPeriodPayments: number,
  totalRatePaidPart?: number,
  sellAward?: number,
  profitabilityPercent?: number,
  details: InvoicesAndPaymentsRateDetails[]
}

export interface CompletionRateClient {
  accountId: number,
  periodStart: string,
  periodEnd: string,
  amount: number,
  title: string
}

export interface CompletionRateStatistics {
  plsWaybillNumber: string,
  orderNumber: string,
  deliveryDate: string,
  amount: number,
  accountId: number,
  serviceNumber: string
}

export interface InvoicesAndPaymentsDetails {
  accountId: number,
  number: number,
  date: string,
  amount: number,
}

export interface InvoicesAndPaymentsTotals {
  totalUnpaid: number,
  totalPaid: number
}

export interface ManagerProfitability {
  accountId: number;
  title: string;
  periodStart: string;
  periodEnd: string;
  totalBilling: number;
  totalServiceCost: number;
  totalServiceWeight: number;
}

export interface TotalManagerProfitability {
  totalBilling: number;
  totalServiceCost: number;
  totalServiceWeight: number;
}

export enum ManagerRefreshStateStatuses {
  NEW,
  RUNNING,
  DONE
}

export interface ManagerRefreshState {
  managerId: number,
  status: ManagerRefreshStateStatuses
}

export interface SetTotalManagerProfitability {
  type: typeof SET_TOTAL_MANAGER_PROFITABILITY,
  payload: TotalManagerProfitability
}

export interface SetManagerProfitabilityDetails {
  type: typeof SET_MANAGER_PROFITABILITY_DETAILS,
  payload: {
    items: Profitability[],
    count: number
  }
}

export interface SetManagerProfitability {
  type: typeof SET_MANAGER_PROFITABILITY,
  payload: ManagerProfitability[]
}

export interface SetInvoicesAndPaymentsDetails {
  type: typeof SET_INVOICES_AND_PAYMENTS_DETAILS,
  payload: InvoicesAndPaymentsDetails[]
}

export interface SetInvoicesAndPaymentsTotals {
  type: typeof SET_INVOICES_AND_PAYMENTS_TOTALS,
  payload: InvoicesAndPaymentsTotals
}

export interface SetSalaryManagerList {
  type: typeof SET_SALARY_MANAGER_LIST,
  payload: SalaryManager[],
}

export interface SetSalaryManager {
  type: typeof SET_SALARY_MANAGER,
  payload: SalaryManager,
}

export interface UpdateSalaryManager {
  type: typeof UPDATE_SALARY_MANAGER,
  payload: SalaryManager
}

export interface SetManagerWorkPeriodList {
  type: typeof SET_MANAGER_WORK_PERIOD_LIST,
  payload: WorkPeriod[],
}

export interface SetCompletionRateClientList {
  type: typeof SET_COMPLETION_RATE_CLIENT_LIST,
  payload: CompletionRateClient[],
}

export interface SetCompletionRateStatisticsList {
  type: typeof SET_COMPLETION_RATE_STATISTICS_LIST,
  payload: CompletionRateStatistics[],
}

export interface SetInvoicesAndPaymentsStatisticsList {
  type: typeof SET_INVOICES_AND_PAYMENTS_STATISTICS_LIST,
  payload: InvoicesAndPaymentsStatistics[],
}

export interface UpdateWorkPeriod {
  type: typeof UPDATE_WORK_PERIOD,
  payload: WorkPeriod
}

export interface SetManagerRefreshState {
  type: typeof SET_MANAGER_REFRESH_STATE,
  payload: ManagerRefreshState
}

export interface ResetSalaryStat {
  type: typeof RESET_SALARY_STAT,
}

export interface ResetSalaryManager {
  type: typeof RESET_SALARY_MANAGER,
}

export interface ResetSalaryDetails {
  type: typeof RESET_SALARY_DETAILS
}

export type SalaryActions = SetSalaryManagerList
| SetSalaryManager
| ResetSalaryStat
| ResetSalaryManager
| ResetSalaryDetails
| UpdateSalaryManager
| SetManagerWorkPeriodList
| SetCompletionRateStatisticsList
| SetInvoicesAndPaymentsStatisticsList
| UpdateWorkPeriod
| SetCompletionRateClientList
| SetInvoicesAndPaymentsTotals
| SetInvoicesAndPaymentsDetails
| SetManagerProfitability
| SetManagerProfitabilityDetails
| SetTotalManagerProfitability
| SetManagerRefreshState;
