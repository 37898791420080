export const SET_CLIENT_ACTIVE_STATISTIC = 'SET_CLIENT_ACTIVE_STATISTIC';
export const SET_TOTAL_WAYBILL_STAT = 'SET_TOTAL_WAYBILL_STAT';
export const SET_WAYBILL_BASE_STAT = 'SET_WAYBILL_BASE_STAT';


export type WaybillStat = {
  date: string,
  count: number,
  sum: number,
};

export type ClientActiveSeries = {
  date: string,
  newShippingCount: number,
  activeCount: number,
};

export type TotalWaybillStat = {
  date: string,
  count: number,
};

export type WaybillBaseStat = {
  manualAmount: number,
  billingAmount: number,
  waybillAmount: number,
};

export interface SetClientActiveStatistic {
  type: typeof SET_CLIENT_ACTIVE_STATISTIC,
  statistic: ClientActiveSeries[],
}

export interface SetTotalWaybillStat {
  type: typeof SET_TOTAL_WAYBILL_STAT,
  statistic: TotalWaybillStat[],
}

export interface SetWaybillBaseStat {
  type: typeof SET_WAYBILL_BASE_STAT,
  statistic: WaybillBaseStat,
}

export type StatisticsActions = SetClientActiveStatistic | SetTotalWaybillStat | SetWaybillBaseStat;
