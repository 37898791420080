import Cookie from 'js-cookie';
import {getSellerList} from '../reference/action-creators';
import websocket from '../../utils/websocket';
import {loadStoreItems} from '../marketing/action-creators';

export const setUser = (user) => ({
  type: 'SET_USER',
  user,
});

export const setToken = (accessToken, refreshToken, expiredAt, remember) => ({
  type: 'SET_TOKEN',
  accessToken,
  refreshToken,
  expiredAt,
  remember,
});

export const setCent = (cent) => ({
  type: 'SET_CENT',
  cent,
})

export const getCurrentUserData = () => (dispatch, getState, http) => {
  const accessToken = localStorage.getItem('accessToken');
  console.debug(Cookie.get());
  if (accessToken && accessToken !== 'NaN' && accessToken !== 'null') {
    return http.get('/api/v1/user/manager/detail/')
      .then(
        (response) => {
          dispatch(setUser(response));
          dispatch(getSellerList());
          dispatch(websocket());
          dispatch(loadStoreItems());
          // setTimeout(() => {
          //   dispatch(refreshCurrentToken());
          // }, 5000);
          return Promise.resolve(response);
        },
        (reject) => Promise.reject(reject),
      );
  }
  return Promise.reject();
};

const refreshCurrentToken = () => (dispatch, getState, http) => {
  const {token} = getState().users;
  http.post('/api/v1/user/token_refresh/', {refresh_token: token.refreshToken}, {NO_AUTH: true}).then(
    (response) => {
      const {accessToken, refreshToken} = response;
      const expiredAt = new Date().getTime() + 300000;
      dispatch(setToken(accessToken, refreshToken, expiredAt));
      setTimeout(() => {
        dispatch(refreshCurrentToken());
      }, 180000);
    },
    () => {
      dispatch(signOut());
    },
  );
};

export const authorizeUser = (username, password, remember = true) => (dispatch, getState, http) => http.post('/api/v1/user/get_token/', {
  username,
  password
})
  .then(
    (response) => {
      const {accessToken, refreshToken} = response;
      const expiredAt = new Date().getTime() + 300000;
      dispatch(setToken(accessToken, refreshToken, expiredAt, remember));
      return dispatch(getCurrentUserData());
    },
    (reject) => Promise.reject(reject),
  );

export const signOut = () => (dispatch, getState) => {
  const {cent} = getState().users;
  if (cent) cent.disconnect();
  dispatch(setCent(null));
  Cookie.remove('crmToken');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('tokenExpired');

  dispatch(setUser(null));
  dispatch(setToken(null));
};
