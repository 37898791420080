import { createBrowserHistory } from 'history';
import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import accounts from './app/accounts/reducer';
import clientBills from './app/accounting/client_bills/reducer';
import companyBills from './app/accounting/company_bills/reducer';
import acts from './app/accounting/acts/reducer';
import transaction from './app/accounting/transaction/reducer';
import clientEvents from './app/notifications/client_event/reducer';
import shipping from './app/shipping/reducer';
import shipping_old from './app/shipping_old/reducer';
import reconcillation from './app/accounting/reconcillation/reducer';
import detailing from './app/accounting/detailing/reducer';
import statistics from './app/statistics/reducer';
import supports from './app/support/reducer';
import users from './app/users/reducer';
import contracts from './app/contracts/reducer';
import chat from './app/chat/reducer';
import marketing from './app/marketing/reducer';
import reference from './app/reference/reducer';
import tender from './app/tender/reducer';
import notifications from './app/notifications/reducer';
import salary from './app/salary/reducer';
import profitability from './app/profitability/reducer';
import failedBilling from './app/failedBilling/reducer';
import downgradedOrders from './app/downgradedOrders/reducer';

const history = createBrowserHistory();

const rootReducer = combineReducers({
  accounts,
  acts,
  transaction,
  clientBills,
  clientEvents,
  companyBills,
  shipping,
  statistics,
  supports,
  detailing,
  reconcillation,
  users,
  contracts,
  chat,
  marketing,
  reference,
  shipping_old,
  tender,
  notifications,
  salary,
  profitability,
  failedBilling,
  downgradedOrders,
  router: connectRouter(history),
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
export { history };
