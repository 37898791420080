import React from 'react';
import { Menu } from 'antd';
import { growthDescription } from '../constants';
import { SelectParam } from 'antd/lib/menu';

interface Props {
    value?: string,
    onChange: (value: string) => void,
}

function GrowthStatisticsField({
    value,
    onChange 
}: Props) {

  const handleChange = (data: SelectParam) => {
    onChange(data.key);
  };

  return (
    <Menu
      selectedKeys={value ? [value] : []}
      onSelect={handleChange}
      onDeselect={handleChange}
      style={{ margin: '-16px', border: 'none' }}
    >
      <Menu.Item key={'increased'}>
        {growthDescription.increased}
      </Menu.Item>
      <Menu.Item key={'reduced'}>
        {growthDescription.reduced}
      </Menu.Item>
      <Menu.Item key={'gone'}>
        {growthDescription.gone}
      </Menu.Item>
    </Menu>
  );
}

export default GrowthStatisticsField;