import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  Card, Col, Row, Table,
} from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router';
import { USER_DATE_FORMAT, WAYBILL_STATUSES } from '../../../utils/constants';
import waybillStatus from '../constants';
import { getWaybillRateNameListing } from '../helpers';

function getNumber(record) {
  let statusData = null;
  if (WAYBILL_STATUSES.hasOwnProperty(record.state)) statusData = WAYBILL_STATUSES[record.state];

  return (
    <Row>
      <Col span={24}>
        <span className="gx-nonhover gx-mr-2 gx-pr-1">
          <i className={`icon icon-circle gx-fs-sm gx-text-${statusData ? statusData.color : 'gray'}`} />
        </span>
        {record.serviceNumber || record.plsNumber}
      </Col>
      <Col span={24}>

        <span className="gx-nonhover gx-mr-3 gx-pr-2" />
        {record.serviceOrderNumber ? record.serviceOrderNumber : ''}
      </Col>
    </Row>
  );
}

function ShippingTable({ accountId, loading }) {
  const history = useHistory();
  const { deliveryServices } = useSelector((state) => state.reference);
  const waybillsList = useSelector((state) => state.shipping.waybillsList);

  const getStatus = (waybill) => {
    const statusItem = waybillStatus.filter((itemWaybillStatus) => waybill.state === itemWaybillStatus.id)[0];
    if (statusItem) {
      return typeof statusItem.title === 'function'
        ? (waybill.stateData ? statusItem.title(JSON.parse(waybill.stateData)) : statusItem.title({}))
        : statusItem.title;
    }
  };

  const handleRowClick = useCallback((record) => {
    if (accountId) {
      history.push(`/accounts/${accountId}/shipping/${record.plsNumber}`);
    } else {
      history.push(`/shipping/${record.plsNumber}`);
    }
  }, [accountId]);

  const columns = [
    {
      className: 'gx-text-left',
      title: 'Номер накладной и вызова курьера',
      dataIndex: 'bill',
      key: 'bill',
      render: (text, record) => getNumber(record),
    },
    {
      title: 'Статус',
      key: 'status',
      render: (text, record) => (
        <>
          {getStatus(record)}
        </>
      ),
    },
    {
      className: 'gx-text-left',
      title: 'Клиент',
      dataIndex: 'accountTitle',
      key: 'accountTitle',
    },
    {
      title: 'Перевозчик',
      dataIndex: 'deliveryService',
      key: 'deliveryService',
      render: (text) => <img src={deliveryServices[text]?.icon} alt={text} className="delivery_service-logo" />,
    },
    {
      className: 'gx-text-left',
      title: 'Тариф',
      dataIndex: 'rate',
      key: 'rate',
      render: (text, record) => getWaybillRateNameListing(record),
    },
    {
      className: 'gx-text-left',
      title: 'Сбор',
      dataIndex: 'senderCity',
      key: 'senderCity',
    },
    {
      className: 'gx-text-left',
      title: 'Доставка',
      dataIndex: 'receiverCity',
      key: 'receiverCity',
    },
    {
      className: 'gx-text-left',
      title: 'Дата создания',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => (text ? moment(text).format(USER_DATE_FORMAT) : ''),
    },
    {
      className: 'gx-text-left',
      title: 'Стоимость',
      dataIndex: 'cost',
      key: 'cost',
    },
  ];

  return (
    <Card>
      <Table
        style={{ margin: '-24px' }}
        className="gx-table-responsive"
        columns={columns}
        rowClassName={() => 'gx-pointer'}
        onRowClick={handleRowClick}
        loading={loading}
        dataSource={waybillsList}
        pagination={false}
      />
    </Card>
  );
}

export default ShippingTable;
