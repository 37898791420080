import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const AccountDialogDetail = React.lazy(() => import('../components/AccountDialogDetail'));

function AccountDialogDetailView(props: any) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <AccountDialogDetail {...props} />
    </Suspense>
  );
}

export default AccountDialogDetailView;
