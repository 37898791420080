import React from 'react';
import CircularProgress from '../../../components/CircularProgress';

const TenderCalculation = React.lazy(() => import('../components/TenderCalculation'));

function TenderCalculationView(props: any) {
  return (
    <React.Suspense fallback={<CircularProgress />}>
      <TenderCalculation {...props}/>
    </React.Suspense>
  );
}

export default TenderCalculationView;
