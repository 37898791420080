import Cookie from 'js-cookie';
import { User, Token } from './types';
import Centrifuge from 'centrifuge';

export interface userState {
  user: User | null,
  token: Token | null,
  cent: null | Centrifuge
}

const initialState: userState = {
  user: null,
  token: null,
  cent: null,
};

export default function (state = initialState, action: any): userState {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, user: action.user };
    case 'SET_TOKEN':
      if (action.remember) {
        localStorage.setItem('refreshToken', action.refreshToken);
      }
      Cookie.set('crmToken', action.accessToken);
      localStorage.setItem('accessToken', action.accessToken);
      localStorage.setItem('tokenExpire', action.expiredAt);

      return {
        ...state,
        token: {
          accessToken: action.accessToken,
          refreshToken: action.refreshToken,
          expiredAt: action.expiredAt,
        },
      };
    case 'SET_CENT':
      return { ...state, cent: action.cent };
    default:
      return state;
  }
}
