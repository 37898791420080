import React, { Component } from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import DateRangeField from 'components/form/DateRangeField';
import { withQueryParams } from 'helpers';
import List from 'components/List';
import { Link } from 'react-router-dom';
import Menu from 'antd/es/menu';
import AccountStatusField from '../components/AccountStatusField';
import AccountStatus from '../components/AccountStatus';
import AccountTable from '../components/AccountTable';
import {
  loadAccounts,
} from '../action-creators';
import { SERVER_DATE_FORMAT, USER_DATE_FORMAT } from '../../../utils/constants';
import AccountSellerField, { AccountSeller } from '../../../components/filters/AccountSellerField';
import { growthDescription } from '../constants';
import GrowthStatisticsField from '../components/GrowthStatusField';
import ManagerSelectField from '../components/ManagerSelectField';

const QUERY_PARAMS_CONFIG = {
  query: { type: 'string' },
  page: { type: 'pageNumber' },
  status: { type: 'array' },
  createdAtFrom: { type: 'date' },
  createdAtTo: { type: 'date' },
  sellerId: { type: 'array' },
  originalContract: { type: 'string' },
  growthType: { type: 'string' },
  preferredManager: { type: 'string' }
};

const OriginalContractAvailableFilter = ({ value, onChange }) => (
  <Menu multiple style={{ margin: '-16px', border: 'none' }} selectedKeys={value ? [value] : []} onSelect={({ key }) => onChange(key)} onDeselect={() => onChange()}>
    <Menu.Item key="AVAILABLE">
      <span>Оригиналы в наличии</span>
    </Menu.Item>
    <Menu.Item key="NOT_AVAILABLE">
      <span>Оригиналы в отсутствуют</span>
    </Menu.Item>
  </Menu>
);

class AccountView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.loadAccounts();
  }

  setLoading = (loading) => this.setState({ loading });

  loadAccounts = () => {
    const {
      params: {
        query, status, page, createdAtFrom, createdAtTo, sellerId, originalContract, growthType, preferredManager
      },
    } = this.props;
    this.setLoading(true);
    this.props.loadAccounts(
      query,
      page,
      status,
      createdAtFrom?.format(SERVER_DATE_FORMAT),
      createdAtTo?.format(SERVER_DATE_FORMAT),
      sellerId,
      originalContract,
      growthType,
      preferredManager,
    ).finally(
      () => { this.setLoading(false); },
    );
  };

  handlePageChange = (page) => this.props.setFieldValue({ page }, this.loadAccounts);

  handleSearchChange = (query) => this.props.setFieldValue({ query, page: 1 }, this.loadAccounts);

  handleCreatedAtChange = ([createdAtFrom, createdAtTo]) => this.props.setFieldValue({ createdAtFrom, createdAtTo, page: 1 }, this.loadAccounts);

  handleCreatedAtFromClean = () => this.props.setFieldValue({ createdAtFrom: null, page: 1 }, this.loadAccounts);

  handleCreatedAtToClean = () => this.props.setFieldValue({ createdAtTo: null, page: 1 }, this.loadAccounts);

  handleStatusChange = (status) => this.props.setFieldValue({ status, page: 1 }, this.loadAccounts);

  handleSellerChange = (sellerId) => this.props.setFieldValue({ sellerId, page: 1 }, this.loadAccounts);

  handleOriginalContracts = (originalContract) => this.props.setFieldValue({ originalContract, page: 1 }, this.loadAccounts);

  handleChangeGrowthFilter = (growthType) => this.props.setFieldValue({ growthType, page: 1 }, this.loadAccounts);

  handleChangeManagerFilter = (preferredManager) => this.props.setFieldValue({ preferredManager, page: 1 }, this.loadAccounts);

  handleStatusDeselect = (statusKey) => {
    this.props.setFieldValue(
      { status: this.props.params.status.filter((s) => s !== statusKey), page: 1 },
      this.loadAccounts,
    );
  };

  handleSellerDeselect = (sellerId) => {
    this.props.setFieldValue(
      { sellerId: this.props.params.sellerId.filter((s) => s !== sellerId), page: 1 },
      this.loadAccounts,
    );
  };

  getFilters = () => {
    const {
      params: {
        status, createdAtFrom, createdAtTo, sellerId, originalContract, growthType, preferredManager
      },
    } = this.props;
    return [
      {
        title: 'Дата договора',
        Component: DateRangeField,
        value: [createdAtFrom, createdAtTo],
        onChange: this.handleCreatedAtChange,
        componentProps: {
          fromPlaceholder: 'Дата регистрации с',
          toPlaceholder: 'Дата регистрации по',
        },
      },
      {
        title: 'Статус',
        Component: AccountStatusField,
        value: status,
        onChange: this.handleStatusChange,
      },
      {
        title: 'Продавец',
        Component: AccountSellerField,
        value: sellerId,
        onChange: this.handleSellerChange,
      },
      {
        title: 'Оригиналы документов',
        Component: OriginalContractAvailableFilter,
        value: originalContract,
        onChange: this.handleOriginalContracts,
      },
      {
        title: 'Статистика',
        Component: GrowthStatisticsField,
        value: growthType,
        onChange: this.handleChangeGrowthFilter,
      },
      {
        title: 'Менеджеры',
        Component: ManagerSelectField,
        value: preferredManager,
        onChange: this.handleChangeManagerFilter,
      },
    ];
  };

  getTags = () => {
    const {
      params: {
        status, createdAtFrom, createdAtTo, sellerId, originalContract, growthType, preferredManager
      },
    } = this.props;
    const tags = [];

    if (createdAtFrom) {
      tags.push({
        onClose: this.handleCreatedAtFromClean,
        content: <span>Дата договора: c {createdAtFrom.format(USER_DATE_FORMAT)}</span>,
      });
    }
    if (createdAtTo) {
      tags.push({
        onClose: this.handleCreatedAtToClean,
        content: <span>Дата договора: по {createdAtTo.format(USER_DATE_FORMAT)}</span>,
      });
    }
    if (status && status.length) {
      status.forEach((statusKey) => {
        tags.push({
          onClose: () => this.handleStatusDeselect(statusKey),
          content: <span><AccountStatus code={statusKey} /></span>,
        });
      });
    }
    if (sellerId?.length) {
      sellerId.forEach((seller) => {
        tags.push({
          onClose: () => this.handleSellerDeselect(seller),
          content: <AccountSeller sellerId={seller} />,
        });
      });
    }

    if (originalContract === 'AVAILABLE') {
      tags.push({
        onClose: () => this.handleOriginalContracts(),
        content: <span>Оригиналы в наличии</span>,
      });
    } else if (originalContract === 'NOT_AVAILABLE') {
      tags.push({
        onClose: () => this.handleOriginalContracts(),
        content: <span>Оригиналы в отсутствуют</span>,
      });
    }

    if (growthType) {
      tags.push({
        onClose: () => this.handleChangeGrowthFilter(null),
        content: <span>{growthDescription[growthType]}</span>,
      });
    }

    if (preferredManager) {
      tags.push({
        onClose: () => this.handleChangeManagerFilter(null),
        content: <span>Менеджер: {preferredManager}</span>,
      });
    }

    return tags;
  };

  handleChartClick = () => {
    this.props.history.push('/accounts/chart');
  };

  render() {
    const { params: { query, page }, accountsCount, user } = this.props;
    const { loading } = this.state;
    return (
      <List
        title="Клиенты"
        totalCount={accountsCount}
        currentPage={page}
        handlePageChange={this.handlePageChange}
        searchValue={query}
        searchPlaceholder="Введите название компании"
        onSearchChange={this.handleSearchChange}
        filters={this.getFilters()}
        tags={this.getTags()}
        loading={loading}
        withGraphMode
        onChartClick={this.handleChartClick}
        addon={(
          <div className="gx-form-group">
            <Link to="/contracts/new">
              <Button className="gx-w-100" type="primary" ghost style={{ margin: '0' }}>
                Новый договор
              </Button>
            </Link>
          </div>
        )}
      >
        <AccountTable loading={loading} />
      </List>
    );
  }
}

export default connect(
  ({ accounts: { accountsCount }, users: { user } }) => ({ accountsCount, user }),
  { loadAccounts },
)(withQueryParams(QUERY_PARAMS_CONFIG)(AccountView));
