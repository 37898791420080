import React, { useCallback } from 'react';
import {
  Modal, Spin, Table, Upload, Divider, Button, Icon, InputNumber,
} from 'antd';
import {
  Form, Formik, FormikProps,
} from 'formik';
import * as XLSX from 'xlsx';
import classNames from 'classnames';
import { RcFile } from 'antd/lib/upload/interface';
import useDispatch from '../../../utils/hooks/useDispatch';
import useSelector from '../../../utils/hooks/useSelector';
import { approveParsedImportData, fetchParsedImportData, resetCurrentImport, startImport, updateParsedWaybillData } from '../action-creators';
import { CurrentImportState } from '../types';
import { MAX_FILE_UPLOAD_SIZE } from '../../../utils/constants';
import { isNumber } from 'lodash';
import { ColumnProps } from 'antd/lib/table';
import ProfitabilityImportSelect from './ProfitabilityColumnSelect';

interface Props {
  isOpened: boolean
  onClose: () => void
}

function ProfitabilityImportModal({ isOpened, onClose }: Props) {
  const dispatch = useDispatch();
  const { currentImportState, currentImportId, parsedImportItems, parsedImportCount } = useSelector((state) => state.profitability);

  const [processing, setProcessing] = React.useState(false);
  const [file, setFile] = React.useState<null | RcFile>(null);
  const [approvedWaybills, setApprovedWaybills] = React.useState<string[]>([]);
  const [isFilePreview, setIsFilePreview] = React.useState<boolean>(false);
  const [editingWaybill, setEditingWaybill] = React.useState<{
    plsWaybillNumber: string,
    serviceCost: number,
    serviceWeight: number
  } | null>(null);
  const [columnsData, setColumnsData] = React.useState<{
    serviceWaybillNumberCol: number | null,
    serviceCostCol: number | null,
    serviceWeightCol: number | null,
    serviceRateCol: number | null,
  }>({
    serviceWaybillNumberCol: null,
    serviceCostCol: null,
    serviceWeightCol: null,
    serviceRateCol: null
  });
  const [columns, setColumns] = React.useState<any[]>([]);
  const [data, setData] = React.useState<any[]>([]);
  const formRef = React.useRef<null | FormikProps<any>>(null);


  const processData = (dataString: string) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
 
    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"')
              d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"')
              d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            //@ts-ignore
            obj[headers[j]] = d;
          }
        }
 
        // remove the blank rows
        if (Object.values(obj).filter(x => x).length > 0) {
          list.push(obj);
        }
      }
    }
 
    // prepare columns list from headers
    const columns = headers.map(c => ({
      name: c,
      selector: c,
    }));
 
    setData(list);
    setColumns(columns);
  }

  const handleAddFile = useCallback((file: RcFile) => {
    if (file.size > MAX_FILE_UPLOAD_SIZE) {
      if (formRef.current) {
        formRef.current.setErrors({ file: `Файл не может быть больше ${MAX_FILE_UPLOAD_SIZE / 1024 / 1024} МБ` });
      }
      return false;
    }
    setFile(file);
    if (formRef.current) {
      formRef.current?.setErrors({ file: undefined });
    }
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target?.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      //@ts-ignore
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      processData(data);
    };
    reader.readAsBinaryString(file);
    return false;
  }, [dispatch, formRef]);

  const handleSubmit = React.useCallback(
    ({}, actions) => {
      const { serviceWaybillNumberCol, serviceCostCol, serviceWeightCol, serviceRateCol } = columnsData;
      if (!!file && isNumber(serviceWaybillNumberCol) && isNumber(serviceCostCol) && isNumber(serviceWeightCol) && isNumber(serviceRateCol)) {
        dispatch(startImport(file, serviceWaybillNumberCol, serviceCostCol, serviceWeightCol, serviceRateCol)).then(
          () => {
          },
          (newErrors: anyObject) => actions.setErrors(newErrors),
        ).finally(() => actions.setSubmitting(false));
      }
    },
    [file, columnsData],
  );

  const handleSave = React.useCallback(
    () => {
      if (approvedWaybills.length) {
        dispatch(approveParsedImportData(currentImportId, approvedWaybills)).finally(() => onClose());
      }
    },
    [approvedWaybills, currentImportId],
  );

  const handleSaveWaybill = React.useCallback(
    () => {
      if (editingWaybill && isNumber(editingWaybill.serviceCost) && isNumber(editingWaybill.serviceWeight)) {
        dispatch(updateParsedWaybillData(currentImportId, editingWaybill.plsWaybillNumber, editingWaybill.serviceCost, editingWaybill.serviceWeight)).finally(() => setEditingWaybill(null));
      }
    },
    [editingWaybill],
  );

  React.useEffect(() => () => {
    dispatch(resetCurrentImport());
  }, []);

  React.useEffect(() => {
    if (currentImportState === CurrentImportState.RUNNING) setProcessing(true);
  }, [currentImportState]);

  const handleWaybillDataChange = (key: string, value: number | undefined) => {
    if (editingWaybill)
    setEditingWaybill({
      ...editingWaybill,
      [key]: value || 0
    })
  }

  const handleColumnsDataChange = (key: string, value: number) => {
    setColumnsData({
      ...columnsData,
      [key]: isNumber(value) ? value : null
    })
  }

  const parsedSource = React.useMemo(() => {
    return parsedImportItems.map(item => ({
      key: item.serviceWaybillNumber,
      ...item
    }))
  }, [parsedImportItems]);

  const columnsParsed: ColumnProps<any>[] = React.useMemo(() => {
    return columns.map(i => {
      return (
        {
          title: i.name,
          //@ts-ignore
          render: (_, row) => (row[i.name]),
        }
      )
    })
  }, [columns]);

  const availableColumns: {key: string, value: number}[] = React.useMemo(() => {
    return columns.map((item, index) => {
      return ({
        key: item.name,
        value: index
      })
    });
  }, [columns]);

  const getBody = React.useCallback(
    (errors: anyObject, isSubmitting) => {
      if (parsedSource.length) {
        return (
          <Form>
            <Table
              pagination={{
                total: parsedImportCount,
                pageSize: 50,
                onChange: (page: number) => {
                  dispatch(fetchParsedImportData(currentImportId, page))
                }
              }}
              dataSource={parsedSource}
              rowSelection={{
                selectedRowKeys: approvedWaybills,
                onChange: ((selected) => setApprovedWaybills(selected as string[])),
              }}
              className="gx-table-responsive"
              columns={[
                {
                  title: `Внутренний номер`,
                  render: (_, row) => (row.plsWaybillNumber),
                },
                {
                  title: `Номер накладной`,
                  render: (_, row) => (row.serviceWaybillNumber),
                },
                {
                  title: `Отправитель`,
                  render: (_, row) => (row.citySender),
                },
                {
                  title: `Получатель`,
                  render: (_, row) => (row.cityReceiver),
                },
                {
                  title: `Тариф в курьерке`,
                  render: (_, row) => (row.serviceRate),
                },
                {
                  title: `Предыдущий тариф`,
                  render: (_, row) => (row.prevServiceRate || "нет данных"),
                },
                {
                  title: `Стоимость в курьерке`,
                  render: (_, row) => {
                    if (row.plsWaybillNumber === editingWaybill?.plsWaybillNumber) {
                      return (
                        <InputNumber value={editingWaybill.serviceCost} placeholder='Цена в курьерке' onChange={(e) => handleWaybillDataChange('serviceCost', e)} className='gx-w-100'/>
                      )
                    }
                    return isNumber(row.serviceCost) ? row.serviceCost.toFixed(2) : 0
                  },
                },
                {
                  title: `Предыдущая стоимость`,
                  render: (_, row) => {
                    if (row.prevServiceCost) {
                      return row.prevServiceCost.toFixed(2)
                    }
                    return "нет данных"
                  },
                },
                {
                  title: `Вес к оплате (кг)`,
                  render: (_, row) => {
                    if (row.plsWaybillNumber === editingWaybill?.plsWaybillNumber) {
                      return (
                        <InputNumber value={editingWaybill.serviceWeight} placeholder='Вес к оплате (кг)' onChange={(e) => handleWaybillDataChange('serviceWeight', e)} className='gx-w-100'/>
                      )
                    }
                    return isNumber(row.serviceWeight) ? row.serviceWeight.toFixed(2) : 0
                  },
                },
                {
                  title: `Предыдущий вес (кг)`,
                  render: (_, row) => {
                    if (row.prevServiceWeight) {
                      return row.prevServiceWeight.toFixed(2)
                    }
                    return "нет данных"
                  },
                },
                {
                  title: `Редактировать`,
                  render: (_, row) => {
                    if (!editingWaybill) {
                      return (
                        <Button type="primary" onClick={() => setEditingWaybill({
                          plsWaybillNumber: row.plsWaybillNumber,
                          serviceCost: row.serviceCost,
                          serviceWeight: row.serviceWeight})}>
                          Редактировать
                        </Button>
                      )
                    }
                    if (row.plsWaybillNumber === editingWaybill.plsWaybillNumber) {
                      return (
                        <Button type="primary" onClick={handleSaveWaybill}>
                          Сохранить
                        </Button>
                      )
                    }
                    return null;
                  }
                }
              ]}
            />
            <div className="gx-text-right gx-px-3 gx-mt-3">
              <Button onClick={onClose}>
                Отменить
              </Button>
              <Button loading={isSubmitting} type="primary" htmlType="submit">
                Сохранить
              </Button>
            </div>
          </Form>
        );
      }

      if (isFilePreview && columnsParsed.length && data.length) {
        return (
          <Spin
            spinning={processing}
            tip="Обрабатываем ваш файл, пожалуйста подождите"
          >
            <Form>
              <ProfitabilityImportSelect
                title='Номер накладной'
                availableColumns={availableColumns}
                selected={isNumber(columnsData.serviceWaybillNumberCol) ? columnsData.serviceWaybillNumberCol : undefined}
                onChange={(value: number) => handleColumnsDataChange('serviceWaybillNumberCol', value)}
              />
              <ProfitabilityImportSelect
                title='Стоимость в курьерке'
                availableColumns={availableColumns}
                selected={isNumber(columnsData.serviceCostCol) ? columnsData.serviceCostCol : undefined}
                onChange={(value: number) => handleColumnsDataChange('serviceCostCol', value)}
              />
              <ProfitabilityImportSelect
                title='Вес к оплате'
                availableColumns={availableColumns}
                selected={isNumber(columnsData.serviceWeightCol) ? columnsData.serviceWeightCol : undefined}
                onChange={(value: number) => handleColumnsDataChange('serviceWeightCol', value)}
              />
              <ProfitabilityImportSelect
                title='Название тарифа в курьерке'
                availableColumns={availableColumns}
                selected={isNumber(columnsData.serviceRateCol) ? columnsData.serviceRateCol : undefined}
                onChange={(value: number) => handleColumnsDataChange('serviceRateCol', value)}
              />
              <Table
                dataSource={data}
                columns={columnsParsed}
                className="gx-table-responsive"
              />
              <div className="gx-text-right gx-px-3 gx-mt-3">
                <Button onClick={onClose}>
                  Отменить
                </Button>
                <Button loading={isSubmitting} type="primary" htmlType="submit">
                  Продолжить
                </Button>
              </div>
            </Form>
          </Spin>
        )
      }

      return (
        <Spin
          spinning={processing}
          tip="Обрабатываем ваш файл, пожалуйста подождите"
        >
          <Form>
            <div className="gx-px-3 gx-pb-3">
              <Upload.Dragger
                accept=".csv,.xls,.xlsx"
                name="file"
                beforeUpload={handleAddFile}
                fileList={file ? [file] : undefined}
                showUploadList={false}
                className={errors?.file ? 'gx-border-danger' : ''}
              >
                <p className="ant-upload-drag-icon">
                  <Icon type="upload"/>
                </p>
                {file ? (
                  <p className={classNames('ant-upload-text', { 'gx-text-danger': errors?.file })}>
                    {file.name}
                  </p>
                ) : (
                  <p className={classNames('ant-upload-text', { 'gx-text-danger': errors?.file })}>
                    Перетащите файл или нажмите на эту область
                  </p>
                )}
              </Upload.Dragger>
              {errors?.file && (
                <div className="gx-text-danger">
                  {errors.file}
                </div>
              )}
            </div>
            <Divider />
            <div className="gx-text-right gx-px-3">
              <Button onClick={onClose}>
                Отмена
              </Button>
              <Button onClick={() => setIsFilePreview(true)} type="primary" htmlType="button">
                Открыть
              </Button>
            </div>
          </Form>

        </Spin>
      );
    },
    [processing, file, handleAddFile, parsedSource, currentImportState, currentImportId,
      onClose, isFilePreview, columnsParsed, data, availableColumns, columnsData, approvedWaybills, editingWaybill, parsedImportCount],
  );

  return (
    <Formik
      initialValues={{ file: null }}
      onSubmit={currentImportState === CurrentImportState.DONE && parsedImportCount ? handleSave : handleSubmit}
      innerRef={(el) => formRef.current = el}
    >
      {({ submitForm, isSubmitting, errors }) => (
        <Modal
          visible={isOpened}
          confirmLoading={isSubmitting}
          title="Добавить из файла"
          // onOk={submitForm}
          className="ant-modal_full-width ant-modal_paddingless gx-position-relative"
          style={{overflowX: 'hidden'}}
          footer={null}
          onCancel={onClose}
        >
          {getBody(errors, isSubmitting)}
        </Modal>
      )}
    </Formik>
  );
}

export default ProfitabilityImportModal;
