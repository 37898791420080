export default function pluralize(number, partsRaw) {
  const parts = partsRaw.split(',');
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return parts[2];
  }
  n %= 10;
  if (n === 1) {
    return parts[0];
  }
  if (n >= 2 && n <= 4) {
    return parts[1];
  }
  return parts[2];
}
