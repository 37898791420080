import React from 'react';
import ReactDOM from 'react-dom';
import 'moment/locale/ru';
import moment from 'moment';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';

import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import ConfigProvider from 'antd/es/config-provider';
import ruLocale from 'antd/es/locale/ru_RU';
import { YMaps } from 'geo';
import { Worker } from '@phuocng/react-pdf-viewer';
import App from './App';
import { history } from './reducers';
import configureStore from './store';
import httpService from './utils/httpService';
import { setToken } from './app/users/action-creators';

moment.locale('ru');
const store = configureStore({}, httpService);

const accessToken = localStorage.getItem('accessToken');
const refreshToken = localStorage.getItem('refreshToken');
let expiredAt = localStorage.getItem('tokenExpire');
if (expiredAt) expiredAt = parseInt(expiredAt, 10);

store.dispatch(setToken(accessToken, refreshToken, expiredAt, false));

// Wrap the rendering in a function:
const render = (Component) => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.3.200/pdf.worker.min.js">
        <YMaps query={{ apikey: 'c7512878-bbda-455d-bd8f-733206352d59' }} preload>
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <ConfigProvider locale={ruLocale}>
                <Component />
              </ConfigProvider>
            </ConnectedRouter>
          </Provider>
        </YMaps>
      </Worker>
    </AppContainer>,
    document.getElementById('root'),
  );
};

// Do this once
// registerServiceWorker();

// Render once
render(App);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./App', () => {
    render(App);
  });
}
