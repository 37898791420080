import {
  DeliveryService,
  Rate,
  Seller,
  SET_DELIVERY_SERVICES,
  SET_REFERENCE_RATE_LIST,
  SET_SELLER_LIST,
  SetDeliveryServices,
  SetReferenceRateList,
  SetSellerList,
} from './types';
import { ThunkAction } from '../../utils/types';

export const setDeliveryServices = (deliveryServices: Array<DeliveryService>): SetDeliveryServices => ({
  type: SET_DELIVERY_SERVICES,
  deliveryServices,
});

export const setSellerList = (sellers: Array<Seller>): SetSellerList => ({
  type: SET_SELLER_LIST,
  sellers,
});

export const setReferenceRateList = (list: Rate[]): SetReferenceRateList => ({
  type: SET_REFERENCE_RATE_LIST,
  list,
});

export const loadReferenceRateList = (): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/rates/list/', { load_all: true }).then(
  ({ items }: { items: Rate[]}) => {
    dispatch(setReferenceRateList(items));
  },
);

export const loadDeliveryServices = (): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/rates/delivery_services/').then(
  (response: { deliveryServices: Array<DeliveryService> }) => {
    dispatch(setDeliveryServices(response.deliveryServices));
    dispatch(loadReferenceRateList());
    return Promise.resolve(response);
  },
);

export const getSellerList = (): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/contracts/sellers/').then(
  ({ list }: { list: Array<Seller> }) => {
    dispatch(setSellerList(list));
    return Promise.resolve();
  },
);

export const getRateList = (deliveryService?: string | null, query?: string | null): ThunkAction => (dispatch, getState, http) => {
  const params: any = {};

  if (deliveryService) params.delivery_service = deliveryService;
  if (query) params.query = query;

  return http.get('/api/v1/rates/list/', params);
};
