import { adaptFromApi, adaptToApi } from '../../utils/adapter';
import { ThunkAction } from '../../utils/types';
import { Profitability } from '../profitability/types';
import {
  SET_MANAGER_WORK_PERIOD_LIST,
  SET_SALARY_MANAGER,
  SET_SALARY_MANAGER_LIST,
  RESET_SALARY_STAT,
  RESET_SALARY_MANAGER,
  SalaryManager,
  SetManagerWorkPeriodList,
  SetSalaryManager,
  SetSalaryManagerList,
  ResetSalaryStat,
  ResetSalaryManager,
  UpdateSalaryManager,
  UPDATE_SALARY_MANAGER,
  WorkPeriod,
  SET_COMPLETION_RATE_STATISTICS_LIST,
  SET_INVOICES_AND_PAYMENTS_STATISTICS_LIST,
  InvoicesAndPaymentsStatistics,
  CompletionRateStatistics,
  SetCompletionRateStatisticsList,
  SetInvoicesAndPaymentsStatisticsList,
  UpdateWorkPeriod,
  UPDATE_WORK_PERIOD,
  SetCompletionRateClientList,
  CompletionRateClient,
  SET_COMPLETION_RATE_CLIENT_LIST,
  InvoicesAndPaymentsTotals,
  SetInvoicesAndPaymentsTotals,
  SET_INVOICES_AND_PAYMENTS_TOTALS,
  SET_INVOICES_AND_PAYMENTS_DETAILS,
  SetInvoicesAndPaymentsDetails,
  InvoicesAndPaymentsDetails,
  ResetSalaryDetails,
  RESET_SALARY_DETAILS,
  SetManagerProfitability,
  ManagerProfitability,
  SET_MANAGER_PROFITABILITY,
  SetManagerProfitabilityDetails,
  SET_MANAGER_PROFITABILITY_DETAILS,
  SET_TOTAL_MANAGER_PROFITABILITY,
  SetTotalManagerProfitability,
  TotalManagerProfitability,
  SetManagerRefreshState,
  ManagerRefreshState,
  SET_MANAGER_REFRESH_STATE,
  ManagerRefreshStateStatuses,
  ProfitabilitySettingsItem,
} from './types';

export const setSalaryManagerList = (payload: Array<SalaryManager>): SetSalaryManagerList => ({
  type: SET_SALARY_MANAGER_LIST,
  payload,
});

export const setSalaryManager = (payload: SalaryManager): SetSalaryManager => ({
  type: SET_SALARY_MANAGER,
  payload,
});

export const setManagerWorkPeriodList = (payload: Array<WorkPeriod>): SetManagerWorkPeriodList => ({
  type: SET_MANAGER_WORK_PERIOD_LIST,
  payload,
});

export const setCompletionRateClientList = (payload: Array<CompletionRateClient>): SetCompletionRateClientList => ({
  type: SET_COMPLETION_RATE_CLIENT_LIST,
  payload,
});

export const setCompletionRateStatisticsList = (payload: Array<CompletionRateStatistics>): SetCompletionRateStatisticsList => ({
  type: SET_COMPLETION_RATE_STATISTICS_LIST,
  payload,
});

export const setInvoicesAndPaymentsStatisticsList = (payload: Array<InvoicesAndPaymentsStatistics>): SetInvoicesAndPaymentsStatisticsList => ({
  type: SET_INVOICES_AND_PAYMENTS_STATISTICS_LIST,
  payload,
});

export const setInvoicesAndPaymentsTotals = (payload: InvoicesAndPaymentsTotals): SetInvoicesAndPaymentsTotals => ({
  type: SET_INVOICES_AND_PAYMENTS_TOTALS,
  payload,
});

export const setInvoicesAndPaymentsDetails = (payload: InvoicesAndPaymentsDetails[]): SetInvoicesAndPaymentsDetails => ({
  type: SET_INVOICES_AND_PAYMENTS_DETAILS,
  payload,
});

export const updateSalaryManager = (payload: SalaryManager): UpdateSalaryManager => ({
  type: UPDATE_SALARY_MANAGER,
  payload,
});

export const updateWorkPeriod = (payload: WorkPeriod): UpdateWorkPeriod => ({
  type: UPDATE_WORK_PERIOD,
  payload,
});

export const setManagerProfitability = (payload: ManagerProfitability[]): SetManagerProfitability => ({
  type: SET_MANAGER_PROFITABILITY,
  payload,
});

export const setManagerProfitabilityDetails = (payload: { items: Profitability[], count: number }): SetManagerProfitabilityDetails => ({
  type: SET_MANAGER_PROFITABILITY_DETAILS,
  payload,
});

export const setTotalManagerProfitability = (payload: TotalManagerProfitability): SetTotalManagerProfitability => ({
  type: SET_TOTAL_MANAGER_PROFITABILITY,
  payload,
});

export const setManagerRefreshState = (payload: ManagerRefreshState): SetManagerRefreshState => ({
  type: SET_MANAGER_REFRESH_STATE,
  payload,
});

export const resetSalaryStat = (): ResetSalaryStat => ({
  type: RESET_SALARY_STAT,
})

export const resetSalaryManager = (): ResetSalaryManager => ({
  type: RESET_SALARY_MANAGER
})

export const resetSalaryDetails = (): ResetSalaryDetails => ({
  type: RESET_SALARY_DETAILS
})

export const loadSalaryManagerList = (search = '', page = 1): ThunkAction => (
  dispatch, getState, http,
) => {
  const params: any = { query: search, page };
  return http.get('/api/v1/salary/list/', params).then(
    (response: any) => {
      const { items } = adaptFromApi(response);
      dispatch(setSalaryManagerList(items || []));
      return Promise.resolve(response);
    },
    (reject: any) => Promise.reject(reject),
  );
};

export const fetchSalaryManager = (managerId: number): ThunkAction => (
  dispatch, getState, http,
) => {
  return http.get(`/api/v1/salary/detail/${managerId}/`).then(
    (response: any) => {
      dispatch(setSalaryManager(adaptFromApi(response) || null));
      return Promise.resolve(response);
    },
    (reject: any) => Promise.reject(reject),
  );
};

export const loadSalaryStatList = (managerId: number, periodStart: string, periodEnd: string): ThunkAction => (dispatch, getState, http) => http.get(
  '/api/v1/salary/stat/list/', adaptToApi({ managerId, periodStart, periodEnd }),
).then(
  (response: any) => {
    const { items } = adaptFromApi(response);
    dispatch(setManagerWorkPeriodList(items || []));
    return Promise.resolve(response);
  },
  (reject: any) => Promise.reject(reject),
);

export const loadManagerProfitability = (managerId: number, periodStart: string, periodEnd: string): ThunkAction => (dispatch, getState, http) => http.get(
  '/api/v1/salary/manager/profitability/list/', adaptToApi({ managerId, periodStart, periodEnd }),
).then(
  (response: any) => {
    const { items, totals } = adaptFromApi(response);
    dispatch(setManagerProfitability(items || []));
    dispatch(setManagerProfitabilityDetails({items: [], count: 0}));
    dispatch(setTotalManagerProfitability(totals));
    return Promise.resolve(response);
  },
  (reject: any) => Promise.reject(reject),
);

export const loadManagerProfitabilityDetails = (page: number, accountId: number, periodStart: string, periodEnd: string): ThunkAction => (dispatch, getState, http) => {
  const params: any = { limit: 25, skip: page * 25 - 25, accountId, deliveryDateFrom: periodStart, deliveryDateTo: periodEnd, serviceCostFrom: 1 };
  return http.get(
    '/api/v1/salary/profitability/list/', adaptToApi(params),
  ).then(
    (response: any) => {
      const { items, count } = adaptFromApi(response);
      dispatch(setManagerProfitabilityDetails({items: items || [], count}));
      return Promise.resolve(response);
    },
    (reject: any) => Promise.reject(reject),
  );
}

export const loadCompletionRateClientList = (managerId: number, periodStart: string, periodEnd: string): ThunkAction => (dispatch, getState, http) => http.get(
  '/api/v1/salary/completion_rate/client/list/', adaptToApi({ managerId, periodStart, periodEnd }),
).then(
  (response: any) => {
    const { items } = adaptFromApi(response);
    dispatch(setCompletionRateClientList(items || []));
    dispatch(setCompletionRateStatisticsList([]));
    return Promise.resolve(response);
  },
  (reject: any) => Promise.reject(reject),
);

export const loadCompletionRateStatisticsList = (managerId: number, periodStart: string, periodEnd: string, accountId: number): ThunkAction => (dispatch, getState, http) => http.get(
  '/api/v1/salary/completion_rate/stat/list/', adaptToApi({ managerId, periodStart, periodEnd, accountId }),
).then(
  (response: any) => {
    const { items } = adaptFromApi(response);
    dispatch(setCompletionRateStatisticsList(items || []));
    return Promise.resolve(response);
  },
  (reject: any) => Promise.reject(reject),
);

export const loadInvoicesAndPaymentsStatisticsList = (managerId: number, periodStart: string, periodEnd: string): ThunkAction => (dispatch, getState, http) => http.get(
  '/api/v1/salary/invoices_and_payments/stat/list/', adaptToApi({ managerId, periodStart, periodEnd }),
).then(
  (response: any) => {
    const { items, totalUnpaid, totalPaid } = adaptFromApi(response);
    dispatch(setInvoicesAndPaymentsStatisticsList(items || []));
    dispatch(setInvoicesAndPaymentsTotals({totalUnpaid, totalPaid}))
    return Promise.resolve(response);
  },
  (reject: any) => Promise.reject(reject),
);

export const loadInvoicesAndPaymentsDetails = (accountId: number, periodStart: string, periodEnd: string): ThunkAction => (dispatch, getState, http) => http.get(
  '/api/v1/salary/invoices_and_payments/stat/details/', adaptToApi({ accountId, periodStart, periodEnd }),
).then(
  (response: any) => {
    const { items } = adaptFromApi(response);
    const parsed = items ? items.map((item: any) => ({...item, accountId})): []
    dispatch(setInvoicesAndPaymentsDetails(parsed));
    return Promise.resolve(response);
  },
  (reject: any) => Promise.reject(reject),
);

export const updateManagerData = (managerId: number, awardPercent: number, sellAwardPercent: number, salary: number, profitabilityAwardPercent: number): ThunkAction => (dispatch, getState, http) => http.patch(
  `/api/v1/salary/update/${managerId}/`, adaptToApi({ awardPercent, sellAwardPercent, salary, profitabilityAwardPercent }),
).then(
  (response: any) => {
    dispatch(updateSalaryManager(adaptFromApi(response)));
    return Promise.resolve(response);
  },
  (reject: any) => Promise.reject(reject),
);

export const updatePeriodData = (managerId: number, periodStart: string, periodEnd: string, predictedCompletionRate: number, totalWorked: number, totalDays: number, predictedProfitability: number, profitabilitySettings: Array<ProfitabilitySettingsItem>): ThunkAction => (dispatch, getState, http) => http.patch(
  `/api/v1/salary/period/update/${managerId}/`, adaptToApi({ periodStart, periodEnd, predictedCompletionRate, totalWorked, totalDays, predictedProfitability, profitabilitySettings }),
).then(
  (response: any) => {
    dispatch(updateWorkPeriod(adaptFromApi(response)));
    return Promise.resolve(response);
  },
  (reject: any) => Promise.reject(reject),
);

export const refreshManager = (managerId: number, periodStart: string, periodEnd: string): ThunkAction => (dispatch, getState, http) => http.patch(
  `/api/v1/salary/refresh/${managerId}/`, adaptToApi({ periodStart, periodEnd }),
).then(
  (response: any) => {
    dispatch(setManagerRefreshState({managerId, status: ManagerRefreshStateStatuses.RUNNING}));
    return Promise.resolve(response);
  },
  (reject: any) => Promise.reject(reject),
);