import React, { useCallback, useState } from "react";

import { Modal } from "antd";
import Select from 'antd/es/select';
import useSelector from "../../../utils/hooks/useSelector";
import { RootState } from "../../../reducers";
import { isNumber } from "lodash";
import useDispatch from "../../../utils/hooks/useDispatch";
import { managerTypeDescription } from "../../salary/constants";
import { addAccountManager } from "../action-creators";

type Props = {
    editingAccountId: number | null;
    handleClose: () => void;
}

const SelectManagerModal = ({
    editingAccountId,
    handleClose
}: Props) => {
    const dispatch = useDispatch();

    const { accountsList } = useSelector((state: RootState) => state.accounts);
    const { salaryManagerList } = useSelector((state: RootState) => state.salary);
    const [val, setVal] = useState<number | undefined>();
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = useCallback(
        () => {
        if (!isNumber(val) || !isNumber(editingAccountId)) return;
        setSubmitting(true);
        dispatch(addAccountManager(editingAccountId, val)).then(
            () => {
                setSubmitting(false);
                handleClose();
            },
            () => {
                setSubmitting(false);
                handleClose();
            },
        );
        },
        [val, dispatch, editingAccountId, handleClose],
    );

    return (
        <Modal
            visible={isNumber(editingAccountId)}
            onCancel={handleClose}
            title={`Редактирование ${accountsList.find(i => i.accountId === editingAccountId)?.title}`}
            onOk={handleSubmit}
            confirmLoading={submitting}
            okText="Сохранить"
            cancelText="Отменить"
        >
            <Select
                value={val}
                onChange={(value: number) => setVal(value)}
                className="gx-w-100"
            >
                {salaryManagerList.map((_) => {
                    //@ts-ignore
                    return <Select.Option value={_.managerId}>{_.managerUsername} {managerTypeDescription[_.managerType]}</Select.Option>
                })}
            </Select>
        </Modal>
    )
}

export { SelectManagerModal };