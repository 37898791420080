import React from 'react';
import CircularProgress from "../../../components/CircularProgress";

const GlobalNotificationDetail = React.lazy(() => import('../components/GlobalNotificationDetail'));

function GlobalNotificationDetailView(props: any) {
  return (
    <React.Suspense fallback={<CircularProgress/>}>
      <GlobalNotificationDetail {...props} />
    </React.Suspense>
  );
}

export default GlobalNotificationDetailView;
