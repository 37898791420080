import { Reconcillation, reconcillationsType } from './types';

export interface ReconcillationState {
  reconcillationsList: Reconcillation[],
  reconcillationsCount: number,
}

const initialState: ReconcillationState = {
  reconcillationsList: [],
  reconcillationsCount: 0,
};

export default function (state = initialState, action: reconcillationsType): ReconcillationState {
  switch (action.type) {
    case 'SET_RECONCILLATIONS_LIST':
      return { ...state, reconcillationsList: action.acts };
    case 'SET_RECONCILLATIONS_COUNT':
      return { ...state, reconcillationsCount: action.count };
    default:
      return state;
  }
}
