export const managerTypeDescription = {
    'SELL_MANAGER': 'Менеджер по продажам',
    'DEVELOPMENT_MANAGER': 'Менеджер по развитию',
    'CLIENT_MANAGER': 'Менеджер клиентский',
}

export const SalaryTabs = {
    DASHBOARD: {
        key: 'DASHBOARD',
        value: 'Дашборд'
    },
    SALES_TARGET: {
        key: 'SALES_TARGET',
        value: 'План продаж'
    },
    PERCENTAGE_OF_PAYMENTS: {
        key: 'PERCENTAGE_OF_PAYMENTS',
        value: '% от оплат'
    },
    PROFITABILITY: {
        key: 'PROFITABILITY',
        value: 'Маржинальная прибыль'
    }
}