import { ShortAccount } from '../../accounts/types';

export const SET_CLIENT_EVENT_LIST = 'SET_CLIENT_EVENT_LIST';
export const SET_CLIENT_EVENT_COUNT = 'SET_CLIENT_EVENT_COUNT';

export const clientEventCode = ['falling_recency', 'self_registration', 'connect_owner_to_account', 'connect_user_to_account'];

export interface ClientEvent {
  eventId: string,
  content: string,
  accountId: number,
  eventDate: string,
  eventCode: typeof clientEventCode[number],
  createdAt: string,
  account: ShortAccount
}
export interface SetClientEventList {
  type: typeof SET_CLIENT_EVENT_LIST,
  items: ClientEvent[],
}

export interface SetClientEventCount {
  type: typeof SET_CLIENT_EVENT_COUNT,
  count: number
}

export type ClientEventsActions =
  SetClientEventList |
  SetClientEventCount;
