import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div className="gx-page-error-container">
      <div className="gx-page-error-content">
        <div className="gx-error-code gx-mb-4">404</div>
        <h2 className="gx-text-center">
          Страница не найдена
        </h2>

        <p className="gx-text-center">
          <Link className="gx-btn gx-btn-primary" to="/">На главную</Link>
        </p>
      </div>
    </div>
  );
}

export default NotFound;
