import {
  AccountTenderImportStatus,
  ADD_OR_UPDATE_TENDER_CALCULATION_WEIGHT_RESULT,
  ADD_TENDER_CALCULATION_DESTINATION,
  ADD_TENDER_CALCULATION_WEIGHT,
  REMOVE_TENDER_CALCULATION_DESTINATION,
  REMOVE_TENDER_CALCULATION_WEIGHT,
  RESET_ACCOUNT_TENDER_IMPORT,
  RESET_TENDER_CALCULATION_DETAIL,
  RESET_TENDER_CALCULATION_IMPORT_STATUS,
  SET_ACCOUNT_TENDER_IMPORT_ID,
  SET_ACCOUNT_TENDER_IMPORT_STATUS,
  SET_CALCULATION_RUNNING,
  SET_TENDER_CALCULATION_COUNT,
  SET_TENDER_CALCULATION_DETAIL,
  SET_TENDER_CALCULATION_IMPORT_STATUS,
  SET_TENDER_CALCULATION_LIST,
  TenderActions,
  TenderCalculationDetail,
  TenderCalculationImportStatus,
  TenderCalculationListItem,
} from './types';
import { removeByFieldValue } from '../../utils/array/remove';
import addOrUpdate from '../../utils/array/addOrUpdate';

type TenderState = {
  tenderCalculationList: TenderCalculationListItem[],
  tenderCalculationCount: number,
  tenderCalculationDetail: null | TenderCalculationDetail,
  calculationRunning: boolean,
  tenderImportStatus: TenderCalculationImportStatus,

  accountTenderImportId: null | string,
  accountTenderImportStatus: null | AccountTenderImportStatus,
};

const initialState: TenderState = {
  tenderCalculationList: [],
  tenderCalculationCount: 0,
  tenderCalculationDetail: null,
  calculationRunning: false,
  tenderImportStatus: TenderCalculationImportStatus.INITIAL,

  accountTenderImportId: null,
  accountTenderImportStatus: null,
};

export default function (state = initialState, action: TenderActions): TenderState {
  switch (action.type) {
    case SET_TENDER_CALCULATION_LIST:
      return { ...state, tenderCalculationList: action.list };
    case SET_TENDER_CALCULATION_COUNT:
      return { ...state, tenderCalculationCount: action.count };
    case SET_TENDER_CALCULATION_DETAIL:
      return { ...state, tenderCalculationDetail: action.detail };
    case RESET_TENDER_CALCULATION_DETAIL:
      return { ...state, tenderCalculationDetail: initialState.tenderCalculationDetail };
    case ADD_TENDER_CALCULATION_WEIGHT:
      if (state.tenderCalculationDetail) {
        return {
          ...state,
          tenderCalculationDetail: {
            ...state.tenderCalculationDetail,
            weightList: [...state.tenderCalculationDetail.weightList, action.weight],
          },
        };
      }
      return state;
    case REMOVE_TENDER_CALCULATION_WEIGHT:
      if (state.tenderCalculationDetail) {
        return {
          ...state,
          tenderCalculationDetail: {
            ...state.tenderCalculationDetail,
            weightList: removeByFieldValue(state.tenderCalculationDetail.weightList, 'weightId', action.weightId),
          },
        };
      }
      return state;
    case ADD_TENDER_CALCULATION_DESTINATION:
      if (state.tenderCalculationDetail) {
        return {
          ...state,
          tenderCalculationDetail: {
            ...state.tenderCalculationDetail,
            destinations: [...state.tenderCalculationDetail.destinations, action.destination],
          },
        };
      }
      return state;
    case REMOVE_TENDER_CALCULATION_DESTINATION:
      if (state.tenderCalculationDetail) {
        return {
          ...state,
          tenderCalculationDetail: {
            ...state.tenderCalculationDetail,
            destinations: removeByFieldValue(state.tenderCalculationDetail.destinations, 'destinationId', action.destinationId),
          },
        };
      }
      return state;
    case ADD_OR_UPDATE_TENDER_CALCULATION_WEIGHT_RESULT:
      if (state.tenderCalculationDetail) {
        return {
          ...state,
          tenderCalculationDetail: {
            ...state.tenderCalculationDetail,
            resultList: addOrUpdate(state.tenderCalculationDetail.resultList, ['destinationId', 'weightId'], action.result),
          },
        };
      }
      return state;
    case SET_CALCULATION_RUNNING:
      return { ...state, calculationRunning: action.running };
    case SET_TENDER_CALCULATION_IMPORT_STATUS:
      return { ...state, tenderImportStatus: action.status };
    case RESET_TENDER_CALCULATION_IMPORT_STATUS:
      return { ...state, tenderImportStatus: initialState.tenderImportStatus };
    case SET_ACCOUNT_TENDER_IMPORT_ID:
      return { ...state, accountTenderImportId: action.importId };
    case SET_ACCOUNT_TENDER_IMPORT_STATUS:
      return { ...state, accountTenderImportStatus: action.status };
    case RESET_ACCOUNT_TENDER_IMPORT:
      return { ...state, accountTenderImportId: initialState.accountTenderImportId, accountTenderImportStatus: initialState.accountTenderImportStatus };
    default:
      return state;
  }
}
