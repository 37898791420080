import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withQueryParams } from 'helpers';
import List from 'components/List';
import AccountDebtTable from '../components/AccountDebtTable';
import { loadArrears } from '../action-creators';

const QUERY_PARAMS_CONFIG = {
  page: { type: 'pageNumber' },
};

class AccountDebtView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  setLoading = (loading) => this.setState({ loading });

  componentWillMount() {
    this.loadArrears();
  }

  loadArrears = () => {
    const { params: { page } } = this.props;
    this.setLoading(true);
    this.props.loadArrears(page).then(() => this.setLoading(false), () => this.setLoading(false));
  };

  handlePageChange = (page) => this.props.setFieldValue({ page }, this.loadArrears);

  render() {
    const { params: { page }, arrearsCount } = this.props;
    const { loading } = this.state;
    return (
      <List
        title="Задолженность"
        totalCount={arrearsCount}
        currentPage={page}
        handlePageChange={this.handlePageChange}
        withSearch={false}
        loading={loading}
      >
        <AccountDebtTable loading={loading} />
      </List>
    );
  }
}

export default connect(
  ({ accounts: { arrearsCount } }) => ({ arrearsCount }),
  { loadArrears },
)(withQueryParams(QUERY_PARAMS_CONFIG)(AccountDebtView));
