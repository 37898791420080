import {
  Transaction, ActionType, SET_TRANSACTIONS_LIST, SET_TRANSACTIONS_COUNT,
} from './types';

export interface State {
  transactionsList: Transaction[],
  transactionsCount: number,
}

const initialState: State = {
  transactionsList: [],
  transactionsCount: 0,
};

export default function (state = initialState, action: ActionType): State {
  switch (action.type) {
    case SET_TRANSACTIONS_LIST:
      return { ...state, transactionsList: action.transactions };
    case SET_TRANSACTIONS_COUNT:
      return { ...state, transactionsCount: action.count };
    default:
      return state;
  }
}
