import { UploadFile } from 'antd/es/upload/interface';
import { AccountComment, Dialog, Message } from './types';

export const SET_CURRENT_DIALOG = 'SET_CURRENT_DIALOG';
export const SET_DIALOG_MESSAGES = 'SET_DIALOG_MESSAGES';
export const ADD_DIALOG_MESSAGES = 'ADD_DIALOG_MESSAGES';
export const ADD_DIALOG_MESSAGE = 'ADD_DIALOG_MESSAGE';
export const SET_DIALOG_MESSAGES_COUNT = 'SET_DIALOG_MESSAGES_COUNT';
export const SET_DIALOG_LIST = 'SET_DIALOG_LIST';
export const SET_DIALOG_COUNT = 'SET_DIALOG_COUNT';
export const SET_ACCOUNT_COMMENTS_LIST = 'SET_ACCOUNT_COMMENTS_LIST';
export const SET_ACCOUNT_COMMENTS_COUNT = 'SET_ACCOUNT_COMMENTS_COUNT';
export const ADD_ACCOUNT_COMMENT = 'ADD_ACCOUNT_COMMENT';
export const ADD_UPLOAD_FILE = 'ADD_UPLOAD_FILE';
export const RESET_UPLOAD_FILES = 'RESET_UPLOAD_FILES';
export const REMOVE_UPLOAD_FILE = 'REMOVE_UPLOAD_FILE';

export interface SetCurrentDialogParams {
  type: typeof SET_CURRENT_DIALOG,
  dialog: Dialog | null
}

export interface SetDialogMessages {
  type: typeof SET_DIALOG_MESSAGES,
  messages: Message[]
}

export interface AddDialogMessages {
  type: typeof ADD_DIALOG_MESSAGES,
  messages: Message[]
}

export interface AddDialogMessage {
  type: typeof ADD_DIALOG_MESSAGE,
  message: Message
}

export interface SetDialogMessagesCount {
  type: typeof SET_DIALOG_MESSAGES_COUNT,
  count: number,
}

export interface SetDialogList {
  type: typeof SET_DIALOG_LIST,
  dialogs: Array<Dialog>,
}

export interface SetDialogCount {
  type: typeof SET_DIALOG_COUNT,
  count: number,
}

export interface SetAccountCommentsList {
  type: typeof SET_ACCOUNT_COMMENTS_LIST,
  comments: Array<AccountComment>,
}

export interface SetAccountCommentsCount {
  type: typeof SET_ACCOUNT_COMMENTS_COUNT,
  count: number,
}

export interface AddAccountComment {
  type: typeof ADD_ACCOUNT_COMMENT,
  comment: AccountComment,
}

export interface AddUploadFile {
  type: typeof ADD_UPLOAD_FILE,
  file: UploadFile,
}

export interface ResetUploadFiles {
  type: typeof RESET_UPLOAD_FILES
}

export interface RemoveUploadFile {
  type: typeof REMOVE_UPLOAD_FILE,
  file: UploadFile
}

export type ChatReducerAction = SetCurrentDialogParams | SetDialogMessages | AddDialogMessage | AddDialogMessages |
SetDialogMessagesCount | SetDialogCount | SetDialogList | SetAccountCommentsList | SetAccountCommentsCount | AddAccountComment |
AddUploadFile | ResetUploadFiles | RemoveUploadFile;
