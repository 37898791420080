import { Moment } from 'moment';
import {
  ADD_CURRENT_WAYBILL_TRACKING_LIST,
  AddCurrentWaybillTrackingList,
  RESET_CURRENT_WAYBILL,
  RESET_CURRENT_WAYBILL_TRACKING_LIST,
  ResetCurrentWaybill,
  ResetCurrentWaybillTrackingList,
  SET_CURRENT_WAYBILL,
  SET_CURRENT_WAYBILL_TRACKING_COUNT,
  SET_CURRENT_WAYBILL_TRACKING_LIST,
  SET_CURRENT_WAYBILL_PRICING_LIST,
  SetCurrentWaybill,
  SetCurrentWaybillTrackingCount,
  SetCurrentWaybillTrackingList,
  SetCurrentWaybillPricingList,
  Waybill,
  WaybillDetail,
  WaybillTracking,
  WaybillPricing,
  SetCurrentOrderServiceList,
  SET_CURRENT_ORDER_SERVICE_LIST,
} from './types';
import { ThunkAction } from '../../utils/types';

export const setWaybillsList = (waybills: Array<Waybill>) => ({
  type: 'SET_WAYBILLS_LIST',
  waybills,
});

export const setWaybillsCount = (count: number) => ({
  type: 'SET_WAYBILLS_COUNT',
  count,
});

export const setWaybillsSearch = (search: string) => ({
  type: 'SET_WAYBILLS_SEARCH',
  search,
});

export const setWaybillsStatus = (status: number | null) => ({
  type: 'SET_WAYBILLS_STATUS',
  status,
});

export const setWaybillsPage = (page: number) => ({
  type: 'SET_WAYBILLS_PAGE',
  page,
});

export const setWaybillsCreatedAtFrom = (date: Moment | null) => ({
  type: 'SET_WAYBILLS_CREATED_AT_FROM',
  date,
});

export const setWaybillsCreatedAtTo = (date: Moment | null) => ({
  type: 'SET_WAYBILLS_CREATED_AT_TO',
  date,
});

export const setWaybillsPickupDateFrom = (date: Moment | null) => ({
  type: 'SET_WAYBILLS_PICKUP_DATE_FROM',
  date,
});

export const setWaybillsPickupDateTo = (date: Moment | null) => ({
  type: 'SET_WAYBILLS_PICKUP_DATE_TO',
  date,
});

export const setWaybillsBillingDateFrom = (date: Moment | null) => ({
  type: 'SET_WAYBILLS_BILLING_DATE_FROM',
  date,
});

export const setWaybillsBillingDateTo = (date: Moment | null) => ({
  type: 'SET_WAYBILLS_BILLING_DATE_TO',
  date,
});

export const setWaybillsAccount = (account: number | string | null) => ({
  type: 'SET_WAYBILLS_ACCOUNT',
  account,
});

export const setWaybillsDeliveryService = (deliveryService: string | null) => ({
  type: 'SET_WAYBILLS_DELIVERY_SERVICE',
  deliveryService,
});

export const resetWaybillList = () => ({
  type: 'RESET_WAYBILL_LIST',
});

export const setCurrentWaybill = (waybill: WaybillDetail): SetCurrentWaybill => ({
  type: SET_CURRENT_WAYBILL,
  waybill,
});

export const resetCurrentWaybill = (): ResetCurrentWaybill => ({
  type: RESET_CURRENT_WAYBILL,
});

export const setCurrentWaybillTrackingList = (tracking: Array<WaybillTracking>): SetCurrentWaybillTrackingList => ({
  type: SET_CURRENT_WAYBILL_TRACKING_LIST,
  tracking,
});

export const setCurrentWaybillPricingList = (pricing: Array<WaybillPricing>): SetCurrentWaybillPricingList => ({
  type: SET_CURRENT_WAYBILL_PRICING_LIST,
  pricing,
});

export const addCurrentWaybillTrackingList = (tracking: Array<WaybillTracking>): AddCurrentWaybillTrackingList => ({
  type: ADD_CURRENT_WAYBILL_TRACKING_LIST,
  tracking,
});

export const resetCurrentWaybillTrackingList = (): ResetCurrentWaybillTrackingList => ({
  type: RESET_CURRENT_WAYBILL_TRACKING_LIST,
});

export const setCurrentWaybillTrackingCount = (count: number): SetCurrentWaybillTrackingCount => ({
  type: SET_CURRENT_WAYBILL_TRACKING_COUNT,
  count,
});

export const setCurrentOrderServiceList = (serviceList: Array<any>): SetCurrentOrderServiceList => ({
  type: SET_CURRENT_ORDER_SERVICE_LIST,
  serviceList,
});

export const loadWaybills = (
  search = '',
  page = 1,
  accountId?: null | undefined | string,
  service: string | null = null,
  createdAtFrom: string | null = null,
  createdAtTo: string | null = null,
  pickupDateFrom: string | null = null,
  pickupDateTo: string | null = null,
  billingDateFrom: string | null = null,
  billingDateTo: string | null = null,
  filters?: anyObject,
): ThunkAction => (dispatch, getState, http) => {
  const params: any = { limit: 25, skip: page * 25 - 25 };
  if (search) params.query = search;

  if (service) {
    params.delivery_service = service;
  }
  if (accountId) {
    params.account_id = accountId;
  }
  if (createdAtFrom) {
    params.created_at_from = createdAtFrom;
  }
  if (createdAtTo) {
    params.created_at_to = createdAtTo;
  }
  if (pickupDateFrom) {
    params.pickup_date_from = pickupDateFrom;
  }
  if (pickupDateTo) {
    params.pickup_date_to = pickupDateTo;
  }
  if (billingDateFrom) {
    params.billing_date_from = billingDateFrom;
  }
  if (billingDateTo) {
    params.billing_date_to = billingDateTo;
  }

  if (filters?.status && filters?.status.length) params.status = filters.status;

  if (filters?.additionalService && filters?.additionalService.length) params.additional_service = filters.additionalService;
  if (filters?.deliveryDateFrom) params.delivery_date_from = filters.deliveryDateFrom;
  if (filters?.deliveryDateTo) params.delivery_date_to = filters.deliveryDateTo;
  if (filters?.tariff) params.rate_code = filters.tariff;

  return http.get('/api/v1/shipping/waybill/list/', params).then(
    (response: any) => {
      const { waybills, count } = response;
      dispatch(setWaybillsList(waybills || []));
      dispatch(setWaybillsCount(count));
      return Promise.resolve(response);
    },
    (reject: any) => Promise.reject(reject),
  );
};

export const accountsFilterSearch = (query = '', page = 1): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/accounts/list/', {
  query,
  page,
});

// export const loadWaybillDetail = (plsWaybillNumber: string):
// ThunkAction => (dispatch, getState, http) => http.get(`/api/v1/shipping/waybill/${plsWaybillNumber}/`).then(
//   (response: WaybillDetail) => {
//     dispatch(setCurrentWaybill(response));
//     return Promise.resolve(response);
//   },
// );

export const loadWaybillTracking = (plsWaybillNumber: string, page = 1, limit = 10):
ThunkAction => (dispatch, getState, http) => http.get('/api/v1/shipping/waybill/tracking/', {
  page,
  limit,
  pls_waybill_number: plsWaybillNumber,
}).then(
  (response: { count: number, tracking: Array<WaybillTracking> }) => {
    dispatch(setCurrentWaybillTrackingList(response.tracking));
    dispatch(setCurrentWaybillTrackingCount(response.count));
    return Promise.resolve(response);
  },
);

export const loadWaybillPricing = (plsWaybillNumber: string):
ThunkAction => (dispatch, getState, http) => http.get('/api/v1/shipping/waybill/pricing/', { pls_waybill_number: plsWaybillNumber }).then(
  (response: { pricing: Array<WaybillPricing> }) => {
    dispatch(setCurrentWaybillPricingList(response.pricing));
    return Promise.resolve(response);
  },
);

export const loadOrderServiceList = (orderNumber: string):
ThunkAction => (dispatch, getState, http) => http.get('/api/v1/shipping/order/service/list/', { order_number: orderNumber }).then(
  (response: { items: Array<any> }) => {
    dispatch(setCurrentOrderServiceList(response.items));
    return Promise.resolve(response);
  },
);

export const getWaybillDetail = (plsWaybillNumber: string): ThunkAction => (dispatch, getState, http) => http.get(
  `/api/v1/tms/waybill/${plsWaybillNumber}/detail/`,
).then(
  (response: WaybillDetail) => {
    dispatch(setCurrentWaybill(response));
    return Promise.resolve(response);
  },
);
