import { adaptFromApi, adaptToApi } from '../../utils/adapter';
import { ThunkAction } from '../../utils/types';
import { FailedBilling, RESET_FAILED_BILLING, ResetFailedBilling, SET_FAILED_BILLING_LIST, SetFailedBillingList } from './types';

export const setFailedBillingList = (payload: {items: FailedBilling[], count: number}): SetFailedBillingList => ({
  type: SET_FAILED_BILLING_LIST,
  payload,
});

export const resetFailedBilling = (): ResetFailedBilling => ({
  type: RESET_FAILED_BILLING
})

export const loadFailedBillingList = (page = 1): ThunkAction => (
  dispatch, getState, http,
) => {
  const params: any = { page };
  return http.get('/api/v1/billing/failed_invoices/', params).then(
    (response: any) => {
      const { items, count } = adaptFromApi(response);
      dispatch(setFailedBillingList({items: items || [], count: count || 0}));
      return Promise.resolve(response);
    },
    (reject: any) => Promise.reject(reject),
  );
};

export const syncFailedBilling = (billingId: number, docId: string, page: number): ThunkAction => (dispatch, getState, http) => http.patch(
  `/api/v1/billing/failed_invoices/${billingId}/update/`, adaptToApi({ docId }),
).then(
  (response: any) => {
    dispatch(loadFailedBillingList(page));
    return Promise.resolve(response);
  },
  (reject: any) => Promise.reject(reject),
);
