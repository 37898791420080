import React, { useState } from 'react';
import { Layout, Icon } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { parseJwt } from 'utils/jwt';
import HorizontalNav from './HorizontalNav';
import VerticalNav from './VerticalNav';
import UserProfile from '../Sidebar/UserProfile';
import AppsNavigation from '../Sidebar/AppsNavigation';

const { Header } = Layout;

function Topbar() {
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);

  const accessToken = useSelector((state) => state.users.token.accessToken);
  const jwtData = accessToken && accessToken !== 'null' ? parseJwt(accessToken) : null;
  const displayApps = jwtData && (
    jwtData.modules.some((moduleName) => moduleName === 'tms' || moduleName.startsWith('tms/'))
    || jwtData.modules.includes('shop')
    || jwtData.modules.includes('tools')
  );
  return (
    <div
      className="gx-header-horizontal gx-header-horizontal-dark gx-inside-header-horizontal"
      style={{ overflow: 'hidden' }}
    >

      <Header
        className="gx-header-horizontal-main"
      >
        <div className="gx-container gx-header-horizontal-nav">
          <div style={{ height: '71px' }} className="gx-header-horizontal-main-grid">
            <div className="gx-d-lg-none gx-burder-menu-trigger" onClick={() => setShowBurgerMenu(!showBurgerMenu)}>
              <Icon type={showBurgerMenu ? 'cross' : 'menu'} />
            </div>

            <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo gx-m-auto">
              CRM
            </Link>

            <Link to="/" className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo">
              CRM
            </Link>

            <div className="ant-menu-dark gx-d-none gx-d-sm-none gx-d-xs-none gx-d-md-none gx-d-lg-block">
              <HorizontalNav />
            </div>

            <ul className="gx-header-notifications gx-ml-lg-auto">
              <li className="gx-user-nav"><UserProfile /></li>
              {displayApps && (
                <li className="gx-user-nav" style={{ marginLeft: '0' }}><AppsNavigation /></li>
              )}
            </ul>
          </div>
          <div className={`ant-menu-dark gx-d-lg-none gx-burder-menu${!showBurgerMenu && ' gx-burder-menu-hide'}`}>
            <VerticalNav onClick={() => setShowBurgerMenu(false)} />
          </div>
        </div>
      </Header>
    </div>
  );
}

export default Topbar;
