import React from 'react';
import { Col, Row } from 'antd';
import SearchBox from './components/SearchBox';
import CompanyBillTable from './components/CompanyBillTable';

function CompanyBillView() {
  return (
    <>
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <div className="title h1"><p>Счета для курьерских служб</p></div>
        </Col>
      </Row>
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <SearchBox />
        </Col>
      </Row>
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <CompanyBillTable />
        </Col>
      </Row>
    </>
  );
}

export default CompanyBillView;
