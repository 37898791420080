import React, { CSSProperties } from 'react';
import { InputNumber } from 'antd';

interface NumberRangeFieldProps {
  value: [number | undefined, number | undefined],
  onChange: (val: [number | undefined, number | undefined]) => void,
  fromPlaceholder?: string,
  fromLabel?: string,
  toPlaceholder?: string,
  toLabel?: string,
  style?: CSSProperties,
}

function NumberRangeField({
  value = [undefined, undefined],
  onChange,
  fromPlaceholder = '',
  fromLabel = '',
  toPlaceholder = '',
  toLabel = '',
  style = {},
}: NumberRangeFieldProps) {

  const handleFromNumberChange = (fromNumber: number | undefined) => {
    onChange([fromNumber, value.length ? value[1] : undefined]);
  };

  const handleToNumberChange = (toNumber: number | undefined) => {
    onChange([value.length ? value[0] : undefined, toNumber]);
  };

  return (
    <div style={style}>
      <div>
        {!!fromLabel && (
          <span>{fromLabel}</span>
        )}
        <InputNumber value={value[0]} placeholder={fromPlaceholder} onChange={handleFromNumberChange} className='gx-w-100'/>
      </div>
      <div>
        {!!toLabel && (
          <span>{toLabel}</span>
        )}
        <InputNumber value={value[1]} placeholder={toPlaceholder} onChange={handleToNumberChange} className='gx-w-100'/>
      </div>
    </div>
  );
}

export default NumberRangeField;
