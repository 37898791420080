import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  Col,
  Row,
  Table,
} from 'antd';
// import { loadOrders } from '../../action-creators';
import Aux from '../../../../../util/Auxiliary';

function ClientBillTable() {
  const dispatch = useDispatch();
  // const ordersList = useSelector((state) => state.orders.ordersList);
  const [search] = useState('');

  const taskStatus = [
    {
      id: 1,
      title: 'Отменен',
      color: 'red',
    }, {
      id: 2,
      title: 'В пути',
      color: 'yellow',
    }, {
      id: 3,
      title: 'Выполнен',
      color: 'green',
    },
  ];

  // useEffect(() => {
  //   dispatch(loadOrders(search));
  // }, [search]);

  const getStatus = (status) => {
    const statusData = taskStatus.filter((taskStatus, index) => status === taskStatus.id)[0];
    if (statusData) {
      return (
        <Aux>
          <span className="gx-nonhover">
            <i className={`icon icon-circle gx-fs-sm gx-text-${statusData.color}`} />
          </span>
          <span className={`gx-badge gx-hover gx-mb-0 gx-text-white gx-badge-${statusData.color}`}>
            {statusData.title}
          </span>
          {/* <div className="gx-task-item-content-right"> */}
          {/*  {getStatus(status)} */}
          {/* </div> */}
        </Aux>
      );
    }
    return null;
  };

  const columns = [
    {
      className: 'gx-text-center',
      title: 'Статус',
      dataIndex: 'status',
      key: 'name',
      // eslint-disable-next-line react/jsx-filename-extension
      render: (text) => (
        <div className="gx-card-ticketlist gx-text-center">
          <div className="gx-media gx-task-list-item gx-flex-nowrap">
            <div className="gx-media-body gx-task-item-content gx-text-center">

              <div className="gx-task-item-content-right">
                {getStatus(text)}
              </div>
            </div>
          </div>
        </div>
      ),

    },
    {
      className: 'gx-text-center',
      title: 'Номер счета',
      dataIndex: 'bill',
      key: 'bill',
      // eslint-disable-next-line react/jsx-filename-extension
    },
    {
      className: 'gx-text-center',
      title: 'Клиент',
      dataIndex: 'client',
      key: 'client',
    },
    {
      className: 'gx-text-center',
      title: 'Период',
      dataIndex: 'period',
      key: 'period',
    },
    {
      className: 'gx-text-center',
      title: 'Сумма к оплате',
      dataIndex: 'total',
      key: 'total',
    },
    {
      className: 'gx-text-center',
      title: '',
      dataIndex: '',
      key: '',
    },

  ];

  const data = [
    {
      status: 4,
      dataIndex: '',
      key: '1',
      client: 'ООО "Ромашка"',
      bill: '310520-001 05.02.20',
      period: '01.05 - 31.05.2020',
      lastDate: '12.01.20',
      total: '300000',

    },
    {
      status: 1,
      key: '2',
      client: 'ООО "Ромашка"',
      bill: '310520-002 05.02.20',
      period: '01.05 - 31.05.2020',
      lastDate: '12.01.20',
      total: '130000',
    },
    {
      status: 1,
      key: '3',
      client: 'ООО "Ромашка"',
      bill: '310520-003 05.02.20',
      period: '01.05 - 31.05.2020',
      lastDate: '12.01.20',
      total: '3000',
    },
    {
      status: 3,
      key: '4',
      client: 'ООО "Ромашка"',
      bill: '310520-004 05.02.20',
      period: '01.05 - 31.05.2020',
      lastDate: '12.01.20',
      total: '10000',
    },
    {
      status: 2,
      key: '5',
      client: 'ООО "Ромашка"',
      bill: '310520-005 05.02.20',
      period: '01.05 - 31.05.2020',
      lastDate: '12.01.20',
      total: '2000',
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Card title="Selection Table">
          <Table
            className="gx-table-responsive"
            columns={columns}
            dataSource={data}
            // dataSource={ordersList.map(
            //   (account) => ({
            //     key: account.id,
            //     title: account.title,
            //     name: account.owner,
            //     phone: account.owner,
            //     lastDate: '12.01.20',
            //     total: '500',
            //   }),
            //
          />
        </Card>
      </Col>
    </Row>
  );
}

export default ClientBillTable;
