import { Account, AccountStatusType } from './types';

const getUserStatusColor = (record: Account) => {
  let color = {
    primary: '#bfbfbf',
    outline: '#8c8c8c',
  };

  if (record.status === AccountStatusType.BANNED) {
    return {
      primary: '#f44336',
      outline: '#f5222d',
    };
  }

  if (record.owner && !!record.owner.userId) {
    color = {
      primary: '#fadb14',
      outline: '#fa8c16',
    };
    if (record.status === 2) {
      color = {
        primary: '#20c997',
        outline: '#52c41a',
      };
    }
  }
  return color;
};

const getContractStatusColor = (record: Account) => {
  let color = {
    primary: '#bfbfbf',
    outline: '#8c8c8c',
  };

  if (record.status === AccountStatusType.BANNED) {
    return {
      primary: '#f44336',
      outline: '#f5222d',
    };
  }

  if (record.contract) {
    if (record.contract.status === 'confirmed') {
      color = {
        primary: '#20c997',
        outline: '#52c41a',
      };
    } else {
      color = {
        primary: '#fadb14',
        outline: '#fa8c16',
      };
    }
  }
  return color;
};

const getAccountFilterStatus = (account: Account): number => {
  if (account.status === AccountStatusType.BANNED) return 5;
  if (!!account.owner && !!account.owner.userId && !account.contractId) return 1;
  if ((!account.owner || !account.owner.userId) && !!account.contractId) return 2;
  if (account.owner && account.contractId && account.status === AccountStatusType.REGISTRATION) return 3;
  if (account.owner && account.status === AccountStatusType.ACTIVE) return 4;
  return 0;
};

export { getAccountFilterStatus, getUserStatusColor, getContractStatusColor };
