import {
  ClientActiveSeries,
  SET_CLIENT_ACTIVE_STATISTIC,
  SET_TOTAL_WAYBILL_STAT,
  SET_WAYBILL_BASE_STAT,
  StatisticsActions,
  TotalWaybillStat,
  WaybillBaseStat,
  WaybillStat,
} from './types';

export interface StatisticState {
  waybillStat: WaybillStat[],
  waybillStatTotalCount: number,
  waybillStatTotalAmount: number,
  clientActiveStatistic: ClientActiveSeries[],
  totalWaybillStat: TotalWaybillStat[],
  waybillBaseStat: WaybillBaseStat | null,
}

const initialState: StatisticState = {
  waybillStat: [],
  waybillStatTotalCount: 0,
  waybillStatTotalAmount: 0,
  clientActiveStatistic: [],
  totalWaybillStat: [],
  waybillBaseStat: null,
};

export default function (state = initialState, action: StatisticsActions | any): StatisticState {
  switch (action.type) {
    case 'SET_WAYBILL_STAT':
      return {
        ...state, waybillStat: action.series, waybillStatTotalCount: action.totalCount, waybillStatTotalAmount: action.totalAmount,
      };
    case SET_CLIENT_ACTIVE_STATISTIC:
      return { ...state, clientActiveStatistic: action.statistic };
    case SET_TOTAL_WAYBILL_STAT:
      return { ...state, totalWaybillStat: action.statistic };
    case SET_WAYBILL_BASE_STAT:
      return { ...state, waybillBaseStat: action.statistic };
    default:
      return state;
  }
}
