import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NotificationContainer } from 'react-notifications';
import BaseRouter from '../routing/BaseRouter';
import { getCurrentUserData } from '../app/users/action-creators';
import CircularProgress from '../components/CircularProgress';

function Base(props) {
  const dispatch = useDispatch();
  const [userInited, setUserInited] = useState(false);

  useEffect(() => {
    dispatch(getCurrentUserData()).then(
      () => {
        setUserInited(true);
      },
      () => {
        setUserInited(true);
      },
    );
  }, [dispatch]);

  const { location } = props;
  return (
    <>
      {
        userInited
          ? <BaseRouter location={location} />
          : <CircularProgress />
      }
      <NotificationContainer />
    </>
  );
}

export default Base;
