import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const NewContract = React.lazy(() => import('../components/NewContract'));
function NewContractView(props) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <NewContract {...props} />
    </Suspense>
  );
}

export default NewContractView;
