import React, { Suspense } from 'react';
import CircularProgress from '../../../../components/CircularProgress';

const TransactionList = React.lazy(() => import('../components/TransactionList'));
function TransactionListView(props) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <TransactionList {...props} />
    </Suspense>
  );
}

export default TransactionListView;
