import React from 'react';
import { connect } from 'react-redux';
import { loadAccount, setBreadcrumbAccount } from 'app/accounts/action-creators';

const defaultConfig = {
  props: ({ match: { params: { accountId } } }) => ({ accountId }),
};

const withCurrentAccount = (config = defaultConfig) => (WrappedComponent) => {
  class WithCurrentAccount extends React.Component {
    componentWillMount() {
      const { accountId } = config
        ? ((typeof config.props === 'function') ? config.props(this.props) : config.props)
        : this.props;
      const {
        currentAccount, breadcrumbAccount, setBreadcrumbAccount, loadAccount,
      } = this.props;
      if (accountId) {
        let id = accountId;
        if (typeof accountId === 'string') id = parseInt(accountId, 10);
        if (id !== currentAccount?.accountId) {
          if (id !== breadcrumbAccount?.accountId) {
            setBreadcrumbAccount(null);
            loadAccount(id, false).then((response) => setBreadcrumbAccount(response));
          }
        } else setBreadcrumbAccount(currentAccount);
      }
    }

    render() {
      const { breadcrumbAccount } = this.props;
      return (
        <WrappedComponent
          {...this.props}
          currentAccount={breadcrumbAccount}
          breadcrumbAccount={undefined}
          loadAccount={undefined}
          setBreadcrumbAccount={undefined}
        />
      );
    }
  }

  return connect(
    ({ accounts: { currentAccount, breadcrumbAccount } }) => ({ currentAccount, breadcrumbAccount }),
    { loadAccount, setBreadcrumbAccount },
  )(WithCurrentAccount);
};

export default withCurrentAccount;
