import React, { Suspense } from 'react';
import CircularProgress from '../../../../components/CircularProgress';

const ClientEventList = React.lazy(() => import('../components/ClientEventList'));

function ClientEventListView(props: any) {
  return (
    <Suspense fallback={<CircularProgress />}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <ClientEventList {...props} />
    </Suspense>
  );
}

export default ClientEventListView;
