import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Card, Col, Icon, InputNumber, Row, Skeleton, Table,
} from 'antd';
import moment from 'moment';
import { SERVER_DATE_FORMAT, USER_DATE_FORMAT, WAYBILL_STATUSES } from '../../../utils/constants';
import { RootState } from '../../../reducers';
import { ColumnProps } from 'antd/es/table';
import { OrderServiceItem, Profitability } from '../types';
import useSelector from '../../../utils/hooks/useSelector';
import { getWaybillRateNameListing } from '../../shipping/helpers';
import { addSingleProfitability, loadProfitabilityList, updateProfitabilitySingle } from '../action-creators';
import { isNumber } from 'lodash';
import useDispatch from '../../../utils/hooks/useDispatch';

function getNumber(record: any) {
  let statusData = null;
  if (WAYBILL_STATUSES.hasOwnProperty(record.state)) statusData = WAYBILL_STATUSES[record.state];

  return (
    <Row>
      <Col span={24}>
        <span className="gx-nonhover gx-mr-2 gx-pr-1">
          <i className={`icon icon-circle gx-fs-sm gx-text-${statusData ? statusData.color : 'gray'}`} />
        </span>
        {record.serviceNumber || record.plsNumber}
      </Col>
      <Col span={24}>

        <span className="gx-nonhover gx-mr-3 gx-pr-2" />
        {record.serviceOrderNumber ? record.serviceOrderNumber : ''}
      </Col>
    </Row>
  );
}

interface ProfitabilityListParams {
  query: string,
  page: number,
  createdAtFrom: null | moment.Moment,
  createdAtTo: null | moment.Moment,
  service: string,
  deliveryDateFrom: null | moment.Moment,
  deliveryDateTo: null | moment.Moment,
  tariff: string[],
  sellerId: number,
  serviceCostFrom: number,
  serviceCostTo: number,
  serviceWeightFrom: number,
  serviceWeightTo: number,
  cityFrom: string,
  cityTo: string,
  accountId: number
}

type Props = {
    loading: boolean,
    selectedOrders: string[],
    setSelectedOrders: (value: string[]) => void,
    params: ProfitabilityListParams
}

function ProfitabilityTable({ loading, selectedOrders, setSelectedOrders, params }: Props) {

  const dispatch = useDispatch();

  const { deliveryServices } = useSelector((state) => state.reference);
  const { profitabilityList } = useSelector((state: RootState) => state.profitability);
  const { user } = useSelector((state) => state.users);

  const [editingWaybill, setEditingWaybill] = useState<string | null>(null);
  const [nextServiceCost, setNextServiceCost] = useState<number>();
  const [nextServiceWeight, setNextServiceWeight] = useState<number>();
  const [loadingSingleUpdate, setLoadingSingleUpdate] = useState<boolean>(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const isMissingFilterEnabled = useMemo(() => params.serviceCostTo === -1 && params.deliveryDateFrom && moment(params.deliveryDateFrom).format(USER_DATE_FORMAT) === "01.01.2023", [params])

  const handleOnExpandRow = (expanded: boolean, record: Profitability) => {
    if (expanded) {
      setExpandedRowKeys([record.plsNumber]);
    } else {
      setExpandedRowKeys([]);
    }
  }

  const handleRenderExpandedRow = (record: Profitability) => {
    const columns: Array<ColumnProps<OrderServiceItem>> = [
      {
        className: 'gx-text-left',
        title: 'Стоимость',
        dataIndex: 'amount',
        key: 'amount',
        render: (_) => _ ? _.toFixed(2) : 0,
      },
      {
        className: 'gx-text-left',
        title: 'Дата консолидации',
        dataIndex: 'consolidationDate',
        key: 'consolidationDate',
        render: (_) => (_ ? moment(_).format(USER_DATE_FORMAT) : ''),
      },
    ];

    return <Table
      columns={columns}
      dataSource={record.invoiceDescription}
      pagination={false}>
    </Table>
  }

  const handleResetSingleUpdate = () => {
    setEditingWaybill(null);
    setNextServiceCost(undefined);
  }

  const getSingleProfitabilityCreationData = useCallback(() => {
    const current = profitabilityList.find(i => i.plsNumber === editingWaybill);
    if (current) {
      return ({
        serviceWaybillNumber: current.serviceOrderNumber,
        plsWaybillNumber: current.plsNumber,
        serviceCost: nextServiceCost,
        serviceWeight: nextServiceWeight
      })
    }
    return {};
  }, [editingWaybill, nextServiceCost, nextServiceWeight])

  const handleSaveWaybill = (e: any) => {
    e.preventDefault();
    if (editingWaybill && isNumber(nextServiceCost)) {
      setLoadingSingleUpdate(true);
      const func = isMissingFilterEnabled ? addSingleProfitability : updateProfitabilitySingle;
      const data = isMissingFilterEnabled ? getSingleProfitabilityCreationData() : {plsNumber: editingWaybill, nextServiceCost};
      dispatch(func(data))
        .then(response => {
          if (response.status || response.done) {
            window.setTimeout(() => {
              const {
                query, page, createdAtFrom, createdAtTo, deliveryDateFrom, deliveryDateTo, ...restFilters
              } = params;
        
              const filters: anyObject = { ...restFilters };
        
              if (deliveryDateFrom) filters.deliveryDateFrom = deliveryDateFrom.format(SERVER_DATE_FORMAT);
              if (deliveryDateTo) filters.deliveryDateTo = deliveryDateTo.format(SERVER_DATE_FORMAT);
              
              dispatch(loadProfitabilityList(
                query,
                page,
                createdAtFrom?.format(SERVER_DATE_FORMAT),
                createdAtTo?.format(SERVER_DATE_FORMAT),
                filters,
              )).finally(() => {
                setLoadingSingleUpdate(false);
                handleResetSingleUpdate();
              });
            }, 2000)
          } else {
            setLoadingSingleUpdate(false);
            handleResetSingleUpdate();
          }
        })
    }
  }

  const handleDiscardWaybill = (e: any) => {
    e.preventDefault();
    handleResetSingleUpdate();
  }

  const columns: Array<ColumnProps<Profitability>> = [
    {
        className: 'gx-text-left',
        title: 'Номер накладной и вызова курьера',
        dataIndex: 'bill',
        key: 'bill',
        render: (_, record) => getNumber(record),
    },
    {
        className: 'gx-text-left',
        title: 'Клиент',
        dataIndex: 'accountTitle',
        key: 'accountTitle',
    },
    {
        title: 'Перевозчик',
        dataIndex: 'deliveryService',
        key: 'deliveryService',
        render: (text) => <img src={deliveryServices[text]?.icon} alt={text} className="delivery_service-logo" />,
    },
    {
        className: 'gx-text-left',
        title: 'Тариф',
        dataIndex: 'rate',
        key: 'rate',
        render: (text, record) => getWaybillRateNameListing(record),
    },
    {
        className: 'gx-text-left',
        title: 'Дата создания',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text) => (text ? moment(text).format(USER_DATE_FORMAT) : ''),
    },
    {
        className: 'gx-text-left',
        title: 'Дата доставки',
        dataIndex: 'deliveryDate',
        key: 'deliveryDate',
        render: (text, record) => {
            try {
                const parsed = JSON.parse(record.stateData);
                return moment(parsed.delivery_date).format(USER_DATE_FORMAT);
            }catch(e) {
                console.log(e);
            }
            return ''
        },
    },
    {
        className: 'gx-text-left',
        title: 'Сбор',
        dataIndex: 'senderCity',
        key: 'senderCity',
    },
    {
        className: 'gx-text-left',
        title: 'Доставка',
        dataIndex: 'receiverCity',
        key: 'receiverCity',
    },
    {
        className: 'gx-text-left',
        title: 'Вес к оплате',
        dataIndex: 'weight',
        key: 'weight',
        render: (text, record) => {
          if (record.plsNumber === editingWaybill && isMissingFilterEnabled) {
            if (loadingSingleUpdate) {
              return (
                <Skeleton
                  paragraph={{ rows: 1, width: '100%', style: { minWidth: 10 } }}
                  title={false}
                  active
                  loading
                />
              )
            }
            return (
              <InputNumber value={nextServiceWeight} placeholder='Вес к оплате' onChange={(e) => setNextServiceWeight(e)} className='gx-w-100'/>
            )
          } 
          return (
            record.serviceWeight.toFixed(2)
          )
        }
    },
    {
        className: 'gx-text-left',
        title: 'Стоимость без бонусов',
        dataIndex: 'totalCost',
        key: 'totalCost',
    },
    {
        className: 'gx-text-left',
        title: 'Стоимость доставки',
        dataIndex: 'rateCost',
        key: 'rateCost',
    },
    {
        className: 'gx-text-left',
        title: 'Стоимость в курьерке',
        dataIndex: 'serviceCost',
        key: 'serviceCost',
        render: (text, record) => {
          if (record.plsNumber === editingWaybill) {
            if (loadingSingleUpdate) {
              return (
                <Skeleton
                  paragraph={{ rows: 1, width: '100%', style: { minWidth: 10 } }}
                  title={false}
                  active
                  loading
                />
              )
            }
            return (
              <InputNumber value={nextServiceCost} placeholder='Цена в курьерке' onChange={(e) => setNextServiceCost(e)} className='gx-w-100'/>
            )
          } 
          return (
            record.serviceCost.toFixed(2)
          )
        }
    },
    {
        className: 'gx-text-left',
        title: 'Чистая прибыль',
        dataIndex: 'clearIncome',
        key: 'clearIncome',
        render: (test, record) => (record.totalCost - record.serviceCost).toFixed(2)
    },
    {
        className: 'gx-text-left',
        title: 'Рентабельность',
        dataIndex: 'rent',
        key: 'rent',
        render: (test, record) => {
            const diff = record.totalCost - record.serviceCost;
            const rent = 100 * diff / record.totalCost;
            return rent.toFixed(2);
        }
    },
    {
      className: 'gx-text-left',
      title: 'Сумма в счетах',
      dataIndex: 'invoicesCost',
      key: 'invoicesCost',
    },
    {
      className: 'gx-text-left',
      title: 'Редактировать',
      dataIndex: 'edit',
      key: 'edit',
      render: (text, record) => {
          if (!user?.isAdmin || (editingWaybill && record.plsNumber !== editingWaybill) || loadingSingleUpdate) return null;
          if (record.plsNumber === editingWaybill) {
            return (
              <div className="gx-d-flex gx-align-items-center">
                <Button type="primary" onClick={handleSaveWaybill}>Сохранить</Button>
                <Button type="danger" className="gx-ml-2" onClick={handleDiscardWaybill}>Отменить</Button>
              </div>
            )
          }
          return (
            <Button type="primary" onClick={() => setEditingWaybill(record.plsNumber)}><Icon type="edit"/></Button>
          )
      }
    }
  ];

  return (
    <Card>
      <Table
        style={{ margin: '-24px' }}
        className="gx-table-responsive"
        columns={columns}
        rowClassName={() => 'gx-pointer'}
        loading={loading}
        dataSource={profitabilityList}
        pagination={false}
        rowSelection={{
          selectedRowKeys: selectedOrders,
          onChange: ((selected) => setSelectedOrders(selected as string[])),
        }}
        rowKey="plsNumber"
        onExpand={handleOnExpandRow}
        expandedRowRender={handleRenderExpandedRow}
        expandedRowKeys={expandedRowKeys}
      />
    </Card>
  );
}

export { ProfitabilityTable };
