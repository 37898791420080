import React from 'react';
import Icon from 'antd/es/icon';

const ACCOUNT_STATUSES = {
  1: {
    title: 'Зарегистрировался сам',
    icons: [
      {
        type: 'smile', theme: 'twoTone', style: { color: '#fadb14', marginRight: '5px' }, twoToneColor: '#fa8c16',
      },
      {
        type: 'file-text', theme: 'twoTone', style: { color: '#bfbfbf', marginRight: '5px' }, twoToneColor: '#8c8c8c',
      },
    ],
  },
  2: {
    title: 'Регистрация менеджером',
    icons: [
      {
        type: 'smile', theme: 'twoTone', style: { color: '#bfbfbf', marginRight: '5px' }, twoToneColor: '#8c8c8c',
      },
      {
        type: 'file-text', theme: 'twoTone', style: { color: '#fadb14', marginRight: '5px' }, twoToneColor: '#fa8c16',
      },
    ],
  },
  3: {
    title: 'Обмен договорами',
    icons: [
      {
        type: 'smile', theme: 'twoTone', style: { color: '#fadb14', marginRight: '5px' }, twoToneColor: '#fa8c16',
      },
      {
        type: 'file-text', theme: 'twoTone', style: { color: '#fadb14', marginRight: '5px' }, twoToneColor: '#fa8c16',
      },
    ],
  },
  4: {
    title: 'Аккаунт активен',
    icons: [
      {
        type: 'smile', theme: 'twoTone', style: { color: '#20c997', marginRight: '5px' }, twoToneColor: '#52c41a',
      },
      {
        type: 'file-text', theme: 'twoTone', style: { color: '#20c997', marginRight: '5px' }, twoToneColor: '#52c41a',
      },
    ],
  },
  5: {
    title: 'Аккаунт заблокирован',
    icons: [
      {
        type: 'smile', theme: 'twoTone', style: { color: '#f44336', marginRight: '5px' }, twoToneColor: '#f5222d',
      },
      {
        type: 'file-text', theme: 'twoTone', style: { color: '#f44336', marginRight: '5px' }, twoToneColor: '#f5222d',
      },
    ],
  },
};

export default function AccountStatus({ code }) {
  const status = ACCOUNT_STATUSES[code];
  if (!status) return <span />;
  return (
    <span>
      {status.icons.map((icon, i) => <Icon key={i} {...icon} />)}
      {status.title}
    </span>
  );
}
