import { ThunkAction } from '../../utils/types';
import {
  ClientActiveSeries,
  SET_CLIENT_ACTIVE_STATISTIC,
  SET_TOTAL_WAYBILL_STAT,
  SET_WAYBILL_BASE_STAT,
  SetClientActiveStatistic,
  SetTotalWaybillStat,
  SetWaybillBaseStat,
  TotalWaybillStat,
  WaybillBaseStat,
  WaybillStat,
} from './types';
import { CancelToken } from 'axios';

export const setWaybillStat = (series: Array<WaybillStat>, totalCount: number, totalAmount: number) => ({
  type: 'SET_WAYBILL_STAT',
  series,
  totalCount,
  totalAmount,
});

export const setClientActiveStatistic = (statistic: ClientActiveSeries[]): SetClientActiveStatistic => ({
  type: SET_CLIENT_ACTIVE_STATISTIC,
  statistic,
});

export const setTotalWaybillStat = (statistic: TotalWaybillStat[]): SetTotalWaybillStat => ({
  type: SET_TOTAL_WAYBILL_STAT,
  statistic,
});

export const setWaybillBaseStatistic = (statistic: WaybillBaseStat): SetWaybillBaseStat => ({
  type: SET_WAYBILL_BASE_STAT,
  statistic,
});

export const loadWaybillStat = (dateFrom = null, dateTo = null, cancelToken: CancelToken): ThunkAction => (dispatch, getState, http) => {
  const params: any = {};
  if (dateFrom) {
    params.date_from = dateFrom;
  }
  if (dateTo) {
    params.date_to = dateTo;
  }
  return http.get('/api/v1/statistic/waybill/', params, { cancelToken }).then(
    (response: { series: Array<WaybillStat>, totalCount: number, totalAmount: number }) => {
      dispatch(setWaybillStat(response.series, response.totalCount, response.totalAmount));
      return Promise.resolve(response);
    },
  );
};

export const loadClientActiveStatistic = (dateFrom?: string, dateTo?: string): ThunkAction => (dispatch, getState, http) => {
  const params: anyObject = {};
  if (dateFrom) params.date_from = dateFrom;
  if (dateTo) params.date_to = dateTo;
  return http.get('/api/v1/statistic/client/', params).then(
    (response: { series: ClientActiveSeries[] }) => {
      dispatch(setClientActiveStatistic(response.series));
      return Promise.resolve(response);
    },
  );
};

export const loadTotalWaybillStat = (dateFrom: string, dateTo: string, cancelToken: CancelToken): ThunkAction => (dispatch, getState, http) => {
  const params: anyObject = {};
  if (dateFrom) params.date_from = dateFrom;
  if (dateTo) params.date_to = dateTo;
  return http.get('/api/v1/statistic/waybill_total/', params, { cancelToken }).then(
    (response: { series: TotalWaybillStat[] }) => {
      dispatch(setTotalWaybillStat(response.series));
      return Promise.resolve();
    },
  );
};

export const loadBaseStatistic = (dateFrom?: string, dateTo?: string): ThunkAction => (dispatch, getState, http) => {
  const params: anyObject = {};
  if (dateFrom) params.period_start = dateFrom;
  if (dateTo) params.period_end = dateTo;
  return http.get('/api/v1/billing/billing_invoice/details/', params).then(
    (response: WaybillBaseStat) => {
      dispatch(setWaybillBaseStatistic(response));
      return Promise.resolve(response);
    },
  );
};