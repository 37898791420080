import React from 'react';
import moment from 'moment';
import { match as matchType } from 'react-router-dom';
import { useHistory } from 'react-router';
import ShippingTable from '../components/ShippingTable';
import DeliveryServiceField from '../../../components/form/DeliveryServiceField';
import DeliveryServiceLabel from '../../../components/form/DeliveryServiceField/DeliveryServiceLabel';
import List from '../../../components/List';
import DateRangeField from '../../../components/form/DateRangeField';
import { loadWaybills } from '../action-creators';
import { SERVER_DATE_FORMAT, USER_DATE_FORMAT } from '../../../utils/constants';
import withQueryParams, { ConfigValueType, SetFieldValueFunc } from '../../../utils/withQueryParams';
import useDispatch from '../../../utils/hooks/useDispatch';
import useSelector from '../../../utils/hooks/useSelector';
import { loadAccount } from '../../accounts/action-creators';
import Breadcrumbs from '../../../components/Breadcrumbs';
import WaybillStatusField, { WaybillStatusLabel } from '../../../components/form/WaybillStatusField';
import AdditionalServicesFilter, { AdditionalServicesLabel } from '../../../components/AdditionalServicesFilter';
import { getWaybillCostReport } from '../../tms/action-creators';
import { notification } from 'antd';
import DeliveryRateField, { DeliveryRateLabel } from '../../../components/filters/DeliveryRateField';

const QUERY_PARAMS_CONFIG = {
  query: { type: ConfigValueType.STRING },
  page: { type: ConfigValueType.PAGE_NUMBER },
  service: { type: ConfigValueType.STRING },
  createdAtFrom: { type: ConfigValueType.DATE },
  createdAtTo: { type: ConfigValueType.DATE },
  pickupDateFrom: { type: ConfigValueType.DATE },
  pickupDateTo: { type: ConfigValueType.DATE },
  billingDateFrom: { type: ConfigValueType.DATE },
  billingDateTo: { type: ConfigValueType.DATE },
  status: { type: ConfigValueType.ARRAY },
  additionalService: { type: ConfigValueType.ARRAY },
  deliveryDateFrom: { type: ConfigValueType.DATE },
  deliveryDateTo: { type: ConfigValueType.DATE },
  tariff: { type: ConfigValueType.ARRAY },
};

interface ShippingListParams {
  query: string,
  page: number,
  createdAtFrom: null | moment.Moment,
  createdAtTo: null | moment.Moment,
  pickupDateFrom: null | moment.Moment,
  pickupDateTo: null | moment.Moment,
  billingDateFrom: null | moment.Moment,
  billingDateTo: null | moment.Moment,
  service: string,
  status: string[],
  additionalService: string[],
  deliveryDateFrom: null | moment.Moment,
  deliveryDateTo: null | moment.Moment,
  tariff: string[],
}

interface ShippingViewRoute {
  accountId?: string
}

interface ShippingListProps {
  params: ShippingListParams,
  setFieldValue: SetFieldValueFunc<ShippingListParams>,
  match: matchType<ShippingViewRoute>
}

function ShippingView({ setFieldValue, params, match: { params: { accountId } } }: ShippingListProps) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { waybillsCount } = useSelector((state) => state.shipping);
  const { rates } = useSelector((state) => state.reference);
  const { currentAccount } = useSelector((state) => state.accounts);
  const [loading, setLoading] = React.useState(false);

  const handleLoadWaybills = React.useCallback(
    (newParams: ShippingListParams) => {
      const {
        query, page, service, createdAtFrom, createdAtTo, pickupDateFrom, pickupDateTo,
        billingDateFrom, billingDateTo, deliveryDateFrom, deliveryDateTo, ...restFilters
      } = newParams;
      setLoading(true);

      const filters: anyObject = { ...restFilters };

      if (deliveryDateFrom) filters.deliveryDateFrom = deliveryDateFrom.format(SERVER_DATE_FORMAT);
      if (deliveryDateTo) filters.deliveryDateTo = deliveryDateTo.format(SERVER_DATE_FORMAT);

      dispatch(loadWaybills(
        query,
        page,
        accountId,
        service,
        createdAtFrom?.format(SERVER_DATE_FORMAT),
        createdAtTo?.format(SERVER_DATE_FORMAT),
        pickupDateFrom?.format(SERVER_DATE_FORMAT),
        pickupDateTo?.format(SERVER_DATE_FORMAT),
        billingDateFrom?.format(SERVER_DATE_FORMAT),
        billingDateTo?.format(SERVER_DATE_FORMAT),
        filters,
      )).finally(() => setLoading(false));
    },
    [],
  );

  const handlePageChange = React.useCallback(
    (page) => {
      setFieldValue({ page }, handleLoadWaybills);
    },
    [handleLoadWaybills],
  );

  const handleFilterChange = React.useCallback(
    (value, filter: string) => {
      setFieldValue({ [filter]: value, page: 1 }, handleLoadWaybills);
    },
    [handleLoadWaybills],
  );

  const handleDateFilterChange = React.useCallback(
    ([from, to], filter: string) => {
      setFieldValue({ [`${filter}From`]: from, [`${filter}To`]: to }, handleLoadWaybills);
    },
    [handleLoadWaybills],
  );

  const handleDateFilterClean = React.useCallback(
    (filter: string) => {
      setFieldValue({ [filter]: null }, handleLoadWaybills);
    },
    [handleLoadWaybills],
  );

  const tags = React.useMemo(() => {
    const {
      service,
      createdAtFrom,
      createdAtTo,
      pickupDateFrom,
      pickupDateTo,
      billingDateFrom,
      billingDateTo,
      status,
      additionalService,
      deliveryDateFrom,
      deliveryDateTo,
      tariff,
    } = params;
    const _: anyObject[] = [];

    if (createdAtFrom) {
      _.push({
        onClose: () => handleDateFilterClean('createdAtFrom'),
        content: <span>Дата создания: c {moment(createdAtFrom).format(USER_DATE_FORMAT)}</span>,
      });
    }
    if (createdAtTo) {
      _.push({
        onClose: () => handleDateFilterClean('createdAtTo'),
        content: <span>Дата создания: по {moment(createdAtTo).format(USER_DATE_FORMAT)}</span>,
      });
    }
    if (pickupDateFrom) {
      _.push({
        onClose: () => handleDateFilterClean('pickupDateFrom'),
        content: <span>Дата сбора: c {moment(pickupDateFrom).format(USER_DATE_FORMAT)}</span>,
      });
    }
    if (pickupDateTo) {
      _.push({
        onClose: () => handleDateFilterClean('pickupDateTo'),
        content: <span>Дата сбора: по {moment(pickupDateTo).format(USER_DATE_FORMAT)}</span>,
      });
    }
    if (billingDateFrom) {
      _.push({
        onClose: () => handleDateFilterClean('billingDateFrom'),
        content: <span>Фактическая дата сбора: c {moment(billingDateFrom).format(USER_DATE_FORMAT)}</span>,
      });
    }
    if (billingDateTo) {
      _.push({
        onClose: () => handleDateFilterClean('billingDateTo'),
        content: <span>Фактическая дата сбора: по {moment(billingDateTo).format(USER_DATE_FORMAT)}</span>,
      });
    }
    if (deliveryDateFrom) {
      _.push({
        onClose: () => handleDateFilterClean('deliveryDateFrom'),
        content: <span>Дата доставки: с {moment(deliveryDateFrom).format(USER_DATE_FORMAT)}</span>,
      });
    }
    if (deliveryDateTo) {
      _.push({
        onClose: () => handleDateFilterClean('deliveryDateTo'),
        content: <span>Дата доставки: по {moment(deliveryDateTo).format(USER_DATE_FORMAT)}</span>,
      });
    }
    if (service) {
      _.push({
        onClose: () => handleFilterChange(null, 'service'),
        content: <span><DeliveryServiceLabel code={service}/></span>,
      });
    }
    if (status && status.length) {
      status.forEach((statusKey) => {
        _.push({
          onClose: () => handleFilterChange(status.filter((_) => _ !== statusKey), 'status'),
          content: <span><WaybillStatusLabel code={statusKey}/></span>,
        });
      });
    }
    if (additionalService && additionalService.length) {
      additionalService.forEach((as) => {
        _.push({
          onClose: () => handleFilterChange(additionalService.filter((__) => __ !== as), 'additionalService'),
          content: <AdditionalServicesLabel service={as}/>,
        });
      });
    }
    if (tariff && tariff.length > 0) {
      tariff.forEach((code) => {
        const rate = rates[code];
        _.push({
          onClose: () => handleFilterChange(tariff.filter((__) => __ !== code), 'tariff'),
          content: <DeliveryRateLabel code={code} />,
          tooltip: rate?.title,
        });
      });
    }

    return _;
  }, [params, rates]);

  React.useEffect(() => {
    handleLoadWaybills(params);
  }, []);

  React.useEffect(() => {
    if (accountId) dispatch(loadAccount(accountId));
  }, [accountId]);

  const breadcrumb = React.useMemo(() => {
    if (accountId) {
      return (
        <Breadcrumbs
          items={[
            { title: 'Клиенты', to: '/accounts' },
            { title: currentAccount?.title || '', to: `/accounts/${accountId}` },
            { title: 'Заказы', to: `/accounts/${accountId}/shipping` },
          ]}
        />
      );
    }
    return null;
  }, [currentAccount, accountId]);

  const onChartClick = React.useCallback(
    () => {
      history.push('/shipping/chart/');
    },
    [],
  );

  const handleDownload = React.useCallback(() => {
    setLoading(true);
    dispatch(getWaybillCostReport({ ...params, accountId })).then(
      (url) => window.open(url, '_blank'),
      () => notification.error({
        message: 'Ошибка',
        description: 'При выгрузке денных произошла ошибка, попробуйте изменить свой фильтр',
      }),
    ).finally(() => setLoading(false));
  }, [params]);

  return (
    <List
      title={
        accountId
          ? breadcrumb
          : <div className="title h1"><p>Заказы</p></div>
      }
      totalCount={waybillsCount}
      currentPage={params.page}
      searchPlaceholder="Укажите номер накладной или вызова курьера"
      handlePageChange={handlePageChange}
      searchValue={params.query}
      withGraphMode
      onChartClick={onChartClick}
      onSearchChange={(query: string) => handleFilterChange(query, 'query')}
      filters={[
        {
          title: 'Дата создания',
          Component: DateRangeField,
          value: [params.createdAtFrom, params.createdAtTo],
          onChange: (vals: [any, any]) => handleDateFilterChange(vals, 'createdAt'),
          componentProps: {
            fromPlaceholder: 'Создан c',
            toPlaceholder: 'Создан по',
          },
        },
        {
          title: 'Дата сбора',
          Component: DateRangeField,
          value: [params.pickupDateFrom, params.pickupDateTo],
          onChange: (vals: [any, any]) => handleDateFilterChange(vals, 'pickupDate'),
          componentProps: {
            fromPlaceholder: 'Дата сбора c',
            toPlaceholder: 'Дата сбора по',
          },
        },
        {
          title: 'Фактическая дата сбора',
          Component: DateRangeField,
          value: [params.billingDateFrom, params.billingDateTo],
          onChange: (vals: [any, any]) => handleDateFilterChange(vals, 'billingDate'),
          componentProps: {
            fromPlaceholder: 'Дата сбора c',
            toPlaceholder: 'Дата сбора по',
          },
        },
        {
          title: 'Дата доставки',
          Component: DateRangeField,
          value: [params.deliveryDateFrom, params.deliveryDateTo],
          onChange: (vals: [any, any]) => handleDateFilterChange(vals, 'deliveryDate'),
          componentProps: {
            fromPlaceholder: 'Дата доставки c',
            toPlaceholder: 'Дата доставки по',
          },
        },
        {
          title: 'Перевозчик',
          Component: DeliveryServiceField,
          value: params.service,
          onChange: (val: string) => handleFilterChange(val, 'service'),
          componentProps: {
            single: true,
          },
        },
        {
          title: 'Статус',
          Component: WaybillStatusField,
          value: params.status,
          onChange: (val: string[]) => handleFilterChange(val, 'status'),
        },
        {
          title: 'Дополнительные услуги',
          Component: AdditionalServicesFilter,
          value: params.additionalService,
          onChange: (val: string[]) => handleFilterChange(val, 'additionalService'),
        },
        {
          title: 'Тариф',
          Component: DeliveryRateField,
          value: params.tariff,
          onChange: (val: string[]) => handleFilterChange(val, 'tariff'),
          componentProps: {
            deliveryService: params.service,
          },
        },
      ]}
      tags={tags}
      onDownloadClick={handleDownload}
    >
      <ShippingTable
        accountId={accountId}
        loading={loading}
      />
    </List>
  );
}

export default withQueryParams(QUERY_PARAMS_CONFIG)(ShippingView);
