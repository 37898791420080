import React, { Suspense } from 'react';
import CircularProgress from '../../../../components/CircularProgress';

const ActList = React.lazy(() => import('../components/ActList'));
function ActListView(props) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <ActList {...props} />
    </Suspense>
  );
}

export default ActListView;
