import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withQueryParams } from '../../../helpers';
import List from '../../../components/List';
import FailedBillingTable from '../components/FailedBillingTable';
import {
  loadFailedBillingList,
} from '../action-creators';

const QUERY_PARAMS_CONFIG = {
  page: { type: 'pageNumber' }
};

class FailedBillingTableView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.loadFailedBilling();
  }

  setLoading = (loading) => this.setState({ loading });

  loadFailedBilling = () => {
    const {
      params: {
        page
      },
    } = this.props;
    this.setLoading(true);
    this.props.loadFailedBillingList(
      page,
    ).finally(
      () => { this.setLoading(false); },
    );
  };

  handlePageChange = (page) => this.props.setFieldValue({ page }, this.loadFailedBilling);

  render() {
    const { params: { page }, failedBillingCount } = this.props;
    const { loading } = this.state;
    return (
      <List
        title="Ошибки в актах"
        totalCount={failedBillingCount}
        currentPage={page}
        handlePageChange={this.handlePageChange}
        loading={loading}
      >
        <FailedBillingTable 
          loading={loading} 
          page={page}/>
      </List>
    );
  }
}

export default connect(
  ({ failedBilling: { failedBillingCount } }) => ({ failedBillingCount }),
  { loadFailedBillingList },
)(withQueryParams(QUERY_PARAMS_CONFIG)(FailedBillingTableView));
