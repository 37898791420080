import { Moment } from 'moment';
import { Contract, contractsAction } from './types';

export interface ContractsState {
  contractsList: Contract[],
  currentContract: Contract | null,
  contractsCount: number,
  contractsPage: number,
  contractsSearch: string,
  contractsStatus: string | null,
  contractsCreatedAtFrom: Moment | null,
  contractsCreatedAtTo: Moment | null,
  contractsSignDate: Moment | null,
}
const initialState: ContractsState = {
  contractsList: [],
  currentContract: null,
  contractsCount: 0,
  contractsPage: 1,
  contractsSearch: '',
  contractsStatus: null,
  contractsCreatedAtFrom: null,
  contractsCreatedAtTo: null,
  contractsSignDate: null,
};

export default function (state = initialState, action: contractsAction) {
  switch (action.type) {
    case 'SET_CURRENT_CONTRACT':
      return { ...state, currentContract: action.contract };
    case 'SET_CONTRACTS_LIST':
      return { ...state, contractsList: action.contracts };
    case 'SET_CONTRACTS_COUNT':
      return { ...state, contractsCount: action.count };
    case 'SET_CONTRACTS_PAGE':
      return { ...state, contractsPage: action.page };
    case 'SET_CONTRACTS_SEARCH':
      return { ...state, contractsSearch: action.search };
    case 'SET_CONTRACTS_STATUS':
      return { ...state, contractsStatus: action.status };
    case 'SET_CONTRACTS_CREATED_AT_FROM':
      return { ...state, contractsCreatedAtFrom: action.date };
    case 'SET_CONTRACTS_CREATED_AT_TO':
      return { ...state, contractsCreatedAtTo: action.date };
    case 'SET_CONTRACTS_SIGN_DATE':
      return { ...state, contractsSignDate: action.date };
    default:
      return state;
  }
}
