import React from 'react';
import {
  Button, Col, Icon, Row,
} from 'antd';
import SimplePagination from 'components/SimplePagination';
import { useHistory } from 'react-router';
import SearchBox from './SearchBox';
import ListModeSwitch from './ListModeSwitch';

function List({
  children,
  title,

  searchValue = '',
  searchPlaceholder = '',
  onSearchChange = null,

  pageSize = 25,
  currentPage = 1,
  totalCount = null,
  handlePageChange = null,

  withFavorites = false,
  filters = [],
  tags = [],
  loading = false,
  addon = null,

  withGraphMode = false,
  onChartClick = null,
  onListClick = null,
  onDownloadClick = null,
}) {
  const history = useHistory();

  return (
    <>
      <Row>
        <Col lg={withGraphMode ? 10 : 13} md={withGraphMode ? 6 : 10} sm={12} xs={12}>
          <div className="title h1"><p>{title}</p></div>
        </Col>
        <Col lg={4} md={5} sm={12} xs={12}>
          {addon}
        </Col>
        {!!handlePageChange && (
          <Col lg={7} md={9} sm={withGraphMode ? 18 : 24} xs={withGraphMode ? 16 : 24}>
            <SimplePagination
              current={currentPage}
              pageSize={pageSize}
              total={totalCount ?? 0}
              loading={loading}
              onChange={handlePageChange}
            />
          </Col>
        )}
        {(!!onListClick || !!onChartClick || !!onDownloadClick) && (
          <Col lg={3} md={4} sm={6} xs={8}>
            <div className="gx-float-right">
              <div className="ant-input-wrapper ant-input-group ant-input-group-compact">
                {!!onDownloadClick && (
                  <Button className="gx-simple-pagination-button" onClick={onDownloadClick}>
                    <Icon type="download" />
                  </Button>
                )}
                {(!!onListClick || !!onChartClick) && (
                  <>
                    <Button className="gx-simple-pagination-button" disabled={!onListClick} onClick={onListClick}>
                      <Icon type="unordered-list" />
                    </Button>
                    <Button className="gx-simple-pagination-button" disabled={!onChartClick} onClick={onChartClick}>
                      <Icon type="line-chart" />
                    </Button>
                  </>
                )}
              </div>
            </div>
          </Col>
        )}
      </Row>
      {!!onSearchChange && (
        <Row>
          <Col lg={24} md={24} sm={24} xs={24}>
            <SearchBox
              searchValue={searchValue}
              searchPlaceholder={searchPlaceholder}
              onSearchChange={onSearchChange}
              filters={filters}
              tags={tags}
              withFavorites={withFavorites}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          {children}
        </Col>
      </Row>
    </>
  );
}

export default List;
