import React from 'react';
import { Menu } from 'antd';
import { waybillAdditionalServices } from '../app/shipping/constants';

interface AdditionalServicesFilterProps {
  value: string[],
  onChange: (value: string[]) => void
}

function AdditionalServicesFilter({ value, onChange }: AdditionalServicesFilterProps) {
  return (
    <Menu
      multiple
      selectedKeys={value}
      onSelect={({ selectedKeys }) => onChange(selectedKeys)}
      onDeselect={({ selectedKeys }) => onChange(selectedKeys)}
      className="gx-border-0 gx-menu-cancel-padding"
    >
      {waybillAdditionalServices.map(({ key, title }) => (
        <Menu.Item key={key}>
          <span>{title}</span>
        </Menu.Item>
      ))}
    </Menu>
  );
}

export default AdditionalServicesFilter;

export function AdditionalServicesLabel({ service }: { service: string }) {
  const additionalService = waybillAdditionalServices.find((_) => _.key === service);
  return (
    <span>{ additionalService?.title ?? service }</span>
  );
}
