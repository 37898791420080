import React from 'react';
import { Menu } from 'antd';
import { WAYBILL_STATUSES } from '../../utils/constants';

const WAYBILL_STATUSES_ORDER = [
  'registered', 'pickup_waiting', 'courier_search', 
  'courier_on_way', 'received_from_sender', 'ready_to_send', 
  'sent_to_receiver_office', 'met_in_receiver_office',
  'received_in_delivery_office', 'courier_delivery', 'receiver_waiting_in_office', 
  'delivery_waiting', 'delivered', 'canceled', 
  'lost', 'scrapped', 'problem', 'canceled_negotiation'
];

interface WaybillStatusFieldProps {
  value: string[],
  onChange: (value: string[]) => void,
}

export function WaybillStatusLabel({ code }: { code: typeof WAYBILL_STATUSES_ORDER[number] }) {
  return (
    <span>
      <span className="gx-nonhover gx-mr-2 gx-pr-1">
        <i className={`icon icon-circle gx-fs-sm gx-text-${WAYBILL_STATUSES[code].color}`} />
      </span>
      {WAYBILL_STATUSES[code].title}
    </span>
  );
}

function WaybillStatusField({ value, onChange }: WaybillStatusFieldProps) {
  return (
    <Menu
      multiple
      selectedKeys={value}
      onSelect={({ selectedKeys }) => onChange(selectedKeys)}
      onDeselect={({ selectedKeys }) => onChange(selectedKeys)}
      className="gx-border-0 gx-menu-cancel-padding"
    >
      {WAYBILL_STATUSES_ORDER.map((key) => (
        <Menu.Item key={key}>
          <WaybillStatusLabel code={key} />
        </Menu.Item>
      ))}
    </Menu>
  );
}

export default WaybillStatusField;
