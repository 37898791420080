export const SET_DOWNGRADED_ORDERS_LIST = 'SET_DOWNGRADED_ORDERS_LIST';
export const SET_DOWNGRADED_ORDERS_COUNT = 'SET_DOWNGRADED_ORDERS_COUNT';

export type DowngradedItem = {
  orderNumber: string,
  orderAmount: number,
  billingAmount: number,
  creationDate: string
};

export interface SetDowngradedOrdersList {
  type: typeof SET_DOWNGRADED_ORDERS_LIST,
  payload: DowngradedItem[]
}

export interface SetDowngradedOrdersCount {
  type: typeof SET_DOWNGRADED_ORDERS_COUNT,
  payload: number,
}

export type DowngradedOrdersActions =
  SetDowngradedOrdersList |
  SetDowngradedOrdersCount;
