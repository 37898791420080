import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Icon,
  Row,
  Table,
} from 'antd';
import { Field, Form, Formik } from 'formik';
import Modal from 'antd/es/modal';
import { ColumnProps } from 'antd/es/table';
import useSelector from '../../../utils/hooks/useSelector';
import useDispatch from '../../../utils/hooks/useDispatch';
import { FailedBilling } from '../types';
import { loadFailedBillingList, syncFailedBilling } from '../action-creators';
import { RootState } from '../../../reducers';
import moment from 'moment';
import { USER_DATE_FORMAT } from '../../../utils/constants';
import { isNumber } from 'lodash';
import { AntInput } from '../../../components/CreateAntField';

interface Props {
  loading: boolean,
  page: number
}

function FailedBillingTable({ loading, page }: Props) {
  const dispatch = useDispatch();

  const { failedBillingList } = useSelector((state) => state.failedBilling);
  const { user } = useSelector((state: RootState) => state.users);

  const [editBilling, setEditBilling] = useState<number | null>(null);

  useEffect(() => {
    dispatch(loadFailedBillingList())
  }, [])

  const columns: ColumnProps<FailedBilling>[] = [
    {
        title: 'ID',
        dataIndex: 'accountId',
        key: 'accountId'
    },
    {
      title: 'Клиент',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Дата',
      dataIndex: 'date',
      key: 'date',
      render: (_, record) => moment(record.date).format(USER_DATE_FORMAT),
    },
    {
      title: 'Редактировать',
      key: 'edit',
      //@ts-ignore
      render: (_, record) => (
        user?.isAdmin
        ?
        <Button 
          type='primary'
          size='small'
          className='gx-mb-0'
          onClick={(e: any) => {
            e.stopPropagation();
            setEditBilling(record.id);
          }}><Icon type="edit"/></Button>
        :
        null
      ),
    }
  ];

  const handleEditBilling = React.useCallback(
    (values, actions) => {
      if (isNumber(editBilling) && values.docId) {
        dispatch(syncFailedBilling(editBilling, values.docId, page)).then(
          () => setEditBilling(null),
          (err) => actions.setErrors(err),
        ).finally(() => actions.setSubmitting(false));
      }
    },
    [editBilling, page],
  );

  return (
    <Card>
      {isNumber(editBilling) && (
        <Formik
          initialValues={{docId: undefined}}
          enableReinitialize
          onSubmit={handleEditBilling}
        >
          {({ submitForm, isSubmitting }) => (
            <Modal
              visible={isNumber(editBilling)}
              onCancel={() => setEditBilling(null)}
              title={`Синхронизация для ${failedBillingList.find(i => i.id === editBilling)?.title}`}
              onOk={submitForm}
              confirmLoading={isSubmitting}
              okText="Синхронизовать"
              cancelText="Отменить"
            >
              <Form>
                <Row>
                  <Col xs={24} md={24}>
                    <Field
                      component={AntInput}
                      type="text"
                      name="docId"
                      placeholder="ID документа"
                      title="ID документа"
                      label="ID документа"
                      componentClassName="gx-w-100"
                    />
                  </Col>
                </Row>
              </Form>
            </Modal>
          )}
        </Formik>
      )}
      <Table
        style={{ margin: -24 }}
        className="gx-table-responsive"
        columns={columns}
        rowClassName={() => 'gx-pointer'}
        loading={loading}
        dataSource={failedBillingList}
        pagination={false}
      />
    </Card>
  );
}

export default FailedBillingTable;
