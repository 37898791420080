function replaceByFieldValue<T extends anyObject>(arr: Array<T>, key: keyof T, newObject: T): Array<T> {
  const tmp = arr.slice();
  const index = tmp.findIndex((_) => _[key] === newObject[key]);
  if (index > -1) tmp[index] = newObject;
  return tmp;
}

function mergeAndReplaceByFieldValue<T extends anyObject>(arr: Array<T>, key: keyof T, newObject: Partial<T>): Array<T> {
  const tmp = arr.slice();
  const index = tmp.findIndex((_) => _[key] === newObject[key]);
  if (index > -1) tmp[index] = { ...tmp[index], ...newObject };
  return tmp;
}

export { replaceByFieldValue, mergeAndReplaceByFieldValue };
