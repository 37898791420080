export const SET_TRANSACTIONS_LIST = 'SET_TRANSACTIONS_LIST';
export const SET_TRANSACTIONS_COUNT = 'SET_TRANSACTIONS_COUNT';

export interface Transaction {
  billingId: number,

  accountId: number,
  contractId: number,

  number: string,
  date: string,
  dateFrom: string,
  dateTo: string,
  amount: number,
  files: any,
}

export interface SetTransactionsList {
  type: typeof SET_TRANSACTIONS_LIST,
  transactions: Array<Transaction>
}

export interface SetTransactionsCount {
  type: typeof SET_TRANSACTIONS_COUNT,
  count: number
}

export type ActionType = SetTransactionsList | SetTransactionsCount;
