export const SET_GLOBAL_NOTIFICATION_LIST = 'SET_GLOBAL_NOTIFICATION_LIST';
export const SET_GLOBAL_NOTIFICATION_COUNT = 'SET_GLOBAL_NOTIFICATION_COUNT';
export const SET_CURRENT_GLOBAL_NOTIFICATION = 'SET_CURRENT_GLOBAL_NOTIFICATION';
export const RESET_CURRENT_GLOBAL_NOTIFICATION = 'RESET_CURRENT_GLOBAL_NOTIFICATION';

export type GlobalNotificationId = string;

export type GlobalNotification<T = string> = {
  notificationId: GlobalNotificationId,
  text: string,
  fromDate: T,
  toDate: T,
};

export interface SetGlobalNotificationList {
  type: typeof SET_GLOBAL_NOTIFICATION_LIST,
  list: GlobalNotification[],
}

export interface SetGlobalNotificationCount {
  type: typeof SET_GLOBAL_NOTIFICATION_COUNT,
  count: number,
}

export interface SetCurrentGlobalNotification {
  type: typeof SET_CURRENT_GLOBAL_NOTIFICATION,
  notification: GlobalNotification,
}

export interface ResetCurrentGlobalNotification {
  type: typeof RESET_CURRENT_GLOBAL_NOTIFICATION,
}

export type NotificationsActions =
  SetGlobalNotificationList
  | SetGlobalNotificationCount
  | SetCurrentGlobalNotification
  | ResetCurrentGlobalNotification;
